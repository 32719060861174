/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './style.scss'
// import Axios from 'axios'
import Icon from 'react-icons-kit'
import { ic_cancel_outline } from 'react-icons-kit/md/'
import { Modal } from 'react-bootstrap'
// import {Images} from '../../../utils/Images'
const Index = (props) => {
    const handlePaymentUnSuccessfulData =()=>{
        props.handlePaymentUnSuccessful()
    }
    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-appointment"
            >

                <Modal.Body >
                    <div className='p-4'>
                        <div className='d-flex justify-content-end'>
                            <Icon icon={ic_cancel_outline} size={40} onClick={handlePaymentUnSuccessfulData} />
                        </div>

                        <div className="card-appointment mt-3 mb-3 mr-3 text-start">
                            {/* <p>You are booking Dr. Rahim's Appointment</p> */}
                            <h3 className='text-center fw-bold' style={{fontSize:'20px'}}>Reschedule your Schedule card</h3>

                            <p className='fw-bolder text-center p-0 m-0' style={{color:'#00BD2B'}}>Payment Successful !</p>
                            <h2 className='mt-1 mb-4 text-center' style={{color:'#FF66C3'}}>Thank !</h2>
                            {/* <p className='p-0 m-0'>Doctor Name:Dr. Rahim</p>
                            <p className='p-0 m-0'>Appointment Time:5.44pm</p>
                            <p className='p-0 m-0'>Appointment Date:30 Oct, 2023</p> */}
                            <p className='mt-5 p-0 mb-0'>We will verify and notify your patients. It usually</p>
                            <p className='p-0 m-0'>takes a few hours</p>

                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
