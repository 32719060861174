import React from "react";
import './style.scss';
import { Link } from 'react-router-dom'
import { Images } from '../../../utils/Images'
import Icon from 'react-icons-kit'
import { user } from 'react-icons-kit/ikons/'
import { ic_location_on, ic_stars } from 'react-icons-kit/md/'
import { book, calendar } from 'react-icons-kit/typicons/'
import { briefcase } from 'react-icons-kit/oct/'
import {dropbox} from 'react-icons-kit/ikons/'
// import Doctor from '../../components/doctor/index'
const Index = () => {
    return (
        <div className="doctors-profile">
            <div className="d-flex justify-content-end"><button className="Dermatology">DERMATOLOGY</button></div>
            <div className="profile-card mb-5">
                <div className="d-flex mt-1">
                    <div className="col-4">
                        <img className="m-3 profile-img" src={Images.Doctor2} alt="" />
                    </div>
                    <div className="col-8  ms-2 text-start">
                        <Link to='/patient/doctor-profile' style={{color:'#000', textDecoration:'none'}}>
                        <div className="d-flex align-items-center">
                            <Icon className="m-2" icon={user} size={15} />
                            <h5 className="mb-0 fs-6">Dr. Anik Dash</h5>
                        </div>
                        </Link>
                        
                        <div className="d-flex align-items-center">
                            <Icon className="m-2" icon={briefcase} size={15} />
                            <h5 className="mb-0 fs-6" style={{ color: '#6C6C6C' }}> Assistant Professor</h5>
                        </div>
                        <div className="d-flex align-items-center">
                            <Icon className="m-2" icon={book} size={15} />
                            <p className="mb-0 fw-bold" style={{ color: '#6C6C6C' }}> Dermatology</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <Icon className="m-2" icon={ic_location_on} size={15} />
                            <p className="mb-0 fw-bold" style={{ color: '#6C6C6C' }}> kurmitola General Hospital Dh...</p>
                        </div>

                    </div>
                </div>

                <div className="m-4">
                    <div className="d-flex align-items-center">
                        <Icon className="m-2" icon={ic_stars} size={20} />
                        <h5 className="mb-0 fs-6 fw-bold" style={{ color: '#6C6C6C' }}>Specialization</h5>
                    </div>
                    <p className="mb-0 ms-5 fw-bold" style={{ color: '#6C6C6C' }}>Dermatology & Venerology</p>
                    <div className="d-flex">
                        <div className="d-flex align-items-center">
                            <Icon className="m-2" icon={calendar} size={15} />
                            <p className="mb-0 " style={{ color: '#6C6C6C', fontSize:'11px' }}>Sat, Sun, Mon, Tue, Wed, Thu</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <Icon className="m-2" icon={dropbox} size={15} />
                            <p className="mb-0 " style={{ color: '#6C6C6C', fontSize:'11px' }}>Dermatology & Venerology</p>
                        </div>
                    </div>

                    <div className="d-flex align-items-center">
                            <Icon className="m-2" icon={ic_location_on} size={15} />
                            <p className="mb-0 fw-bold" style={{ color: '#6C6C6C' }}>Ibn Sina Medical College & Hospital</p>
                        </div>
                    
                </div>

                <div className="d-flex  text-end justify-content-end flex-lg-row flex-md-row flex-column mb-4">
                    <button className="profile-follow">FOLLOW</button>
                    <button className="profile-view">VIEW PROFILE</button>
                    <button className="profile-book">BOOK APPOINTMENT</button>
                </div>
            </div>
        </div>
    );
}
export default Index;