import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import { useForm } from 'react-hook-form'
import {  useNavigate } from "react-router-dom";
import Icon from 'react-icons-kit'
import { ic_save_alt_twotone} from 'react-icons-kit/md/'

const Index = () => {
    const navigate = useNavigate();
    const { register, } = useForm();//handleSubmit, formState: { errors }, 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            navigate(`/doctor/change-password`);
        
        } catch (error) {
 
        }
    }
    return (

        <div className="profile-setting">
   
                <div className="col-sm-12 card p-4 mt-2 mb-5 text-start">
                <h3 className='fw-bold'>Basic Information</h3>
                   
                <form onSubmit={onSubmit}>
                    <div className='d-flex align-items-center'>
                        <div className='m-2 UploadPhoto' style={{ height: '100px', width:'100px', background: '#D9D9D9' }}>
                        </div>
                        <div className='m-2'>
                            <button className='UploadPhoto'><Icon icon={ic_save_alt_twotone}/> Upload Photo</button>
                            <p className='p-0 m-0 fw-bold' style={{fontSize:'14px'}} >Allow JPG, GIF or PNG. Max size of 2MB</p>
                        </div>
                        {/* <div className='m-2' style={{ height: '100px', width:'100px', background: '#D9D9D9' }}>
                        </div> */}
                    </div>
                    <div className='row'>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            
                            <div className="form-group  p-3 mb-2 mt-2 pb-0">
                            <p className='p-0 m-0 '>Username<span style={{color:'red'}}>*</span></p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div> 

                        <div className="col-sm-12 col-md-6 col-lg-6">
                            
                            <div className="form-group  p-3 mb-2 mt-2 pb-0">
                            <p className='p-0 m-0 '>Email<span style={{color:'red'}}>*</span></p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div>  

                        <div className="col-sm-12 col-md-6 col-lg-6">
                            
                            <div className="form-group  p-3 mb-2 pt-0 pb-0">
                            <p className='p-0 m-0 '>First Name<span style={{color:'red'}}>*</span></p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-6">
                            
                            <div className="form-group  p-3 mb-2 pt-0 pb-0">
                            <p className='p-0 m-0 '>Last Name<span style={{color:'red'}}>*</span></p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-6">
                            
                            <div className="form-group  p-3 mb-2 pt-0 pb-0">
                            <p className='p-0 m-0 '>Phone Number</p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-6">
                            
                            <div className="form-group p-3 mb-2 pt-0 pb-0">
                            <p className='p-0 m-0 '>Gender</p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            
                            <div className="form-group  p-3 mb-2 pt-0">
                            <p className='p-0 m-0 '>Date of Birth</p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div>
                                                 
                    </div>
                    <p className='p-0 m-0 text-center' style={{fontSize:"14px"}}>You can only Change These for a Limited Number of times.<span style={{color:'#64C5FF'}}>See Community Guidelines</span> </p>
                    {/* <Link><Icon icon={ic_add_circle_outline}/>Add More</Link> */}

                    <h3 className='fw-bold mt-5'>About Me</h3>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                            
                            <div className="form-group  p-3 mb-2 mt-2 pb-0">
                            <p className='p-0 m-0 fw-bold'>Biography</p>
                                <textarea
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div> 
                </form>
                

                </div>
           
                
            </div>
       
    );
}
export default Index;

