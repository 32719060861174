import './App.scss';
import {BrowserRouter, Route, Routes,} from "react-router-dom";
import Home from './pages/home/home'
import Community from './pages/community/community'
import Doctor from './pages/doctors/doctors'
import HelpCenter from './pages/helpCenter/helpCenter'
import AppBar from './components/AppBar/Index'
import Footer from './components/Footer/Index'

// patient
import Sidebar from './components/AppBar/patient'
import DoctorSiderbar from './components/AppBar/patient'
import Register from './pages/register-dr/Index'
import DoctorNotVerified from './pages/couldnot-be-verified/Index'
import DoctorProfileVerified from './pages/verification/Index'
import ShareHealthData from './pages/patientPage/ShareHealthData/Index'
import Patient from './pages/patient/Index'
import FindCare from './pages/patientPage/find-care/index'
import ForwardPrescription from './pages/patientPage/ForwardPrescription/index'
import MyRecord from './pages/patientPage/myRecord/index'
import UpcomingScreening from './pages/patientPage/upcomingScreening/index'
import Appointment from './pages/patientPage/Appointment/index'
import DocumentCenter from './pages/patientPage/DocumentCenter/index'
import PatientCommunity from './pages/patientPage/community/index'
import MyMedication from './pages/patientPage/myMedication/index'
import AfterLoginDrRegister from './pages/patientPage/register-dr/Index'
import SinglePost from './pages/SinglePost/index'
import PatientDoctorProfile from './pages/patientPage/DoctorProfile/index'
import ChangePasswordPatient from './pages/auth/ChangePassword/change-password-patient'
import BookingInvoice from './pages/patientPage/BookingInvoice/index'
import PatientVerifications from './pages/patientPage/Verifications/index'
import PatientPreference from './pages/patientPage/Preference/index'
import EditPorfolio from './pages/patientPage/EditPortfolio/index'
// import About from './components/doctor/myProfile/index'
// Doctor
import DoctorProfile from './pages/DoctorPage/myProfile/index'
import TodaysAppointment from './pages/DoctorPage/todaysAppointments/index'
import MyQrCodeComponent from './pages/qr'
import DoctorCommunity from './pages/DoctorPage/community/index'
import FindCareDoctor from './pages/DoctorPage/find-care/index'
import ReferPatient from './pages/DoctorPage/ShareHealthData/Index'
import Schedule from './pages/DoctorPage/Schedules/index'
import ChangePasswordDoctor from './pages/auth/ChangePassword/change-password-doctor'
import ForgetPassword from './pages/auth/ForgetPassword/index'
import DoctorOTP from './pages/auth/OTP/index'
import DoctorBookingInvoice from './pages/DoctorPage/BookingInvoice/index'
import DoctorVerifications from './pages/DoctorPage/Verifications/index'
import DoctorPreference from './pages/DoctorPage/Preference/index'
import ProfileSettingsCont from './pages/setting/ProfileSettingsCont/index'
import ProfileSettings from './pages/setting/ProfileSettings/index'


// Patient Doctor view
import DoctorPatientProfile from './pages/PatientDoctorView/patient/Index'

// Practice Prescription
import PracticePrescription from './pages/practice/practice-prescription/index'
import PracticePrescription2 from './pages/practice/practice-prescription2/index'
import Prescription from './pages/practice/prescription/index'
import Practice from './pages/practice/practice-loading/index'
import PracticePatientProfile from './pages/practice/patientProfile/index'
import TermsCondition from './pages/termsAndCondition/Index'
import PrivacyPolicy from "./pages/privacyPolicy";
import Construction from "./pages/construction/Index"
import {useEffect} from 'react';

import Blog from './pages/Blog/index'

function App() {
    const token = localStorage.getItem('token')
    const qrCodeData = 'https://example.com';
    useEffect(() => {
    }, [token])
    // console.log(token);
    return (
        <div className="App">
            <BrowserRouter>
                {token ? null : <AppBar/>}
                {/* <AppBar /> */}
                <Routes>
                    {/* {localStorage?.getItem("role") === "null"?:  <Route path="/" element={<Sidebar />}></Route>} */}

                    {/* <Route /> */}
                    {/* <Route exact path="/reset" element={<Reset />} /> */}
                    <Route path="/patient" element={<Sidebar/>}>
                        {/* { token? null: <Route path='/patient/my-profile' element ={<Patient/>}/>} */}
                        <Route path='/patient/my-profile' element={<Patient/>}/>
                        <Route path='/patient/doctor-profile' element={<PatientDoctorProfile/>}/>
                        <Route path='/patient/share-health-data' element={<ShareHealthData/>}/>
                        <Route path='/patient/find-care' element={<FindCare/>}/>
                        <Route path='/patient/forward-prescription' element={<ForwardPrescription/>}/>
                        <Route path='/patient/my-record' element={<MyRecord/>}/>
                        <Route path='/patient/upcoming-screening' element={<UpcomingScreening/>}/>
                        <Route path='/patient/document-center' element={<DocumentCenter/>}/>
                        <Route path='/patient/appointment' element={<Appointment/>}/>
                        <Route path='/patient/your-medication' element={<MyMedication/>}/>
                        <Route path='/patient/patient-community' element={<PatientCommunity/>}/>
                        <Route path='/patient/rescheduled-appointment' element={<Appointment/>}/>
                        <Route path='/patient/register-doctor' element={<AfterLoginDrRegister/>}/>
                        <Route path='/patient/change-password' element={<ChangePasswordPatient/>}/>
                        <Route path='/patient/booking-invoice' element={<BookingInvoice/>}/>
                        <Route path='/patient/verifications' element={<PatientVerifications/>}/>
                        <Route path='/patient/preference' element={<PatientPreference/>}/>
                        <Route path='/patient/profile-settings-cont' element={<ProfileSettingsCont/>}/>
                        <Route path='/patient/profile-settings' element={<ProfileSettings/>}/>
                        <Route path='/patient/edit-portfolio' element={<EditPorfolio/>}/>

                        {/* <Route path='/about' element ={<About/>}/> */}
                        {/* <Route path='/appointment' element ={<FindCare/>}/> */}
                    </Route>

                    {/* Doctor */}
                    <Route path="/doctor" element={<DoctorSiderbar/>}>
                        <Route path='/doctor/my-profile' element={<DoctorProfile/>}/>
                        <Route path='/doctor/todays-appointments' element={<TodaysAppointment/>}/>
                        <Route path='/doctor/cancel-appointment' element={<Appointment/>}/>
                        <Route path='/doctor/community' element={<DoctorCommunity/>}/>
                        <Route path='/doctor/find-care' element={<FindCareDoctor/>}/>
                        <Route path='/doctor/practice' element={<Practice/>}/>
                        <Route path='/doctor/refer-patient' element={<ReferPatient/>}/>
                        <Route path='/doctor/schedule' element={<Schedule/>}/>
                        <Route path='/doctor/change-password' element={<ChangePasswordDoctor/>}/>
                        <Route path='/doctor/forget-password' element={<ForgetPassword/>}/>
                        <Route path='/doctor/otp' element={<DoctorOTP/>}/>
                        <Route path='/doctor/booking-invoice' element={<DoctorBookingInvoice/>}/>
                        <Route path='/doctor/verifications' element={<DoctorVerifications/>}/>
                        <Route path='/doctor/preference' element={<DoctorPreference/>}/>
                        <Route path='/doctor/profile-settings-cont' element={<ProfileSettingsCont/>}/>
                        <Route path='/doctor/profile-settings' element={<ProfileSettings/>}/>

                        {/* Patient Doctor view */}
                        <Route path='/doctor/patient-profile' element={<DoctorPatientProfile/>}/>
                    </Route>

                    <Route>
                        {/* Dashboard */}

                        <Route path="/" element={<Home/>}/>
                        <Route path="/community" element={<Community/>}/>
                        <Route path="doctors" element={<Doctor/>}/>
                        <Route path="/help-center" element={<HelpCenter/>}/>
                        <Route path="/single-post" element={<SinglePost/>}/>
                        <Route path='/sidebar' element={<Sidebar/>}/>
                        <Route path='/register-doctor' element={<Register/>}/>
                        <Route path='/doctor-profile-not-verified' element={<DoctorNotVerified/>}/>
                        <Route path='/doctor-profile-verified' element={<DoctorProfileVerified/>}/>
                        <Route path='/terms_condition' element={<TermsCondition/>}/>
                        <Route path='/privacy_policy' element={<PrivacyPolicy/>}/>
                        <Route path='/under_construction' element={<Construction/>}/>


                        <Route path='/patients' element={<Patient/>}/>
                        <Route path='/blog' element={<Blog/>}/>
                        {/* <Route path='*' element={<MainPage title=" Comming soon..........." />} /> */}
                        <Route path='/qr' element={<MyQrCodeComponent data={qrCodeData}/>}/>
                        <Route path='/practice-prescription' element={<PracticePrescription/>}/>
                        <Route path='/practice-prescription2' element={<PracticePrescription2/>}/>
                        <Route path='/prescription' element={<Prescription/>}/>

                        <Route path='/practice/patient-profile' element={<PracticePatientProfile/>}/>
                    </Route>
                </Routes>
                {token ? null : <Footer/>}
                {/* <Footer/> */}
            </BrowserRouter>
            {/* <AppBar/> */}
            {/* <HelpCenter/> */}
        </div>
    );
}

export default App;
