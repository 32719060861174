import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import { useForm } from 'react-hook-form'
import PatientSidebar from '../../../components/sidebar-patient/index'

const Index = () => {
    const { register, } = useForm();//handleSubmit, formState: { errors }, 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
        


        } catch (error) {
           
           
        }
    }
    return (

        <div className="custom-change-password">
        <div className="d-flex">
            
            <div className="col-sm-12 col-md-9 col-lg-8">
            
                <div className="card mt-3 mb-3 mr-3 p-4 text-start">
                <form onSubmit={onSubmit}>
                            <div className="col-sm-12 col-md-8 col-lg-8">
                                <p className='p-0 m-0'>Old Password</p>
                                <div className="form-group mb-2">

                                    <input
                                        type="text"
                                        name="oldPassword"
                                        className="form-control shadow-none"
                                        placeholder=""
                                        {...register("oldPassword", {
                                            required: "Old Password is required",

                                        })}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-8">
                                <p className='p-0 m-0'>New Password</p>
                                <div className="form-group mb-2">

                                    <input
                                        type="text"
                                        name="newPassword"
                                        className="form-control shadow-none"
                                        placeholder=""
                                        {...register("newPassword", {
                                            required: "New Password is required",

                                        })}
                                    />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-8 col-lg-8">
                                <p className='p-0 m-0'>Confirm Password</p>
                                <div className="form-group mb-2">

                                    <input
                                        type="text"
                                        name="confirmPassword"
                                        className="form-control shadow-none"
                                        placeholder=""
                                        {...register("confirmPassword", {
                                            required: "Confirm Password is required",

                                        })}
                                    />
                                </div>
                            </div>
                        </form>
                        <div className='row'>
                            <div className="col-12 text-start p-2">
                                <button className='btn-password' type="submit" >SAVE CHANGES</button>

                            </div>
                            {/* <div className="col-12 text-center mb-0"><p style={{ fontWeight: "bold" }}>Already have an account?</p></div> */}


                        </div>
                </div>
            </div>
            <div className='col-md-4 col-lg-4 d-none d-lg-block '>
            <div className='card p-2 position-fixed top-2 end-0 overflow-auto' style={{ width: '310px', maxHeight: "85vh" }}>
                
                <PatientSidebar/>
            </div>
            </div>
        </div>
    </div>
       
    );
}
export default Index;