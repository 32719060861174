import React from 'react'
import './style.scss'
// import Icon from 'react-icons-kit'
import { useForm } from 'react-hook-form'
// import { search,  } from 'react-icons-kit/feather'//loader

const Index = (props) => {
    const { register, handleSubmit, formState: { errors },  } = useForm()
    // const [searchValues, setSearchValues] = useState(null);
    const onClearSearch = event => {
        const value = event.target.value
        if (!value) props.clear()
    }

    // Submit Form
    const onSubmit = data => props.search(data);

    return (
        <div className="search-component">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-container">
                    <input
                        type="text"
                        name="query"
                        {...register("query",{required:'value is required'})}
                        placeholder={` ${props.placeholder}`}
                        onChange={onClearSearch}
                        className={errors.query ? "form-control form-control-sm shadow-none error" : "form-control form-control-sm shadow-none"}
                    />
                    <button
                        type="submit"
                        className="btn btn-sm shadow-none"
                        disabled={props.loading}>
                        {/* {props.loading ?
                            <div className="btn-loader"></div> : <Icon icon={search} size={18} />
                        } */}
                    </button>
                    
                </div>
            </form>
        </div>
        
    );
};

export default Index;
