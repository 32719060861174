import React from 'react'//useEffect, useCallback,
import '../ChangePassword/style.scss'
import { Images } from '../../../utils/Images'
// import Axios from 'axios'
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom";
const Index = () => {
    const navigate = useNavigate();
    const { register, } = useForm();//handleSubmit, formState: { errors }, 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            navigate(`/doctor/change-password`);
        
        } catch (error) {
 
        }
    }
    return (

        <div className="custom-change-password">
            <div className="d-flex justify-content-evenly align-items-center">
                
                <div className="col-sm-12 col-md-5 col-lg-5">
                <img src={Images.ChangePassword} alt=''/>
                    
                </div>

                <div className="col-sm-12 col-md-5 col-lg-5">
                
                <div className="card p-5 text-start">
                    <h5 className='m-0 p-0 fw-bolder'>Forgot Password?</h5>
                    <p className='m-0 p-0 fw-bold'>
                        Enter your email to get a password reset link
                    </p>
                <form onSubmit={onSubmit}>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                {/* <p className='p-0 m-0'>Old Password</p> */}
                                <div className="form-group mb-2 mt-2">

                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control shadow-none"
                                        placeholder="EMAIL"
                                        {...register("email", {
                                            required: "Email is required",

                                        })}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                        <div className="col-12 text-end p-2">
                                <p className='p-0 m-0' style={{color:'#A8A8A8'}} >Remember your password?</p>

                            </div>
                            <div className="col-12 text-center">
                                <button className='btn-reset-password' style={{width:'100%'}} type="submit" >Reset Password</button>

                            </div>
                        </div>
                            
                        </form>
                        
                </div>
            </div>
                
            </div>
        </div>
       
    );
}
export default Index;

