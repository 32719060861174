import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import Icon from 'react-icons-kit'
// import { Images } from '../../../utils/Images';
import { Modal } from 'react-bootstrap'
// import {ic_facebook} from 'react-icons-kit/md/'
import {ic_collections} from 'react-icons-kit/md/'

// import { api } from '../../../../../utils/api'
// import 'react-toastify/dist/ReactToastify.css'
import { x, } from 'react-icons-kit/feather'
import {ic_people, ic_arrow_drop_down_outline} from 'react-icons-kit/md/'

import { useForm } from 'react-hook-form'
import { Images } from '../../../utils/Images'
// import { toast } from 'react-toastify';
const Index = (props) => {

    // const [isLoading, setLoading] = useState(false) formState: { errors }, 

    const { register, handleSubmit, } = useForm();




    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const header = {
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    // }


    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            props.handleRefetch()
            

        } catch (error) {
            props.onHide();
        }
    }

    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-post "
            >
                <Modal.Header>
                    <p ></p>
                    <Modal.Title>Create post</Modal.Title>
                    <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button>
                </Modal.Header>
                <Modal.Body >
                    <div >

                        <div className='mt-2 border-none'>
                            <div className='card-body p-4'>
                                <div className='d-flex align-items-center'>
                                    <img className='img-profile' src={Images.Doctor2} alt=''/>
                                    <div>
                                        <p className='p-0 m-0 fw-bolder'>Dr. Iman Khan</p>
                                        <button><Icon icon={ic_people}/>Doctors <Icon icon={ic_arrow_drop_down_outline}/></button>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="row mt-1">

                                        {/* Name */}
                                        <div className="col-12">
                                            <div className="form-group mb-4">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    style={{border:'none'}}
                                                    className="form-control shadow-none"
                                                    placeholder="What's on your mind, Dr. Iman Khan?"
                                                    defaultValue={props.value ? props.value.name : null}
                                                    {...register("email", {
                                                        // required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                                <div className='d-flex justify-content-between img-btn' type='button'>
                                                    <p className='p-0 m-0'>Add to your post</p>
                                                    <Icon className='Icon' icon={ic_collections} />
                                                </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center mt-2 mb-0">
                                                <button type="submit" className='btn-post'>Post</button>

                                            </div>


                                    <div className='row'>
                                        <div className="text-center" >
                                            

                                            {/* <button  className="btn-save shadow-none" disabled={isLoading}  >
                                                    {isLoading ? 'Save...' : 'Save'}
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn shadow-none"
                                                    disabled={isLoading}>
                                                    {isLoading ? 'Next...' : 'Next'}
                                                </button> */}
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
