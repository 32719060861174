/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './style.scss'
// import Axios from 'axios'
import Icon from 'react-icons-kit'
import { ic_cancel_outline } from 'react-icons-kit/md/'
import { Modal } from 'react-bootstrap'
// import {Images} from '../../../utils/Images'
const Index = (props) => {

    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-appointment"
            >

                <Modal.Body >
                    <div className='p-4'>
                        <div className='d-flex justify-content-end'>
                            <Icon icon={ic_cancel_outline} size={40} onClick={props.onHide} />
                        </div>

                        <div >
                            {/* <p>You are booking Dr. Rahim's Appointment</p> */}
                            <h3 style={{ color: '#7D7C7D', fontSize:'20px' }}>You are booking Dr. Rahim's Appointment</h3>

                            <p className='fw-bolder text-center p-0 m-0' style={{color:'#00BD2B'}}>Payment Successful !</p>
                            <h2 className='mt-1 mb-4 text-center' style={{color:'#FF66C3'}}>Congrats !</h2>
                            <p className='p-0 m-0'>Doctor Name:Dr. Rahim</p>
                            <p className='p-0 m-0'>Appointment Time:5.44pm</p>
                            <p className='p-0 m-0'>Appointment Date:30 Oct, 2023</p>
                            <p className='mt-3 p-0 mb-0'>Invoice Number is <span className='fw-bolder'>25896211</span>. You can download</p>
                            <p className='p-0 m-0'>invoice anytime from invoice page. <a href='#'>Click Here to</a></p>
                            <p className='p-0 m-0'><a href='#'>Download Anyway</a></p>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
