


export const district = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
  ];
     
  export const insurance = [
    {title:'insurance', image:'http://localhost:3001/assets/doctors.jpg'},
    {title:'pandemic', image:'http://localhost:3001/assets/doctors.jpg'},
    {title:'awareness', image:'http://localhost:3001/assets/doctors.jpg'},
    {title:'online doctor', image:'http://localhost:3001/assets/doctors.jpg'},
    {title:'lab', image:'http://localhost:3001/assets/doctors.jpg'},
  ]