/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import './style.scss';
import Select from 'react-select';
import Icon from 'react-icons-kit'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { circle_left } from 'react-icons-kit/ikons/'
import { ic_help } from 'react-icons-kit/md/';

import Otp from '../patient-otp/index'
const Index = () => {
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [isClearable,] = useState(true); //setIsClearable
    const [isSearchable,] = useState(true); //setIsSearchable
    const [isDisabled,] = useState(false); //setIsDisabled
    const [isLoading, setLoading] = useState(false);
    const [addPatient, setAddPatient] = useState(false);
    const [otp, setOtp] = useState({ status: false })
    const [value, setValue] = useState()
    const [isRtl,] = useState(false); //setIsRtl
    const currentYear = new Date().getFullYear();

    const [day, setDay] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);

    const days = Array.from({ length: 31 }, (_, i) => ({ label: `${i + 1}`, value: `${i + 1}` }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const months = [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
    ];
    const years = Array.from({ length: 100 }, (_, i) => ({
        label: `${currentYear - i}`,
        value: `${currentYear - i}`,
    }));

    useEffect(() => {
        // Set default values when the component mounts

        setDay(days[0]);
        setMonth(months[0]);
        setYear(years[0]);
        setLoading(true)
    }, [days, months, years]); // The empty dependency array ensures the effect runs only once when the component mounts

    const handleDayChange = (selected) => {
        setDay(selected[0]);
    };



    const handleMonthChange = (selected) => {
        setMonth(selected[0]);
    };

    const handleYearChange = (selected) => {
        setYear(selected[0]);
    };

    const handleAddPatientChange = () => {
        setAddPatient(true);
    };

    const handleOTP = (data) => {
        setOtp({ status: true })
    };
    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);

        } catch (error) {

        }
    }
    return (
        <div className='practice-prescription'>
            <div className='d-flex align-items-center'>
                {/*  */}
                {addPatient ?
                    <div className='col-sm-12 col-md-10 col-lg-10'>
                        <h1>Adding New Patient</h1>

                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="row m-4">

                                <p className='fw-bolder text-start'>Patient Details</p>

                                {/* Name */}
                                <div className="col-sm-12 col-md-3">
                                    <div className="form-group d-flex mb-2">

                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control shadow-none"
                                            placeholder="First name"
                                            {...register("email", {
                                                required: "First name is required",

                                            })}
                                        />
                                    </div>
                                </div>

                                {/* Surname */}
                                <div className="col-sm-12 col-md-3">
                                    <div className="form-group mb-2">

                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control shadow-none"
                                            placeholder="Surname"
                                            {...register("email", {
                                                required: "Surname is required",

                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">

                                        <PhoneInput
                                            name="phone"
                                            className="form-control shadow-none  d-flex border-none"
                                            value={value}
                                            onChange={setValue}
                                            placeholder="Enter phone number"
                                        // {...register("phone", {
                                        //     required: "First name is required",

                                        // })}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 mt-2 text-start">
                                    {/* Birth Date */}
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group mb-2">
                                            {errors.phone && errors.phone.message ? (
                                                <p className="text-danger">{errors.phone && errors.phone?.message}</p>
                                            ) : <p>Birth Date <Icon icon={ic_help} /></p>
                                            }
                                            <div className="d-flex justify-content-between">

                                                <div className="col-3"> <Select options={days} onChange={handleDayChange} values={[day]} /></div>
                                                <div className="col-3"><Select options={months} onChange={handleMonthChange} values={[month]} /></div>
                                                <div className="col-3"><Select options={years} onChange={handleYearChange} values={[year]} /></div>


                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-sm-12 col-md-6 text-start">
                                    <div className="form-group mb-4">
                                        {errors.familyWith && errors.familyWith.message ? (
                                            <p className="text-danger">{errors.familyWith && errors.familyWith?.message}</p>
                                        ) : <p>Gender <Icon icon={ic_help} /></p>
                                        }
                                        <div className='d-flex mt-2'>
                                            <label className='form-control radio-gender d-flex justify-content-evenly align-items-center' style={{ marginRight: '20px' }}>
                                                Female

                                                <input
                                                    type="radio"
                                                    value="Female"
                                                // checked={selectedOption === 'Yes'}
                                                // onChange={handleOptionChange}
                                                />

                                            </label>

                                            <label className='form-control radio-gender d-flex justify-content-evenly align-items-center'>
                                                Male
                                                <input
                                                    type="radio"
                                                    value="Male"
                                                // checked={selectedOption === 'No'}
                                                // onChange={handleOptionChange}
                                                />

                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div><button className='view-record fw-bolder' onClick={() => handleOTP()} style={{ background: '#FFC7EA', color: '#FF65C2' }}>Give Rx</button></div>

                        </form>
                    </div> :
                    <div className='col-sm-12 col-md-10 col-lg-10'>
                        <h1 className='text-center fw-bolder' style={{ color: '#00BD13' }}>Ready</h1>
                        <p className='text-center p-0 m-0'>Select Schedule and load patients to Populate</p>
                        <p className='text-center p-0 m-0'>Patient List.</p>

                        <h1 className='text-center fw-bolder' style={{ color: '#00BD13' }}>Please Wait..</h1>
                        <p className='text-center p-0 m-0'>Your Assistive Al is preparing</p>

                    </div>
                }


                <div className='col-sm-12 col-md-2 col-lg-2'>
                    <div className='card p-2'>
                        <div className='d-flex'>
                            <div className='col-sm-12 col-md-6 col-lg-6'><h1 className='fw-bolder' style={{ color: '#00BD13' }}>60</h1></div>
                            <div className='col-sm-12 col-md-6 col-lg-6'><p><span>Patients Pending Today</span></p></div>
                        </div>


                        <Select
                            className="basic-single"
                            classNamePrefix="SPECIALIST"
                            // defaultValue={district[0]}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="color"
                            placeholder="Select Card"
                            styles={customStyles}
                        // options={district}
                        />

                        <div className='col-sm-12 col-md-12 col-lg-12 mt-1'>
                            <div className="form-group text-center mb-2">
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control shadow-none form-control-center"
                                    placeholder="Monday, 2PM - 5PM"
                                    {...register("email", {
                                        required: "Indications is required",

                                    })}
                                />
                            </div>
                        </div>

                        <div><button className='view-record' style={{ background: '#FFC7EA', width: '100%' }}>Load Patients</button></div>
                        <div><Icon icon={circle_left} /><h6>Todays Appointments</h6></div>

                    </div>

                    <div><button className='view-record mt-5' onClick={handleAddPatientChange} style={{ background: '#49A0AE' }}>ADD PATIENT</button></div>
                </div>

                {otp.status ?
                    <Otp
                        show={otp.status}
                        onHide={() => setOtp({ status: false })}
                    />
                    : null}
            </div>
        </div>
    )
}

const customStyles = {
    control: (styles) => ({
        ...styles,
        //   borderBottom: 'none',
        backgroundColor: 'white', // Adjust the background color as needed
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#000', // Change the color of the placeholder text
    }),
};

export default Index;