import React, { useState } from "react";
import './style.scss';
import Icon from 'react-icons-kit'
// import Login from '../../components/auth/models/Login'
// import Login from '../../components/auth/models/signup/SignUp'
// import Login from '../../components/auth/OTP'
import Login from '../../components/model/BookingPayment/BookAppointment'

// import Slider from '../../components/home/slider'
// import ProfileCard from '../../components/home/profileCard'
import {ic_thumb_up_outline, ic_share_outline,ic_access_time_outline, ic_place_outline} from 'react-icons-kit/md/'
import {speech_bubbles} from 'react-icons-kit/ikons/'
import {socialTumblr} from 'react-icons-kit/ionicons/'
import {circleRight} from 'react-icons-kit/iconic/'
import {ic_arrow_left, ic_arrow_right,ic_star_outline} from 'react-icons-kit/md/'

import { DateRangePicker } from 'rsuite';
import "rsuite/dist/rsuite.css";
import 'rsuite/styles/index.less';
import Select from 'react-select';
import {district, insurance, specialist} from './data'
import { Images } from "../../utils/Images";
const Index = () => {
    const [addNew, setAddNew] = useState({ status: false })
    const [isClearable, ] = useState(true); //setIsClearable
    const [isSearchable, ] = useState(true); //setIsSearchable
    const [isDisabled, ] = useState(false); //setIsDisabled
    const [isLoading, ] = useState(false); //setIsLoading
    const [isRtl, ] = useState(false); //setIsRtl

    const handleLogin = data => {
        setAddNew({ status: true })
    }
    return (
        <div className="home">
            {/* banner Section */}
            {/* <Login/> */}
            <div className="banner-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <img className="bannerImg" src={Images.Banner} alt="" />
                            {/* <h2>Image</h2> */}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-5">
                            <div className="card border-none">

                                <div className="card-body">
                                <div className="form-group mb-4">
                                <Select
                                        className="basic-single"
                                        classNamePrefix="DISTRICT"
                                        placeholder="DISTRICT"
                                        // defaultValue={district[0]}
                                        isDisabled={isDisabled}
                                        isLoading={isLoading}
                                        isClearable={isClearable}
                                        isRtl={isRtl}
                                        isSearchable={isSearchable}
                                        name="color"
                                        options={district}
                                    />
                                </div>
                                <div className="form-group mb-4">
                                <Select
                                        className="basic-single"
                                        classNamePrefix="SPECIALIST"
                                        placeholder="SPECIALIST"
                                        isDisabled={isDisabled}
                                        isLoading={isLoading}
                                        isClearable={isClearable}
                                        isRtl={isRtl}
                                        isSearchable={isSearchable}
                                        name="color"
                                        options={specialist}
                                    />
                                </div>
                                    
                                    <div className="form-group mb-4">
                                        <DateRangePicker
                                        placeholder='PICK A DATE'
                                            // onChange={handleRange}
                                            // onClean={handleRangeClean}
                                            // appearance="subtle" // Use a subtle appearance for mobile-friendly UI
                                            // style={{ width: '100%' }} // Set the width to 100% for mobile responsiveness

                                            block
                                        />
                                    </div>
                                    <div className="mt-4 text-center">
                                        <button type='button' className="mt-2 search-btn"  onClick={() => handleLogin()}>Search</button>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* insurance Section */}
            <div className="insuranceSection ">
                {/* <div className="container"> */}
                    <div className="row">
                    {insurance.map((data, i)=><div className="insuranceImg" key={i}>
                    <div > 
                        <img  src={Images.Lab} alt=""/>
                        <h4 className="mt-2">{data.title}</h4>
                    </div>

                </div>)}
                    {/* </div> */}
                
                </div>
                
            </div>

            {/*<div className="slider-section text-start ">*/}
            {/*    <h1>OUR DOCTORS POST</h1>*/}
            {/*    <div className="d-flex justify-content-around align-items-center">*/}
            {/*        <div className="m-0 p-0 d-none d-lg-block"><Icon icon={ic_arrow_left} size={80} style={{color:'#BF89C2'}}/></div>*/}
            {/*    <div className="card">*/}
            {/*        <div className="inner-shadow" style={{padding:'10px'}}>*/}
            {/*        <div className="d-flex align-items-end">*/}
            {/*            <img src={Images.Doctors} className="card-img" alt="..." />*/}
            {/*            <h5 className="card-title" style={{fontWeight:'bold'}}>Dr. Farheen Ahmed</h5>*/}
            {/*        </div>*/}
            {/*        <div className="card-body text-start">*/}
            {/*            <div className="mb-2" style={{color:"#968f8f"}}>*/}
            {/*            <p className="card-text" style={{fontWeight:'600', fontSize:'14px'}}>MBBS, MD- Dermatology, Venereolgy</p>*/}
            {/*            <p><Icon icon={ic_access_time_outline}/> 4 Dec 2023</p>*/}
            {/*            <p className="card-text"><Icon icon={ic_place_outline}/>Dhaka, Bangladesh</p>*/}
            {/*            </div>*/}
            {/*            */}
            {/*            <h5>Mediserv.Ai - Regular health checks can help to idetify...</h5>*/}
            {/*            <p className="card-text">a medical examination by your doctor to make sure that there ... See More</p>*/}
            {/*            <div className="mt-2"><p>*/}
            {/*                <Icon style={{color:'#B887BB'}} icon={ic_thumb_up_outline}/> */}
            {/*                <span className="card-border">2.2k</span> <Icon style={{color:'#B887BB'}} icon={ic_share_outline}/>*/}
            {/*                <span className="card-border">117</span> <Icon style={{color:'#B887BB'}} icon={speech_bubbles}/> */}
            {/*                <span className="card-border">202</span>comments</p></div>*/}
            {/*        </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="card d-none d-lg-block d-md-block">*/}
            {/*        <div className="inner-shadow" style={{padding:'10px'}}>*/}
            {/*        <div className="d-flex align-items-end">*/}
            {/*            <img src={Images.Doctors} className="card-img" alt="..." />*/}
            {/*            <h5 className="card-title" style={{fontWeight:'bold'}}>Dr. Farheen Ahmed</h5>*/}
            {/*        </div>*/}
            {/*        <div className="card-body text-start">*/}
            {/*            <div className="mb-2" style={{color:"#968f8f"}}>*/}
            {/*            <p className="card-text" style={{fontWeight:'600', fontSize:'14px'}}>MBBS, MD- Dermatology, Venereolgy</p>*/}
            {/*            <p><Icon icon={ic_access_time_outline}/> 4 Dec 2023</p>*/}
            {/*            <p className="card-text"><Icon icon={ic_place_outline}/>Dhaka, Bangladesh</p>*/}
            {/*            </div>*/}
            {/*            */}
            {/*            <h5>Mediserv.Ai - Regular health checks can help to idetify...</h5>*/}
            {/*            <p className="card-text">a medical examination by your doctor to make sure that there ... See More</p>*/}
            {/*            <div className="mt-2"><p>*/}
            {/*                <Icon style={{color:'#B887BB'}} icon={ic_thumb_up_outline}/> */}
            {/*                <span className="card-border">2.2k</span> <Icon style={{color:'#B887BB'}} icon={ic_share_outline}/>*/}
            {/*                <span className="card-border">117</span> <Icon style={{color:'#B887BB'}} icon={speech_bubbles}/> */}
            {/*                <span className="card-border">202</span>comments</p></div>*/}
            {/*        </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="card d-none d-lg-block">*/}
            {/*        <div className="inner-shadow" style={{padding:'10px'}}>*/}
            {/*        <div className="d-flex align-items-end">*/}
            {/*            <img src={Images.Doctors} className="card-img" alt="..." />*/}
            {/*            <h5 className="card-title" style={{fontWeight:'bold'}}>Dr. Farheen Ahmed</h5>*/}
            {/*        </div>*/}
            {/*        <div className="card-body text-start">*/}
            {/*            <div className="mb-2" style={{color:"#968f8f"}}>*/}
            {/*            <p className="card-text" style={{fontWeight:'600', fontSize:'14px'}}>MBBS, MD- Dermatology, Venereolgy</p>*/}
            {/*            <p><Icon icon={ic_access_time_outline}/> 4 Dec 2023</p>*/}
            {/*            <p className="card-text"><Icon icon={ic_place_outline}/>Dhaka, Bangladesh</p>*/}
            {/*            </div>*/}
            {/*            */}
            {/*            <h5>Mediserv.Ai - Regular health checks can help to idetify...</h5>*/}
            {/*            <p className="card-text">a medical examination by your doctor to make sure that there ... See More</p>*/}
            {/*            <div className="mt-2"><p>*/}
            {/*                <Icon style={{color:'#B887BB'}} icon={ic_thumb_up_outline}/> */}
            {/*                <span className="card-border">2.2k</span> <Icon style={{color:'#B887BB'}} icon={ic_share_outline}/>*/}
            {/*                <span className="card-border">117</span> <Icon style={{color:'#B887BB'}} icon={speech_bubbles}/> */}
            {/*                <span className="card-border">202</span>comments</p></div>*/}
            {/*        </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="card d-none d-lg-block">*/}
            {/*        <div className="inner-shadow" style={{padding:'10px'}}>*/}
            {/*        <div className="d-flex align-items-end">*/}
            {/*            <img src={Images.Doctors} className="card-img" alt="..." />*/}
            {/*            <h5 className="card-title" style={{fontWeight:'bold'}}>Dr. Farheen Ahmed</h5>*/}
            {/*        </div>*/}
            {/*        <div className="card-body text-start">*/}
            {/*            <div className="mb-2" style={{color:"#968f8f"}}>*/}
            {/*            <p className="card-text" style={{fontWeight:'600', fontSize:'14px'}}>MBBS, MD- Dermatology, Venereolgy</p>*/}
            {/*            <p><Icon icon={ic_access_time_outline}/> 4 Dec 2023</p>*/}
            {/*            <p className="card-text"><Icon icon={ic_place_outline}/>Dhaka, Bangladesh</p>*/}
            {/*            </div>*/}
            {/*            */}
            {/*            <h5>Mediserv.Ai - Regular health checks can help to idetify...</h5>*/}
            {/*            <p className="card-text">a medical examination by your doctor to make sure that there ... See More</p>*/}
            {/*            <div className="mt-2"><p>*/}
            {/*                <Icon style={{color:'#B887BB'}} icon={ic_thumb_up_outline}/> */}
            {/*                <span className="card-border">2.2k</span> <Icon style={{color:'#B887BB'}} icon={ic_share_outline}/>*/}
            {/*                <span className="card-border">117</span> <Icon style={{color:'#B887BB'}} icon={speech_bubbles}/> */}
            {/*                <span className="card-border">202</span>comments</p></div>*/}
            {/*        </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="m-0 p-0 d-none d-lg-block"><Icon icon={ic_arrow_right} size={80} style={{color:'#BF89C2'}}/></div>*/}

            {/*    </div>*/}
            {/*    */}
            {/*/!* <ProfileCard/> *!/*/}
            {/*    /!* <div className="container"> *!/*/}
            {/*        /!* <div className="d-flex">*/}
            {/*        */}
            {/*    */}
            {/*        */}
            {/*    */}
            {/*    </div> *!/*/}
            {/*    */}
            {/*</div>*/}

            <div>
            {/*<div className="slider-section text-start ">*/}
            {/*    <h1>OUR <span style={{ color: '#a356a8' }}>*/}
            {/*    CARE GIVER</span></h1>*/}
            {/*    <div className="d-flex justify-content-around align-items-center">*/}
            {/*        <div className="m-0 p-0 d-none d-lg-block"><Icon icon={ic_arrow_left} size={80} style={{color:'#BF89C2'}}/></div>*/}
            {/*    <div className="card">*/}
            {/*        <img src={Images.Doctor} className="card-img-top" alt="..." />*/}
            {/*        <div className="card-body text-start">*/}
            {/*            <h5 className="card-title">Dr. Farheen Ahmed</h5>*/}
            {/*            <p className="card-text" style={{fontWeight:'600', fontSize:'15px'}}>MBBS, MD- Dermatology, Venereolgy</p>*/}
            {/*            <div className="card-text d-flex">*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#BCBCBC", marginTop:'-9px'}}/>*/}
            {/*                <h6>4.9(82)</h6></div>*/}
            {/*            <p className="card-text">Dhaka, Bangladesh</p>*/}
            {/*            <p className="card-text">Available on Fri,Mar</p>*/}
            {/*            <p className="card-text"><Icon icon={socialTumblr} size={12}/>500-<Icon icon={socialTumblr} size={12}/>400</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="card d-none d-lg-block d-md-block">*/}
            {/*        <img src={Images.Doctor} className="card-img-top" alt="..." />*/}
            {/*        <div className="card-body text-start">*/}
            {/*            <h5 className="card-title">Dr. Farheen Ahmed</h5>*/}
            {/*            <p className="card-text" style={{fontWeight:'600', fontSize:'15px'}}>MBBS, MD- Dermatology, Venereolgy</p>*/}
            {/*            <div className="card-text d-flex">*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#BCBCBC", marginTop:'-9px'}}/>*/}
            {/*                <h6>4.9(82)</h6></div>*/}
            {/*            <p className="card-text">Dhaka, Bangladesh</p>*/}
            {/*            <p className="card-text">Available on Fri,Mar</p>*/}
            {/*            <p className="card-text"><Icon icon={socialTumblr} size={12}/>500-<Icon icon={socialTumblr} size={12}/>400</p>*/}
            {/*        </div>*/}
            {/*    </div><div className="card d-none d-lg-block">*/}
            {/*        <img src={Images.Doctor} className="card-img-top" alt="..." />*/}
            {/*        <div className="card-body text-start">*/}
            {/*            <h5 className="card-title">Dr. Farheen Ahmed</h5>*/}
            {/*            <p className="card-text" style={{fontWeight:'600', fontSize:'15px'}}>MBBS, MD- Dermatology, Venereolgy</p>*/}
            {/*            <div className="card-text d-flex">*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#BCBCBC", marginTop:'-9px'}}/>*/}
            {/*                <h6>4.9(82)</h6></div>*/}
            {/*            <p className="card-text">Dhaka, Bangladesh</p>*/}
            {/*            <p className="card-text">Available on Fri,Mar</p>*/}
            {/*            <p className="card-text"><Icon icon={socialTumblr} size={12}/>500-<Icon icon={socialTumblr} size={12}/>400</p>*/}
            {/*        </div>*/}
            {/*    </div><div className="card d-none d-lg-block">*/}
            {/*        <img src={Images.Doctor} className="card-img-top" alt="..." />*/}
            {/*        <div className="card-body text-start">*/}
            {/*            <h5 className="card-title">Dr. Farheen Ahmed</h5>*/}
            {/*            <p className="card-text" style={{fontWeight:'600', fontSize:'15px'}}>MBBS, MD- Dermatology, Venereolgy</p>*/}
            {/*            <div className="card-text d-flex">*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#FFC107", marginTop:'-9px'}}/>*/}
            {/*                <Icon icon={ic_star_outline} style={{ color:"#BCBCBC", marginTop:'-9px'}}/>*/}
            {/*                <h6>4.9(82)</h6></div>*/}
            {/*            <p className="card-text">Dhaka, Bangladesh</p>*/}
            {/*            <p className="card-text">Available on Fri,Mar</p>*/}
            {/*            <p className="card-text"><Icon icon={socialTumblr} size={12}/>500-<Icon icon={socialTumblr} size={12}/>400</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className="m-0 p-0 d-none d-lg-block"><Icon icon={ic_arrow_right} size={80} style={{color:'#BF89C2'}}/></div>*/}

            {/*    </div>*/}
            {/*/!* <ProfileCard/> *!/*/}
            {/*    /!* <div className="container"> *!/*/}
            {/*        /!* <div className="d-flex">*/}
            {/*        */}
            {/*    */}
            {/*        */}
            {/*    */}
            {/*    </div> *!/*/}
            {/*    */}
            {/*</div>*/}
            </div>
           <div className="tickets-section  align-content-center justify-content-evenly align-items-center">
                <div  className="col-sm-12 col-md-12 col-lg-12">
                    <img className="tickets-img" src={Images.Tickets} alt=""/>
                </div>
                {/*<div className="col-sm-12 col-md-6 col-lg-6 text-left">*/}
                {/*    /!*<h3 className="mb-3 text-left">Instructions to Purchase Tickets</h3>*!/*/}
                {/*    /!*<p><Icon icon={circleRight}/> There are many variations of passages of lorem larum available, but</p>*!/*/}
                {/*    /!*<p><Icon icon={circleRight}/> There are many variations of passages of lorem larum available, but</p>*!/*/}
                {/*    /!*<p><Icon icon={circleRight}/> There are many variations of passages of lorem larum available, but</p>*!/*/}
                {/*    /!*<p><Icon icon={circleRight}/> There are many variations of passages of lorem larum available, but</p>*!/*/}
                {/*    /!*<p><Icon icon={circleRight}/> There are many variations of passages of lorem larum available, but</p>*!/*/}
                {/*    /!*<p><Icon icon={circleRight}/> There are many variations of passages of lorem larum available, but</p>*!/*/}
                {/*    /!*<p><Icon icon={circleRight}/> There are many variations of passages of lorem larum available, but</p>*!/*/}
                {/*    <div className="mt-5 justify-content-end">*/}
                {/*        <img className="tickets-img-AppStore" src={Images.AppStore} alt=""/>*/}
                {/*        <img className="tickets-img-AppStore" src={Images.GooglePlay} alt=""/>*/}
                {/*    </div>*/}
                {/*</div>*/}
           </div>


           <div className="img-payment-section">
           <hr style={{background:'grey', height:"2px"}} />
            <img className="img-payment" src={Images.Payment} alt=""/>
           <hr style={{background:'grey', height:"2px"}} />

           </div>


           <div className="container">
            <h6 className="m-3 d-flex">The Tickets are issued by Mediserv's Centrally Computerized Seat Reservation & Ticketing System (MCCSRTS) and Mediserv Private Ltd. is responsible for designing, development, implementation, technical operation & maintenance of the system.</h6>
           </div>

            {addNew.status ?
                <Login
                    show={addNew.status}
                    showNumber = {0}
                    onHide={() => setAddNew({ status: false })}
                />
                : null}
        </div>

    )


}
export default Index;