import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import { useForm } from 'react-hook-form'
import {  useNavigate } from "react-router-dom";
// import Icon from 'react-icons-kit'
// import { ic_save_alt_twotone} from 'react-icons-kit/md/'

const Index = () => {
    const navigate = useNavigate();
    const { register, } = useForm();//handleSubmit, formState: { errors }, 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            navigate(`/doctor/change-password`);
        
        } catch (error) {
 
        }
    }
    return (

        <div className="profile-setting">
   
                <div className="col-sm-12 card p-4 mt-2 mb-5 text-start">
                {/* <h3 className='fw-bold'>Clinic Information</h3> */}
                   
                <form onSubmit={onSubmit}>
                    <div className='row'>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            
                            <div className="form-group  p-3 mb-2 mt-2 pb-0">
                            <p className='p-0 m-0 '>Clinic Name</p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div> 

                        <div className="col-sm-12 col-md-6 col-lg-6">
                            
                            <div className="form-group  p-3 mb-2 mt-2 pb-0">
                            <p className='p-0 m-0 '>Clinic Address</p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div>  
                        <div className=' p-3 mb-2 mt-2 pb-0'>
                        <p className='fw-bold'>Clinic Images</p>
                        <div className='m-2 UploadPhoto d-flex justify-content-center align-content-center align-items-center' style={{ height: '150px',  background: '#F6F6F6' }}>
                            <p>Drop Files here to upload</p>
                        </div>
                        <div className='d-flex align-items-center'>
                        <div className='m-2 UploadPhoto' style={{ height: '100px', width:'100px', background: '#D9D9D9' }}>
                        </div>
                        <div className='m-2 UploadPhoto' style={{ height: '100px', width:'100px', background: '#D9D9D9' }}>
                        </div>
                        </div>
                        
                    </div>

                       
                                                 
                    </div>
                    

                    
                    
                </form>
                

                </div>
           
                
            </div>
       
    );
}
export default Index;

