/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import { Images } from '../../../utils/Images'
const Index = () => {

    return (
        <div className="vaccination-record-card">
            <table className="table table-bordered">
                <thead>
                    <tr className='text-center'>
                        <th scope="col">Name</th>
                        <th scope="col">Date</th>
                        <th scope="col">Dosage</th>
                        <th scope="col">Lot Number</th>
                        <th scope="col">Manufacturer</th>
                        <th scope="col">Location/Site</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='text-center'>
                        <td>Vaccine Name</td>
                        <td>8/16/2023</td>
                        <td>2</td>
                        <td>123</td>
                        <td>Sinovac</td>
                        <td>Public School</td>
                        <td>
                            <a href='#'>Edit</a>
                        </td>
                    </tr>

                    <tr className='text-center'>
                        <td>Vaccine Name</td>
                        <td>8/16/2023</td>
                        <td>2</td>
                        <td>123</td>
                        <td>Sinovac</td>
                        <td>Public School</td>
                        <td>
                            <a href='#'>Edit</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Index;