import React from "react";
import './style.scss';
// import { Images } from '../../utils/Images'
import Icon from 'react-icons-kit'
// import {user} from 'react-icons-kit/ikons/user'
import {ic_toggle_on} from 'react-icons-kit/md/'

import Ads from '../../components/ads/index'
import QuickISearch from '../../components/QuickISearch/Index'
import Doctor from '../../components/doctor/profile/doctorProfile'
const Index = () => {
    // const [isScrolling, setIsScrolling] = useState(false);

    // // Function to handle scroll event
    // const handleScroll = () => {
    //   // Check if the page is scrolled
    //   if (window.scrollY > 0) {
    //     setIsScrolling(true); // Set state to indicate scrolling
    //   } else {
    //     setIsScrolling(false); // Set state to indicate no scrolling
    //   }
    // };
  
    // Add scroll event listener when the component mounts
    // React.useEffect(() => {
    //   window.addEventListener('scroll', handleScroll);
    //   return () => {
    //     window.removeEventListener('scroll', handleScroll);
    //   };
    // }, []);
    return (
        <div className="doctors find-care ">
           < QuickISearch/>
        <div className="community">
            {/* <div className="d-flex"> */}
                <div className="col-lg-2 d-none d-lg-block">
                    <div className="card mt-3 mb-3 me-lg-3">
                       <h4 className="fw-bold pt-4">Graph Search</h4>
                       <hr className="m-0" style={{height:'2px'}}/>
                        <p className="mt-5 mb-0 fw-bold">Earliest Available <Icon icon={ic_toggle_on} size={40} style={{color:'#A356A8'}}/></p>
                        <p className="mb-5 fw-bold">Earliest Available <Icon icon={ic_toggle_on} size={40} style={{color:'#A356A8'}}/></p>

                        <button className=" Specialization mb-3">District</button>
                        <button className=" Specialization mb-3">Specialization</button>

                        <div className="mt-4 m-3 text-start">
                            <h6 className="fw-bold">Gender</h6>
                            <div className="d-flex m-0 fw-bold">
                                <label className=" fw-bold mb-0" for="btncheck1">Male Doctor</label>
                                <input type="checkbox" id="btncheck1" autocomplete="off"/>
                            </div>
                            <div className="d-flex m-0 fw-bold">
                                <label className=" fw-bold mb-0" for="btnchec21">Female Doctor</label>
                                <input type="checkbox" id="btncheck2" autocomplete="off"/>
                            </div>
                        </div>

                        <button className="go mb-3" >Go</button>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8" style={{background:'#fff'}}>
                <Doctor/>
                <Doctor/>
                <Doctor/>
                <Doctor/>
                    {/* <div className="card mt-3 mb-3 mr-3">
                        <div className="d-flex justify-content-end pt-5"><button className="Dermatology">DERMATOLOGY</button></div>
                        <div className="profile-card mb-5">
                        <div className="d-flex align-items-baseline">
                            <div className="col-sm-12 col-md-4 col-lg-4">
                            <img className="m-3" style={{marginTop:'60px'}} src={Images.Doctor2} alt=""/>
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-8 text-start">
                                <div className="d-flex align-items-center">
                                    <Icon className="m-1 profile-icon" icon={user}/>
                                    <h5 className="profile-text mb-0"> Dr. Iman Khan</h5>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Icon className="m-1 profile-icon" icon={user}/>
                                    <h5 className="mb-0 profile-text" style={{color:'#6C6C6C'}}> Assistant Professor</h5>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Icon className="m-1 profile-icon" icon={user}/>
                                    <p className="mb-0 fw-bold profile-text" style={{color:'#6C6C6C'}}> Dermatology</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Icon className="m-1 profile-icon" icon={user}/>
                                    <p className="mb-0 fw-bold profile-text" style={{color:'#6C6C6C'}}> kurmitola General Hospital Dh...</p>
                                </div>
                                
                            </div>
                        </div>

                        <div className="m-4">
                        <div className="d-flex align-items-center">
                                    <Icon className="m-1 profile-icon" icon={user}/>
                                    <h5 className="mb-0 profile-text"> Dr. Iman Khan</h5>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Icon className="m-1 profile-icon" icon={user}/>
                                    <h5 className="mb-0 profile-text" style={{color:'#6C6C6C'}}> Assistant Professor</h5>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Icon className="m-1 profile-icon" icon={user}/>
                                    <h5 className="mb-0 profile-text" style={{color:'#6C6C6C'}}> Dermatology</h5>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Icon className="m-1 profile-icon" icon={user}/>
                                    <h5 className="mb-0 profile-text" style={{color:'#6C6C6C'}}> kurmitola General Hospital Dh...</h5>
                                </div>
                        </div>

                        <div className="d-flex  text-end justify-content-end mb-4">
                            <button className="profile-follow">FOLLOW</button>
                            <button className="profile-view">VIEW PROFILE</button>
                            <button className="profile-book" style={{color:'#fff'}}>BOOK APPOINTMENT</button>
                        </div>
                        </div>
                        
                    </div> */}
                </div>
                {/* <div> */}
                {/* <div className={isScrolling ? 'container scrolling position-fixed me-lg-2 card d-none d-lg-block end-0 overflow-auto' : 'me-lg-2 container card d-none d-lg-block'}  style={{width:'230px', maxHeight: "85vh"}}> */}
                <div className=' me-lg-2  d-none d-lg-block'>
                    <div className="card"><Ads/></div>               
                </div>
            </div>
        {/* </div> */}
        </div>
    );
}
export default Index;