import React from "react";
import './style.scss';
import Icon from 'react-icons-kit'
import { ic_reply, ic_thumb_up_alt_outline } from 'react-icons-kit/md/'
import { Images } from "../../../../utils/Images";
const Index = () => {
    return (
        <div className="doctor-reviews">
            {/* <h4>Show account suggestions on profiles</h4>
            <div className="hide-list">
                <h3>You can hide this list</h3>
                <p>Choose whether people can see similar account suggestions on your profile,
                    and whether your account can be suggested on other profile
                </p>
            </div> */}
            <div className="comment ">
                <div className="d-flex">
                    <img className="img-comment" src={Images.Female} alt="" />
                    <div className="">
                        {/* <img className="img-comment" src={Images.Female} alt=""/>  */}
                        <div>
                            <p className="p-0 m-0 fw-bold">Nd. Niloy</p>
                            <p className="p-0 m-0">Reviewed 2days ago</p>
                            {/* <img className="img-comment" src={Images.Female} alt=""/> */}
                        </div>

                        <p style={{ color: 'green' }}><Icon icon={ic_thumb_up_alt_outline} /> I recommend the doctor</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                        <div className="d-flex justify-content-between align-items-center">
                            <p className="p-0 m-0" style={{ color: 'blue' }}><Icon icon={ic_reply} />Reply</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="p-0 m-0">Recommend?</p>
                                <button className="follow-btn">Yes <Icon icon={ic_thumb_up_alt_outline} /></button>
                                <button className="follow-btn">No <Icon icon={ic_thumb_up_alt_outline} /></button>
                            </div>
                        </div>

                        <div className=" d-flex ms-3">
                            <img className="img-comment" src={Images.Female} alt="" />
                            <div>
                                <div>
                                    <p className="p-0 m-0 fw-bold">Nd. Niloy</p>
                                    <p className="p-0 m-0">Reviewed 2days ago</p>
                                </div>
                                <p style={{ color: 'green' }}><Icon icon={ic_thumb_up_alt_outline} /> I recommend the doctor</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                <p style={{ color: 'blue' }}><Icon icon={ic_reply} />Reply</p>
                                <div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Index;