


export const district = [
    { value: 'dhaka', label: 'Dhaka', color: '#00B8D9', isFixed: true },
    { value: 'Rajshahi', label: 'Rajshahi', color: '#0052CC', isDisabled: true },
    { value: 'Chattagram', label: 'Chattagram', color: '#5243AA' },
    { value: 'Khulna', label: 'Khulna', color: '#FF5630', isFixed: true },
    { value: 'Barishal', label: 'Barishal', color: '#FF8B00' },
    { value: 'Rangpur', label: 'Rangpur', color: '#FFC400' },
    { value: 'Sylhet', label: 'Sylhet', color: '#36B37E' },
    { value: 'Brahmanbaria', label: 'Brahmanbaria', color: '#00875A' },
  ];
export const specialist = [
    { value: 'Cardiologist', label: 'Cardiologist', color: '#00B8D9', isFixed: true },
    { value: 'Neurologists', label: 'Neurologists', color: '#0052CC', isDisabled: true },
    { value: 'Oncologist', label: 'Oncologist', color: '#5243AA' },
    { value: 'Gynecologist', label: 'Gynecologist', color: '#FF5630', isFixed: true },
    { value: 'Pediatrician', label: 'Pediatrician', color: '#FF8B00' },
    { value: 'Dermatologist', label: 'Dermatologist', color: '#FFC400' },
    { value: 'Psychiatrist', label: 'Psychiatrist', color: '#36B37E' },
    { value: 'Endocrinologist', label: 'Endocrinologist', color: '#00875A' },
]
     
  export const insurance = [
    {title:'insurance', image:'http://localhost:3001/assets/doctors.jpg'},
    {title:'pandemic', image:'http://localhost:3001/assets/doctors.jpg'},
    {title:'awareness', image:'http://localhost:3001/assets/doctors.jpg'},
    {title:'online doctor', image:'http://localhost:3001/assets/doctors.jpg'},
    {title:'lab', image:'http://localhost:3001/assets/doctors.jpg'},
  ]