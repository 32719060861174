import React, { useState } from 'react'
import './style.scss';
// import Requests from "../../utils/Requests/Index"
import Icon from 'react-icons-kit'
import { Images } from '../../utils/Images'
import { NavLink, Link } from 'react-router-dom'
import Login from '../auth/models/Login'
import SignUp from '../auth/models/signup/SignUp'
import PhoneVerify from '../auth/OTP'
import {ic_arrow_right_alt, ic_public_outline, ic_keyboard_arrow_down} from 'react-icons-kit/md/'

// import { DropdownButton, Dropdown } from 'react-bootstrap'
// import { thumbsUp, search,  } from 'react-icons-kit/feather'
// import { person } from 'react-icons-kit/oct'



const Index = () => {
    const [login, setLogin] = useState({ status: false })
    const [signUp, setSignUp] = useState({ status: false })
    const [otp, setOtp] = useState({ status: false })

    const handleLogin = data => {
        setLogin({ status: true })
    }

    const handleSignUp = data => {
        setLogin({ status: false })
        setSignUp({ status: true })
    }

    const handleOtp = data => {
        setSignUp({ status: false })
        setOtp({ status: true })
    }
    // const [header] = useState({ headers: { Authorization: "Bearer " + localStorage.getItem('token') } })
    // const { user } = useContext(UserContext)
    // const [dialog, setDialog] = useState({ view: false, state: null })
    // const messageBoxState = useSelector((state) => state.messageBoxState.view)
    // const connectedList = useSelector((state) => state.membersList.connected)
    // const matchList = useSelector((state) => state.membersList.match)
    // const previousList = useSelector((state) => state.membersList.previous)
    // const arrivalMsgCount = useSelector((state) => state.arrivalMessage.count)
    // const connectionNotificationCount = useSelector((state) => state.notification.count)
    // const notifications = useSelector((state) => state.notification)
    // const [view, setView] = useState(false)
    // const dispatch = useDispatch()

    // const doLogout = async () => {
    //     try {
    //         await Requests.Auth.Logout(header);
    //         localStorage.clear()
    //         window.location.reload()
    //     } catch (err) {
    //         if (err) console.log(err);
    //     }
    // }
    // const handleNotificationView = e => {
    //     e.preventDefault()
    //     setView(!view)
    // }
    // const removeViewPerson = async() =>{
    //     try {
    //         const res = await Requests.Auth.DeleteProfileViewNotification(header);
    //         if(res.status){
    //             dispatch(seenProfileViewed())
    //         }
    //         else{
    //         }
    //     } catch (err) {
    //         if (err) console.log(err);
    //     }
    // }

    // if (!user) return 'loading'
    return (
        <div className="appbar-container">
            <div className="custom-appbar shadow">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-enter">

                                <div>
                                    <Link to="/">
                                        {/* <div className='d-flex flex-column align-items-center'> */}
                                            {/* <img  style={{ width: '45px' }} src={Images.Logo} className="d-sm-none d-md-block img-fluid" alt="Logo" /> */}
                                            <img style={{ height: '20px' }} src={Images.TextLogo} className="img-fluid" alt="Logo" />
                                        {/* </div> */}

                                    </Link>
                                </div>

                                {/* Page links */}
                                <div className="d-none d-lg-block ">
                                    <ul>
                                        <li><NavLink activeClassName="isActive" to="/">Home</NavLink></li>
                                        <li><NavLink activeClassName="isActive" to="/under_construction">Doctors</NavLink></li>
                                        {/*<li className="subnav"><NavLink*/}
                                        {/*    activeClassName=" ">Feature <Icon*/}
                                        {/*    icon={ic_keyboard_arrow_down} size={20}/></NavLink>*/}
                                        {/*    <div className="subnav-content">*/}
                                        {/*        <div className='d-flex justify-content-around'>*/}
                                        {/*            <div className='card'>*/}
                                        {/*                <Icon icon={ic_public_outline} size={30}/>*/}
                                        {/*                <h5>Message Privately</h5>*/}
                                        {/*                <p className='ext-align-start'>There are many variations of*/}
                                        {/*                    passages</p>*/}
                                        {/*                <Icon icon={ic_arrow_right_alt} size={30}/>*/}
                                        {/*            </div>*/}
                                        {/*            <div className='card'>*/}
                                        {/*                <Icon icon={ic_public_outline} size={30}/>*/}
                                        {/*                <h5>Message Privately</h5>*/}
                                        {/*                <p className='ext-align-start'>There are many variations of*/}
                                        {/*                    passages</p>*/}
                                        {/*                <Icon icon={ic_arrow_right_alt} size={30}/>*/}
                                        {/*            </div>*/}
                                        {/*            <div className='card'>*/}
                                        {/*                <Icon icon={ic_public_outline} size={30}/>*/}
                                        {/*                <h5>Message Privately</h5>*/}
                                        {/*                <p className='ext-align-start'>There are many variations of*/}
                                        {/*                    passages</p>*/}
                                        {/*                <Icon icon={ic_arrow_right_alt} size={30}/>*/}
                                        {/*            </div>*/}
                                        {/*            <div className='card'>*/}
                                        {/*                <Icon icon={ic_public_outline} size={30}/>*/}
                                        {/*                <h5>Message Privately</h5>*/}
                                        {/*                <p className='ext-align-start'>There are many variations of*/}
                                        {/*                    passages</p>*/}
                                        {/*                <Icon icon={ic_arrow_right_alt} size={30}/>*/}
                                        {/*            </div>*/}
                                        {/*            <div className='card'>*/}
                                        {/*                <Icon icon={ic_public_outline} size={30}/>*/}
                                        {/*                <h5>Message Privately</h5>*/}
                                        {/*                <p className='ext-align-start'>There are many variations of*/}
                                        {/*                    passages</p>*/}
                                        {/*                <Icon icon={ic_arrow_right_alt} size={30}/>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</li>*/}
                                        <li><NavLink activeClassName="isActive" to="/under_construction">Community</NavLink></li>
                                        <li><NavLink activeClassName="isActive" to="/under_construction">Help Center</NavLink>
                                        </li>
                                        <li><NavLink activeClassName="isActive" to="/terms_condition">T&C</NavLink></li>
                                        <li><NavLink activeClassName="isActive" to="/privacy_policy">Privacy Policy</NavLink>
                                        </li>
                                        <li><NavLink to="/under_construction">Login</NavLink></li>
                                    </ul>
                                </div>

                                <div className='d-flex align-items-center'>
                                    <NavLink className="d-none d-lg-block me-2 fw-bolder text-decoration-none" style={{color:'#AC6164'}} activeClassName="isActive" to="#">L/T <Icon icon={ic_keyboard_arrow_down}/></NavLink>
                                {/*   onClick={() => handleSignUp()} */}
                                    <Link className='btn-register text-decoration-none' to='/under_construction'>Register as DOCTOR</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {login.status ?
                <Login
                    show={login.status}
                    handleSignUp ={handleSignUp}
                    onHide={() => setLogin({ status: false })}
                />
                : null}

            {signUp.status ?
                <SignUp
                    show={signUp.status}
                    handleOtp={handleOtp}
                    onHide={() => setSignUp({ status: false })}
                />
                : null}

            {otp.status?
                <PhoneVerify
                    show={otp.status}
                    // handleOtp={handleOtp}
                    onHide={() => setOtp({ status: false })}
                />:null
            }

        </div>
    );
};

export default Index;