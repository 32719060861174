import React from "react";
import Icon from 'react-icons-kit'
import {ic_check_circle} from 'react-icons-kit/md/'
import './index.css'

const Index = () => {
    return (
        <div className="container">
            <div className="content-container">
                <h1>Terms and conditions </h1>
                <h4>এই টার্মস এন্ড কন্ডিশনস  সর্বশেষ হালনাগাদ  করা হয় ১১ ই জুন ২০২৪ ইং তারিখে।</h4>
                <ol>
                    <li>
                        একজন ব্যক্তি একই দিন একই মোবাইল নম্বর বা আইডি দিয়ে কেবল দুটি অ্যাপয়েন্টমেন্ট বুক করতে পারেন।
                        প্রতিটি অ্যাপয়েন্টমেন্টের জন্য ব্যবহারকারীকে বিকাশ/নগদ/ক্রেডিট কার্ডের মাধ্যমে ২০ টাকা বা
                        সমপরিমাণ স্থানীয় মুদ্রা প্রদান করতে হবে। এই পেমেন্ট পদ্ধতিগুলি শুধুমাত্র বাংলাদেশের মধ্যে থেকে
                        করা হলে বৈধ হবে। ৬/১১/২০২৪ থেকে পরবর্তী পরিবর্তন পর্যন্ত এই নিয়ম কার্যকর থাকবে।
                    </li>
                    <li>
                        একজন ব্যক্তি একই মোবাইল নম্বর দিয়ে কেবল একটি আইডি খুলতে পারবেন। রোগীরা তাদের প্রোফাইলের মধ্যে
                        পরিবারের সদস্যদের যোগ করতে পারবেন।
                    </li>
                    <li>
                        ডাক্তারের আইডিতে স্থানান্তরিত হওয়ার সুবিধা একজন ইউজার mediserv.ai নীতিমালা অনুযায়ী ডিজিটাল এবং
                        ম্যানুয়ালি ভেরিফিকেশনের পরই পাবেন। ডাক্তার আইডিগুলো আমাদের AI অ্যাসিস্ট্যান্ট Maya'r সকল ফিচার
                        বিনামূল্যে ব্যবহার করতে পারবেন।
                    </li>
                    <li>
                        অ্যাপয়েন্টমেন্টের সময় ডাক্তারের অধীনে; যদি ডাক্তার অ্যাপয়েন্টমেন্ট বাতিল করে, ব্যবহারকারী ৩
                        সপ্তাহের মধ্যে একই ডাক্তার বা আমাদের প্ল্যাটফর্মে উপলব্ধ আরেকটি ডাক্তারের সাথে অ্যাপয়েন্টমেন্ট
                        পুনঃনির্ধারণ করতে পারবেন। ডাক্তার ব্যবহারকারীদের জন্য অ্যাপয়েন্টমেন্ট বাতিল করতে প্রতি রোগীর
                        রোগীর অ্যাপয়েন্টমেন্ট ক্যান্সলেশন ফি বাবদ ২ টাকা খরচ হবে। প্রদান অস্বীকার করার ক্ষেত্রে ডক্টরের
                        প্রোফাইলটি পেমেন্ট সেটেল্ড না হওয়া পর্যন্ত অটোমেটিকলি ব্লক হবে। রোগীরা সেই ডাক্তারের সাথে
                        অ্যাপয়েন্টমেন্ট করতে অক্ষম হবে যতক্ষণ না পেমেন্ট ক্লিয়ার হয়। পেমেন্ট হয়ে যাওয়ার পর ব্লক
                        আইডিটি স্বয়ংক্রিয়ভাবে আগের অবস্থায় ফেরত আসবে।
                    </li>
                    <li>
                        রোগীরা তাদের প্রোফাইল যে কোন ডাক্তারের সাথে mediserv.ai প্ল্যাটফর্মে শেয়ার করতে পারবেন
                        বিনামূল্যে।
                    </li>
                    <li>
                        আমরা অ্যাপয়েন্টমেন্ট নিশ্চিতকরণ করবো পেমেন্ট যাচাইকরণের মাধ্যমে পরবর্তী ২৪ ঘণ্টার মধ্যে।
                    </li>
                </ol>


                <h1>Refund Policy</h1>
                <ol>
                    <li>
                        ডাক্তারদের দ্বারা অ্যাপয়েন্টমেন্ট বাতিলের ক্ষেত্রে রোগীরা পুনরায় শিডিউলের জন্য অনুরোধ করতে
                        পারবেন।
                    </li>
                    <li>
                        অ্যাপয়েন্টমেন্টের বাতিলের দিন হতে সর্বোচ্চ ৩ সপ্তাহের মধ্যে সিডিউল পুনঃনির্ধারণ করা যাবে।
                    </li>
                    <li>
                        মেডিসার্ভে ডাক্তারের ফি পরিশোধ করার প্রয়োজন নেই। আমাদের প্ল্যাটফর্মে ডাক্তারের নির্ধারিত ভিজিট ফি
                        দেখা যাবে এপয়েন্টমেন্ট বুক করার সময়। ডাক্তারের ফি কেবলমাত্র ডাক্তারের চেম্বারে সরাসরি
                        ডাক্তারকে/ডাক্তারের নির্ধারিত সহকারীকে কিংবা সরাসরি ডাক্তারকে পরিশোধ করতে হবে। ডাক্তারের
                        নির্ধারিত
                        ফি 'তে mediserv.ai কমিশন প্রাপ্য নয়। ডাক্তারের সাথে রোগীর অর্থের আদান-প্রদান আমাদের প্লাটফর্মে
                        করা
                        যাবে না। এই আদান-প্রদানের জন্য mediserv.ai কোন দায় রাখে না।
                    </li>
                </ol>
            </div>
        </div>
    )
}
export default Index;