import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from "react-router-dom";
const Index = () => {
    const navigate = useNavigate();
    const { register, } = useForm();//handleSubmit, formState: { errors }, 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            navigate(`/doctor/change-password`);
        
        } catch (error) {
 
        }
    }
    return (

        <div className="profile-setting-cont ">
                <div className="col-sm-12 card p-4  mb-5 text-start">
                <h3 className='fw-bold'>Services and Specialization</h3>
                <h5 className='fw-bold mt-3'>Services</h5>
                   {/* <div className='otp-text'>OTP</div> */}
                    
                <form onSubmit={onSubmit}>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                {/* <p className='p-0 m-0'>Entre</p> */}
                                <div className="form-group form-control p-3 mb-2 mt-2">
                                    <Link className='cleaning-btn me-md-3'>Tooth cleaning</Link>
                                    <input
                                        type="text"
                                        name="otp"
                                        // style={{ textAlign: 'center' }}
                                        className="shadow-none border-0"
                                        placeholder="Enter Services"
                                        {...register("otp", {
                                            required: "otp is required",

                                        })}
                                    />
                                </div>
                            </div>                            
                 </form>
                <p className='fw-bold m-0 p-0'>Note: Type & Press enter to add new services</p>


                <h5 className='fw-bold mt-3'>Specialization</h5>
                   {/* <div className='otp-text'>OTP</div> */}
                    
                <form onSubmit={onSubmit}>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                {/* <p className='p-0 m-0'>Entre</p> */}
                                <div className="form-group form-control p-3 mb-2 mt-2">
                                    <Link className='cleaning-btn me-md-3'>Children Care</Link>
                                    <Link className='cleaning-btn ms-md-3 me-md-3'>Dental Care</Link>
                                    <input
                                        type="text"
                                        name="otp"
                                        // style={{ textAlign: 'center' }}
                                        className="shadow-none border-0"
                                        placeholder="Enter Services"
                                        {...register("otp", {
                                            required: "otp is required",

                                        })}
                                    />
                                </div>
                            </div>                            
                 </form>
                <p className='fw-bold m-0 p-0'>Note: Type & Press enter to add new services</p> 
                </div> 
            </div>
       
    );
}
export default Index;

