import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import Icon from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { x, } from 'react-icons-kit/feather'
import { ic_create } from 'react-icons-kit/md/'
import { useForm } from 'react-hook-form'

const Index = (props) => {

    // const [isLoading, setLoading] = useState(false) formState: { errors }, 

    const { register, handleSubmit,  } = useForm();//formState: { errors },
    // const [value, setValue] = useState()

    // const handleReceive = data => {
    //     props.handleReceive()
    //     // props.onHide()
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const header = {
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    // }


    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            props.handleRefetch()



            // const res = await Axios.post(`${api}user/prospect`, regData, header);

            // if (res.status === 200) {
            //     setId(res.data.response);
            //     toast('Your create account save');
            //     setStep(1);
            //     setLoading(false)
            //     props.handleRefetch()
            // }
            // else {
            //     setLoading(false);
            //     toast('This phone number or email already exists.');
            //     props.onHide();
            // }



        } catch (error) {
            // if (error) 
            // console.log(error.response.data.message)
            // setLoading(false);
            // toast(error.response.data.message);
            props.onHide();
        }
    }

    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-form-report"
            >
                <Modal.Header>
                    <Modal.Title>{props.value ? props.value.name : 'Report a medication that you are taking'}</Modal.Title>
                    <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button>
                </Modal.Header>
                <Modal.Body >

                    <div className='p-2'>
                        <p className='fw-bolder m-0 p-0 mt-2 ms-2'>This is not a refill request. Enter details about the medication below.</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='text-center'>
                            <div className='d-flex flex-row mt-4'>
                                <div className='col-sm-12 col-md-3 text-md-end'>
                                    <p className='m-0 p-0 me-1'>Name: </p>
                                </div>
                                <div className='col-sm-12 col-md-7 text-start'>
                                    <p className='m-0 p-0'>lisinopril 1 mg/mL solution <Icon style={{color:'#114A7E'}} icon={ic_create} /></p>
                                </div>
                            </div>
                            <div className='d-flex flex-row  mt-1 align-items-center'>
                                <div className='col-sm-12 col-md-3 text-md-end'>
                                    <p className='m-0 p-0 me-1'>Start date: </p>
                                </div>

                                <div className='col-sm-12 col-md-7 text-start'>
                                <div className="col-sm-12 col-md-3">
                                            <div className="form-group">

                                                <input
                                                    type="date"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>
                                    {/* <p className='m-0 p-0'>lisinopril 1 mg/mL solution <Icon icon={ic_create} /></p> */}
                                </div>
                            </div>
                            <div className='d-flex flex-row mt-4 mb-4'>
                                <div className='col-sm-12 col-md-3 text-md-end'>
                                    <p className='m-0 p-0 me-1'>Additional details: </p>
                                </div>
                                <div className='col-sm-12 col-md-7 text-start'>
                                <div className="form-floating">
                                            <textarea class="form-control" placeholder="" id="floatingTextarea"></textarea>
                                            {/* <label for="floatingTextarea">Comments</label> */}
                                        </div>
                                </div>
                            </div>
                        </div>
                        </form>
                        <div className='d-flex m-3 justify-content-end'>
                            <button className='btn-accept' onClick={props.onHide}> Accept</button>
                            <button className='btn-go-back' onClick={props.onHide}>Go back</button>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
        </div>

    );
}


export default Index;
