import React, { useState } from "react";
import './style.scss';
import ReactSwitch from 'react-switch';
// import { Images } from '../../utils/Images'
// import Icon from 'react-icons-kit'
// import {user} from 'react-icons-kit/ikons/user'
import PatientSidebar from '../../../components/sidebar-patient/index'
const Index = () => {
    const [checked, setChecked] = useState(true);
    const [checked2, setChecked2] = useState(true);

    const handleChange = val => {
        setChecked(val)
    }
    const handleChange2 = val => {
        setChecked2(val)
    }
    return (
        <div className="preference text-center">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-9 mt-md-5">
                    <div className="d-flex justify-content-center align-items-center mt-md-5">
                    <p className="m-0 p-0 fw-bold">Select language</p>
                    <div className='otp-text'>English</div>
                    </div>
                    
                    <div className="d-flex mt-4 mb-4 justify-content-around align-items-center flex-lg-row flex-md-row flex-column ">

                        <div className="col-sm-12 col-md-5 col-lg-5">
                            <div className="d-flex align-items-center justify-content-between">
                                <label className="me-1">Make All Posts Public</label>
                                <ReactSwitch
                                    checked={checked}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                                <label className="me-1">Make All Posts Doctors Only</label>
                                <ReactSwitch
                                    checked={checked2}
                                    onChange={handleChange2}
                                />
                            </div>
                        </div>
                    </div>
                    <p style={{fontSize:'12px'}}>You can chage this Settings for Individual Posts Separately</p>
                </div>
                
                <div className='col-md-3 col-lg-3 d-none d-lg-block '>
                    <div className='card p-2 position-fixed top-2 end-0 overflow-auto' style={{ width: '310px', maxHeight: "85vh" }}>
                        <PatientSidebar />
                    </div>
                </div>
            </div>


        </div>
    );
}
export default Index;