import React from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import {world} from 'react-icons-kit/typicons/'
// import {user} from 'react-icons-kit/ikons/user'
import {ic_more_horiz_outline, ic_autorenew, ic_favorite_border} from 'react-icons-kit/md/'
// import {speech_bubble_1} from 'react-icons-kit/ikons/'
// import { Images } from '../../utils/Images'
// import SearchComponent from '../../components/search/Index'
const Index = () => {
    
    return (
        <div className="patient-profile-card">
                <div className="card-patient mt-3 mb-3 mr-3">
                <div style={{height:'250px', background:'#D9D9D9'}}>
                       
                       </div>
                        <div className="d-flex justify-content-between">
                        <div className="col-5 ">
                        <div className="d-flex align-items-center">
                            
                            <div className="d-flex flex-column align-items-start m-3">
                                <h3>Mediserv - Making your clinic painless visit?</h3>
                                <p><Icon icon={world}/>Dr. Sumaiya 4 Dec 2023 12Comments Health Tips</p>
                            </div>
                           
                        </div>
                        </div>
                        <Icon style={{color:'#E1E1E1'}} icon={ic_more_horiz_outline} size={50}/>
                        </div>
                        
                       <div className="">
                        <p className="text-start p-4">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                       </div>

                       
                       <button>Read More</button>
                    </div>
        </div>
    );
};

export default Index;



