import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
// import { useForm } from 'react-hook-form'
// import { Link } from "react-router-dom";
// import Icon from 'react-icons-kit'
// import {ic_add_circle_outline} from 'react-icons-kit/md/'

import BasicInformation from '../../../components/setting/ProfileSettings/BasicInformation'
import ClinicInformation from '../../../components/setting/ProfileSettings/ClinicInformation'
import ContactDetails from '../../../components/setting/ProfileSettings/ContactDetails'

const Index = () => {
    // const navigate = useNavigate();
    // const { register, } = useForm();//handleSubmit, formState: { errors }, 
    // const onSubmit = async (data) => {
    //     try {

    //         // setLoading(true)
    //         const regData = {
    //             ...data
    //         }
    //         console.log(regData);
    //         navigate(`/doctor/change-password`);
        
    //     } catch (error) {
 
    //     }
    // }
    return (

        <div className="profile-setting p-2 pt-0">
            <BasicInformation />
            <ClinicInformation />
            <ContactDetails />
            
            </div>
       
    );
}
export default Index;

