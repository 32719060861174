import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from "react-router-dom";
import Icon from 'react-icons-kit'
import {ic_add_circle_outline} from 'react-icons-kit/md/'

const Index = () => {
    const navigate = useNavigate();
    const { register, } = useForm();//handleSubmit, formState: { errors }, 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            navigate(`/doctor/change-password`);
        
        } catch (error) {
 
        }
    }
    return (

        <div className="profile-setting-cont ">
   
                <div className="col-sm-12 card p-4 mt-2 mb-5 text-start">
                <h3 className='fw-bold'>Education</h3>
                   
                <form onSubmit={onSubmit}>
                    <div className='row'>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            
                            <div className="form-group  p-3 mb-2 mt-2">
                            <p className='p-0 m-0 fw-bold'>Degree</p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div>   

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            
                            <div className="form-group  p-3 mb-2 mt-2">
                            <p className='p-0 m-0 fw-bold'>College/Institute</p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div> 

                        <div className="col-sm-12 col-md-6 col-lg-4">
                            
                            <div className="form-group  p-3 mb-2 mt-2">
                            <p className='p-0 m-0 fw-bold'>Year of completion</p>
                                <input
                                    type="text"
                                    name="otp"
                                    // style={{ textAlign: 'center' }}
                                    className="form-control shadow-none "
                                    placeholder=""
                                    {...register("otp", {
                                        required: "Degree is required",

                                    })}
                                />
                            </div>
                        </div>                          
                    </div>
                    <Link><Icon icon={ic_add_circle_outline}/>Add More</Link>
                </form>
                

                </div>
           
                
            </div>
       
    );
}
export default Index;

