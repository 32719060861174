import React, { } from "react";
import Icon from 'react-icons-kit'
import { ic_location_on } from 'react-icons-kit/md/'
import { Images } from "../../../../utils/Images";
const Index = ({ doctor }) => {
    return (
        <div className="appointment-profile">
            <div className="">
                <div className="card p-4 text-center">
                    <div>
                        <img src={Images.Doctor2} style={{ width: '120px' }} alt="" />
                    </div>
                    <h4 className="m-0">Aranya khan</h4>
                    <p className="m-0">Patient ID: Pt0016</p>
                    <p className="m-0"><Icon icon={ic_location_on} />Dhaka, Bangladesh</p>
                    <div className="d-flex justify-content-between">
                        <h6>Age</h6>
                        <h6>28 Years, Male</h6>
                    </div>
                    <div className="d-flex justify-content-between">
                        <h6>Blood Group</h6>
                        <h6>AB+</h6>
                    </div>
                    <div className="d-flex align-items-center">
                        <h6>Allotted time: </h6>
                        <p className="m-0 p-0">4.28pm</p>
                    </div>
                    {doctor ?
                        <div className="d-flex align-items-center">
                            <button className='view-record' style={{ background: '#FFC7EA' }}>Skip</button>
                            <button className='view-record' style={{ background: '#49A0AE' }}>Give Care</button>
                        </div>
                        : null
                    }

                </div>
            </div>
        </div>
    )
}

export default Index;