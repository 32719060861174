/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import './style.scss';
import { Images } from '../../utils/Images'
import Accordion from 'react-bootstrap/Accordion';
import Icon from 'react-icons-kit'
import {ic_list} from 'react-icons-kit/md/'

// import {user} from 'react-icons-kit/ikons/user'
// import {ic_more_horiz_outline, ic_autorenew, ic_favorite_border} from 'react-icons-kit/md/'
// import {speech_bubble_1} from 'react-icons-kit/ikons/'
// import {ic_toggle_on} from 'react-icons-kit/md/'

// import Ads from '../../components/ads/index'
import QuickISearch from '../../components/QuickISearch/Index'
import HelpCenter from '../../components/helpCenter/Index'

const Index = () => {

    return (
        <div className="helpCenter">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex  align-items-center">
                        <img className="logo-img" src={Images.Logo2} alt="" />
                        <h4 className="m-2">Help Center</h4>
                    </div>

                    < QuickISearch />
                </div>
            </div>


            <div className="community">
                {/* <div className="row"> */}
                    <div className="col-sm-12 col-md-2 col-lg-2 me-lg-2">
                        <div className="card mt-3 mb-3 mr-3">
                            <nav className="nav flex-column">
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Using Mediserv</Accordion.Header>
                                        <Accordion.Body className="text-start">
                                            <a className="nav-link active" href="#">Active</a>
                                            <a className="nav-link" href="#">Link</a>
                                            <a className="nav-link" href="#">Link</a>
                                            <a className="nav-link disabled" href="#">Disabled</a>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Managing your account</Accordion.Header>
                                        <Accordion.Body className='p-4 '>


                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>FPrivacy, safety and security</Accordion.Header>
                                        <Accordion.Body className='p-4 '>


                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Policies and reporting</Accordion.Header>
                                        <Accordion.Body className='p-4 '>


                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>


                            </nav>

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-8">

                        <div className="card mt-3 ms-0 me-0 p-4 pr-0 text-start">

                            <h2 className="fw-bold"> Create an Account</h2>
                            <hr style={{height:'2px'}}/>
                            <h4 className="fw-bold mt-4"> Create an Account</h4>
                            <HelpCenter/>


                            <div className="m-4 p-4 d-flex justify-content-between align-items-end" style={{ background: '#FBF4FC' }}>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <div className="card p-4">

                                        <div className="d-flex justify-content-between">
                                            <p>Was this helpful?</p>
                                            <p>x</p>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <button className="helpCenter-btn">Yes</button>
                                            <button className="helpCenter-btn">No</button>
                                        </div>
                                    </div>


                                </div>
                                <div className="d-flex">
                                <button className="view-full-article-btn">View full article</button>
                                <button className="view-full-article-btn"><Icon icon={ ic_list}/></button>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </div>
    );
}
export default Index;