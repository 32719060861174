/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import './style.scss'
import 'react-datepicker/dist/react-datepicker.css';
import 'react-month-picker-input/dist/react-month-picker-input.css';
import DoctorRegister from '../../../components/register-dr/Index'
import CouldNotBeVerified from '../../couldnot-be-verified/Index'
import CouldBeVerified from '../../verification/Index'
const Index = () => {
    const [show, setShow] = useState(true)
    const [showNotVerified, setShowNotVerified] = useState(false)
    const [showVerified, setShowVerified] = useState(false)

    const handleNotVerified =()=>{
        setShow(false)
        setShowNotVerified(true)
    }

    const handleVerified =()=>{
        setShowNotVerified(false)
        setShowVerified(true)
    }
   
    return (
        <div className="register-doctor">
            <div className="">
                {
                    show ?
                        <DoctorRegister
                            handleNotVerified={handleNotVerified}
                        /> :null
                        
                }
                {
                    showNotVerified ?
                        <CouldNotBeVerified
                            handleVerified={handleVerified}
                        />
                        : null
                }
                {
                    showVerified ?
                        <CouldBeVerified />
                        : null
                }
            </div>
        </div>
    )

    
}
export default Index;