/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import './style.scss';
import Icon from 'react-icons-kit'
import { ic_report_gmailerrorred_outline } from 'react-icons-kit/md/'
import PatientSidebar from '../../../components/sidebar-patient/index'
import UpcomingScreening from '../../../components/patient/upcomingScreening/index'

const Index = () => {

    return (
        <div className="patient-community my-record">
            {/* <div className="row"> */}

            <div className="col-sm-12 col-md-12 col-lg-9">
            <div className="d-flex justify-content-between Share-Health-Data-p col-sm-12 col-md-7 col-lg-12">
                    <p className="p-0 m-0"><Icon icon={ic_report_gmailerrorred_outline} /> Please read the Online Refund Instructions & Ticket Refund Policy before canceling your ticket.</p>
                    <a href="#">View</a>
                </div>
                <div className="d-flex justify-content-center mt-4 mb-4 flex-wrap flex-sm-nowrap">
                    <button className="record-btn">28D</button>
                    <button className="record-btn">90D</button>
                    <button className="record-btn">365D</button>
                    <button className="record-btn">Dec</button>
                    <button className="record-btn">Nov</button>
                    <button className="record-btn">Oct</button>
                    <button className="record-btn">2023</button>
                    <button className="record-btn">2022</button>
                    <button className="record-btn">Lifetime</button>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="col-sm-12 col-md-7 col-lg-7">
                        <UpcomingScreening />

                    </div>
                </div>
            </div>
            <div className='col-md-3 col-lg-3 d-none d-lg-block '>
                    <div className='card p-2 position-fixed top-2 end-0 overflow-auto' style={{ width: '310px', maxHeight: "85vh" }}>
                        
                        <PatientSidebar />
                    </div>
                </div>
            {/* </div> */}


        </div>
    );
}
export default Index;