import React from "react";
import './style.scss';
import { Link } from 'react-router-dom'
import { Images } from '../../../../utils/Images'
import Icon from 'react-icons-kit'
import { ic_check_circle_outline, } from 'react-icons-kit/md/'
const Index = ({ profile }) => {
    return (
        <div className="doctor-myProfile">

            {/* my profile */}
            <div className="d-flex justify-content-between">
                <div className="col-2">
                    <img src={Images.Doctor2} alt="" />
                </div>
                <div className="col-9">
                    <div className="d-flex justify-content-between">
                        <h2>Dr. Ashik Khandakar</h2>
                        <p className="m-0 p-0 fw-bold"><Icon icon={ic_check_circle_outline} style={{ color: 'green' }} /> BMDC Verified</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p><span className="m-0 p-0 fw-bold">2K</span> Patients</p>
                        <p><span className="m-0 p-0 fw-bold">2K</span> Followers</p>
                        <p><span className="m-0 p-0 fw-bold">2K</span> Answers</p>
                    </div>
                    {profile === 'self' ?
                        <div className="d-flex">

                            <button className="follow-btn fw-bold">Edit profile</button>
                            <Link to="/patient/doctor-profile"  className="follow-btn fw-bold" rel="noopener noreferrer">
                            Edit Schedules
                            </Link>
                            <Link to="/practice-prescription" target="_blank" className="fw-bold Book-Appointments-btn" rel="noopener noreferrer">
                                Go to Practice
                            </Link>
                        </div> :
                        <div className="d-flex">

                            <button className="follow-btn fw-bold">Follow</button>
                            <button className="fw-bold Book-Appointments-btn">Book His Appointments</button>
                        </div>
                    }

                </div>

            </div>

            {/* Bio */}
            <div>
                <h3>Bio</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
        </div>
    );
}
export default Index;