import React from 'react'
import './style.scss'
import { Images } from '../../../utils/Images'
import Icon from 'react-icons-kit'
import {ic_visibility_outline} from 'react-icons-kit/md/'
const Index = () => {

    return (
        <div className="records-table">
            <table className="table table-bordered">
                <thead>
                    <tr className='text-center'>
                        <th scope="col">Date</th>
                        <th scope="col">Diagnosis</th>
                        <th scope="col">Doctor</th>
                        <th scope="col">Medications</th>
                        <th scope="col">Tests</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='text-center'>16 Nov 2023</td>
                        <td className='text-center'>Fever</td>
                        <td>
                            <div className='d-flex'>
                                <img className='records-img' src={Images.Doctor2} alt='' />
                                <div>
                                    <p className='m-0'>Dr. Rumana Khan</p>
                                    <p className='forward-sub-p m-0'>Dental</p>
                                </div>
                            </div>
                        </td>
                        <td className='text-center'><button className='records-view'><Icon icon={ic_visibility_outline}/> View</button></td>
                        <td className='text-center'><button className='records-view'><Icon icon={ic_visibility_outline}/> View</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Index;