import React, { useState, useEffect } from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { NavLink,  } from 'react-router-dom'
import {ic_arrow_drop_down_outline, ic_arrow_drop_up_twotone} from 'react-icons-kit/md/'
// import { standby } from 'react-icons-kit/iconic' useNavigate
import { Images } from '../../utils/Images'

const Layout = (isOpen) => {
    // const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const [more, setMore] = useState(false)

    useEffect(() => {
        setShow(isOpen)
    }, [isOpen])
    const handleMore = data =>{
        setMore(data)
    }
    // const handleNewProspect = data => {
    //     setAddNew({ status: true })
    // }
  
    // Toggle menu
    // const toggleMenu = event => {
    //     let current = event.target.getAttribute("data-value")

    //     if (isMenu === current) {
    //         setMenu(false)
    //     } else {
    //         setMenu(current)
    //     }
    // }

    // Logout
    // const doLogout = async () => {
    //     try {
    //         setLoggingOut(true)
    //             localStorage.clear()
    //             setTimeout(() => {
    //                 navigate('/');
    //             }, 2000)
    //         // }
    //     } catch (error) {
    //         if (error) {
    //             localStorage.clear()
    //             setTimeout(() => {
    //                 navigate('/');
    //             }, 2000)
    //         }
    //     }
    // }

    return (
        <div className="layout">
            
            {/* Sidebar */}
            <div className={show ? "backdrop d-lg-none open-backdrop" : "backdrop d-lg-none"} onClick={() => setShow(false)}></div>

            <div className="sidebar-container">
                <div className={show ? "sidebar  shadow open-sidebar" : "shadow sidebar "}>
                    {/* className={isOpen ? "sidebar shadow open-sidebar" : "sidebar shadow"} */}
                    {/* {show ?
                  <div style = {{ marginTop:"70px"}}></div> : null} */}
                    <ul>
                        {/* Dashboard  */}
                        <li>
                            <NavLink
                                exact="true"
                                to="my-profile"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.Doctor2} />My Profile</NavLink>
                            {/* <Icon icon={statsBars} size={20} /> */}
                        </li>
                        {/* Setting Links */}
                        
                        <li>
                            <NavLink
                                exact="true"
                                to="/doctor/schedule"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.Schedules} />Schedules</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="todays-appointments"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.ForwardPrescriptions} />Today's Appointment</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="/doctor/refer-patient"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.MyRecords} />Refer Patient</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="/doctor/cancel-appointment"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.CancelAppointments} />Cancel Appointment</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                // to="/document-center"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.DocumentCentre} />Timeline</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="appointment"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.MyAppointments} />About</NavLink>                            
                        </li>
                        {more ? <>
                            <li>
                            <NavLink
                                exact="true"
                                to="your-medication"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.Photos} />Photos</NavLink>                            
                        </li>
                        <li>
                            <NavLink
                                exact="true"
                                to="patient-community"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.Videos} />Videos</NavLink>                            
                        </li>
                        <li>
                            <NavLink
                                exact="true"
                                // to="/rescheduled-appointment"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.ShareHealthData} />Connections</NavLink>                            
                        </li>
                        <li>
                        </li>
                        </> : null}
                        

                      

                        {/* Logout */}
                        {/* <li>
                            <button
                                type="button"
                                className="btn shadow-none"
                                onClick={doLogout}
                                disabled={loggingOut}
                            >
                                <Icon icon={standby} size={15} />
                                {loggingOut ? <span>Logging out...</span> : <span>Logout</span>}
                            </button>
                        </li> */}

                    </ul>
                    {more?
                    <p className='fw-bold' type= 'button' onClick={() => handleMore(false)}><Icon icon={ic_arrow_drop_down_outline} size={40}/>See Less</p>:
                    <p className='fw-bold' type= 'button' onClick={() => handleMore(true)}><Icon icon={ic_arrow_drop_up_twotone} size={40}/>See More</p>
                    }
                </div>

                <div className={show ? "shortcuts  shadow open-sidebar mt-3" : "shadow shortcuts mt-3"}>
                    <h6>Your Shortcuts</h6>
                <ul>
                        {/* Dashboard  */}
                        <li>
                            <NavLink
                                exact="true"
                                to="/h"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            >UserName</NavLink>
                            {/* <Icon icon={statsBars} size={20} /> */}
                        </li>
                        {/* Setting Links */}
                        
                        <li>
                            <NavLink
                                exact="true"
                                to="find-care"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            >Find Care</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="/dashboard/settings-field-value"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            >Forward Prescription</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="/dashboard/settings-field-value"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            >My Record</NavLink>
                        
                            
                        </li>
                    </ul>
                </div>
            </div>


            

        </div>
    );
}

export default Layout;