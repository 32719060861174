/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import './style.scss'
import { Images } from "../../utils/Images";
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Icon from 'react-icons-kit'
// import {ic_facebook} from 'react-icons-kit/md/'
import 'react-datepicker/dist/react-datepicker.css';
import 'react-month-picker-input/dist/react-month-picker-input.css';
import Select from 'react-dropdown-select';
import { ic_help, ic_star_rate } from 'react-icons-kit/md/';
import { district } from './data'
const Index = ({handleNotVerified}) => {
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [isClearable,] = useState(true); //setIsClearable
    const [isSearchable,] = useState(true); //setIsSearchable
    const [isDisabled,] = useState(false); //setIsDisabled
    const [isLoading,] = useState(false); //setIsLoading
    const [isRtl,] = useState(false); //setIsRtl
    const [value, setValue] = useState()
    const [loading, setLoading] = useState(false);
    const [workplace, setWorkplace] = useState(false);
    const currentYear = new Date().getFullYear();

    const [day, setDay] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);

    const days = Array.from({ length: 31 }, (_, i) => ({ label: `${i + 1}`, value: `${i + 1}` }));
    const months = [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
    ];
    const years = Array.from({ length: 100 }, (_, i) => ({
        label: `${currentYear - i}`,
        value: `${currentYear - i}`,
    }));

    useEffect(() => {
        // Set default values when the component mounts

        setDay(days[0]);
        setMonth(months[0]);
        setYear(years[0]);
        setLoading(true)
    }, [days, months, years]); // The empty dependency array ensures the effect runs only once when the component mounts

    const handleDayChange = (selected) => {
        setDay(selected[0]);
    };



    const handleMonthChange = (selected) => {
        setMonth(selected[0]);
    };

    const handleYearChange = (selected) => {
        setYear(selected[0]);
    };

    const handleWorkplace = () => {
        setWorkplace(!workplace)
    }

    const handleVerifiedData =()=>{
        handleNotVerified()
    }

    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);

        } catch (error) {

        }
    }

    const iconStyle = {
        // marginTop: '-10px',
        color: 'red'
    };
    if (!loading) return (<div></div>)
    return (
        <div className="register-doctor">
            <div className="card-shadow justify-content-center">
                <img style={{ width: '150px' }} src={Images.Doctor2} alt="" />
            </div>
            {/* <br/> */}
            <div className="card-shadow">
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="row">



                        {/* Name */}
                        <div className="col-6">
                            <div className="form-group d-flex mb-2">
                                <h3 style={{ marginRight: '10px' }}>Dr </h3>
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control shadow-none"
                                    placeholder="First name"
                                    {...register("email", {
                                        required: "First name is required",

                                    })}
                                />
                            </div>
                        </div>

                        {/* Surname */}
                        <div className="col-6">
                            <div className="form-group mb-2">

                                <input
                                    type="text"
                                    name="email"
                                    className="form-control shadow-none"
                                    placeholder="Surname"
                                    {...register("email", {
                                        required: "Surname is required",

                                    })}
                                />
                            </div>
                        </div>

                        {/* Email */}
                        <div className="col-12">
                            <div className="form-group mb-2">

                                <input
                                    type="text"
                                    name="email"
                                    className="form-control shadow-none"
                                    placeholder="Email"
                                    {...register("email", {
                                        required: "Email is required",

                                    })}
                                />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-group">

                                <PhoneInput
                                    name="phone"
                                    className="form-control shadow-none  d-flex border-none"
                                    value={value}
                                    onChange={setValue}
                                    placeholder="Enter phone number"
                                // {...register("phone", {
                                //     required: "First name is required",

                                // })}
                                />
                            </div>
                        </div>

                        {/* Email */}
                        <div className="col-12 mt-2">
                            <div className="form-group mb-2">
                                {errors.phone && errors.phone.message ? (
                                    <p className="text-danger">{errors.phone && errors.phone?.message}</p>
                                ) : <p>Birth Date <Icon icon={ic_help} /></p>
                                }
                                <div className="d-flex justify-content-between">

                                    <div className="col-3"> <Select options={days} onChange={handleDayChange} values={[day]} /></div>
                                    <div className="col-3"><Select options={months} onChange={handleMonthChange} values={[month]} /></div>
                                    <div className="col-3"><Select options={years} onChange={handleYearChange} values={[year]} /></div>


                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6">
                            <div className="form-group mb-4">
                                {errors.familyWith && errors.familyWith.message ? (
                                    <p className="text-danger">{errors.familyWith && errors.familyWith?.message}</p>
                                ) : <p>Gender <Icon icon={ic_help} /></p>
                                }
                                <div className='d-flex mt-2'>
                                    <label className='form-control radio-gender d-flex justify-content-evenly align-items-center' style={{ marginRight: '20px' }}>
                                        Female

                                        <input
                                            type="radio"
                                            value="Female"
                                        // checked={selectedOption === 'Yes'}
                                        // onChange={handleOptionChange}
                                        />

                                    </label>

                                    <label className='form-control radio-gender d-flex justify-content-evenly align-items-center'>
                                        Male
                                        <input
                                            type="radio"
                                            value="Male"
                                        // checked={selectedOption === 'No'}
                                        // onChange={handleOptionChange}
                                        />

                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="form-group mb-4">
                            {errors.familyWith && errors.familyWith.message ? (
                                <p className="text-danger">{errors.familyWith && errors.familyWith?.message}</p>
                            ) : <div className="d-flex">
                                    <p style={{ marginTop: '10px' }}>Government ID </p>
                                    <span ><Icon size={10} style={iconStyle} icon={ic_star_rate} /></span>
                                </div>
                            }
                            <input
                                type="file"
                                className="form-control shadow-none"
                                hidden
                                id="upload"
                                title="Upload"
                                name="photo"
                            // onChange={handleImage} 
                            />
                            <label htmlFor="upload" className="col-12 btn-upload text-center" tabIndex="0">{/*disabled={isPhotoLoading}*/}
                                {/* < Icon className=" Icon" icon={download} size={19} /> */}
                                {/* {isPhotoLoading ? 'Upload...' : 'Upload'} */}Upload /Take Photo
                            </label>
                        </div>
                    </div>

                    <div className="col-sm-12 col-lg-3">
                        <div className="form-group mb-4">
                            {errors.familyWith && errors.familyWith.message ? (
                                <p className="text-danger">{errors.familyWith && errors.familyWith?.message}</p>
                            ) : <div className="d-flex">
                                <p style={{ marginTop: '10px' }}>BDMC Certificate </p>
                                <span ><Icon size={10} style={iconStyle} icon={ic_star_rate} /></span>
                            </div>
                            }
                            <input
                                type="file"
                                className="form-control shadow-none"
                                hidden
                                id="upload"
                                title="Upload"
                                name="photo"
                            // onChange={handleImage} 
                            />
                            <label htmlFor="upload" className="col-12 btn-upload text-center" tabIndex="0">{/*disabled={isPhotoLoading}*/}
                                {/* < Icon className=" Icon" icon={download} size={19} /> */}
                                {/* {isPhotoLoading ? 'Upload...' : 'Upload'} */}Upload/Take Photo
                            </label>
                        </div>
                    </div>

                    <div className="col-sm-12 col-lg-3">
                        <div className="form-group mb-4">
                            {errors.familyWith && errors.familyWith.message ? (
                                <p className="text-danger">{errors.familyWith && errors.familyWith?.message}</p>
                            ) :<div className="d-flex">
                            <p style={{ marginTop: '10px' }}>BDMC Number </p>
                            <span ><Icon size={10} style={iconStyle} icon={ic_star_rate} /></span>
                        </div>
                            }
                            <input
                                type="file"
                                className="form-control shadow-none"
                                hidden
                                id="upload"
                                title="Upload"
                                name="photo"
                            // onChange={handleImage} 
                            />
                            <label htmlFor="upload" className="col-12 btn-upload text-center" tabIndex="0">{/*disabled={isPhotoLoading}*/}
                                {/* < Icon className=" Icon" icon={download} size={19} /> */}
                                {/* {isPhotoLoading ? 'Upload...' : 'Upload'} */}Upload/Take Photo
                            </label>
                        </div>
                    </div>


                    {/* </div> */}

                    <div className='row'>
                        <div className="modal-footer text-center" >

                            <div className="col-12 text-start mb-0">
                                <label className='d-flex align-items-start'>

                                    <input
                                        className="check-type"
                                        style={{ marginRight: '20px', marginTop: '5px' }}
                                        type="checkbox"
                                        value=""
                                        // checked={selectedOption === 'Yes'}
                                        onChange={handleWorkplace}
                                    />
                                    <div>
                                        <h2 className="p-0 m-0" style={{ fontWeight: "bold" }}>Add Current Workplace</h2>
                                        <p className="p-0"  >Adding more information will help you rank higher on mediserv</p>

                                        {workplace ?
                                            <div className="col-sm-12 col-md-7 col-lg-7 card p-3 d-flex flex-lg-row flex-column justify-content-between flex-wrap ">
                                                <div className="col-sm-12 col-md-6 col-lg-5">
                                                    <div className="form-group mb-2">

                                                        <input
                                                            type="text"
                                                            name="position"
                                                            className="form-control shadow-none"
                                                            placeholder="Position"
                                                            {...register("position", {
                                                                // required: "Surname is required",

                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                    <div className="form-group mb-2">
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="DISTRICT"
                                                            placeholder="Select Country"
                                                            // defaultValue={district[0]}
                                                            isDisabled={isDisabled}
                                                            isLoading={isLoading}
                                                            isClearable={isClearable}
                                                            isRtl={isRtl}
                                                            isSearchable={isSearchable}
                                                            name="color"
                                                            options={district}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-5">
                                                    <div className="form-group mb-2">

                                                        <input
                                                            type="text"
                                                            name="department"
                                                            className="form-control shadow-none"
                                                            placeholder="Department"
                                                            {...register("department", {
                                                                // required: "Surname is required",

                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                    <div className="form-group mb-2">
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="SPECIALIST"
                                                            placeholder="District"
                                                            isDisabled={isDisabled}
                                                            isLoading={isLoading}
                                                            isClearable={isClearable}
                                                            isRtl={isRtl}
                                                            isSearchable={isSearchable}
                                                            name="color"
                                                            options={district}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="col-sm-12 col-md-6 col-lg-5">
                                                    <div className="form-group mb-2 mt-lg-2">

                                                        <input
                                                            type="text"
                                                            name="hospitalName"
                                                            className="form-control shadow-none"
                                                            placeholder="Hospital Name"
                                                            {...register("hospitalName", {
                                                                // required: "Surname is required",

                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                    <div className="form-group mb-2">
                                                        <p className="fw-bolder m-0" style={{ fontSize: '12px' }}><Icon icon={ic_help} />[this number is not visible to public]</p>
                                                        <input
                                                            type="text"
                                                            name="position"
                                                            className="form-control shadow-none"
                                                            placeholder="Your WhatsApp Number"
                                                            {...register("whatsAppNumber", {
                                                                // required: "Surname is required",

                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <div className="form-group mb-2">

                                                        <input
                                                            type="text"
                                                            name="position"
                                                            className="form-control shadow-none"
                                                            placeholder="Address line 1 (100 characters)"
                                                            {...register("position", {
                                                                // required: "Surname is required",

                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-around align-items-center">
                                                    <p className="m-0">Form</p>
                                                    <div className="col-3 form-group mb-2">

                                                        <input
                                                            type="text"
                                                            name="position"
                                                            className="form-control shadow-none"
                                                            placeholder="Date"
                                                            {...register("position", {
                                                                // required: "Surname is required",

                                                            })}
                                                        />
                                                    </div>

                                                    <p className="m-0">To</p>
                                                    <div className="col-3 form-group mb-2">

                                                        <input
                                                            type="text"
                                                            name="position"
                                                            className="form-control shadow-none"
                                                            placeholder="Date"
                                                            {...register("position", {
                                                                // required: "Surname is required",

                                                            })}
                                                        />

                                                    </div>
                                                    <label className='d-flex align-items-start'>

                                                        <input
                                                            className="check-type"
                                                            style={{ marginRight: '20px' }}
                                                            type="checkbox"
                                                            value=""
                                                        // checked={selectedOption === 'Yes'}
                                                        // onChange={handleWorkplace}
                                                        />
                                                        Still Working Here
                                                    </label>
                                                </div>
                                            </div> : ''
                                        }

                                    </div>


                                </label>
                                <p className="p-0"  >By clacking on Verification you agree to our Policy and Terms</p>

                                <div className="col-12 text-start">
                                    <button className='btn-verification' 
                                    type="submit" style={{ background: "#A356A8" }}
                                     onClick={handleVerifiedData}>Apply for Verification</button>

                                </div>
                            </div>


                        </div>
                    </div>

                </form>
            </div>
        </div>
    )

    
}
export default Index;