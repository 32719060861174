import React from "react";
import './style.scss';
// import Icon from 'react-icons-kit'
// import {ic_check_circle_outline} from 'react-icons-kit/md/'
import DocumentCenter from '../../../components/table/forwardPrescription/ForwardPrescription'
const Index = () => {

    return (
        <div className="document-center text-start">
            <div className="p-4">
                <h2>Your Uploads</h2>
                <DocumentCenter document='document' />
            </div>

            <div className="mt-5 p-4 text-center ">
                    <h2>Upload Documents</h2>
                    <p>Prescription and Test Reports</p>
                    <div className="col-sm-12 col-md-7 col-lg-12">
                        <div className="card p-4 ">
                        <button className="mt-2 document-btn" style={{ marginBottom:'20px'}}>Select Documents To Upload</button>
                        <p>or drag & drop</p>
                        </div>
                    </div>

            </div>

        </div>
    );
}
export default Index;