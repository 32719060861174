import React from 'react'
import './style.scss';
// import { Images } from '../../../utils/Images'
const Index = () => {

    return (
        <div className="allergies-reactions-table text-start">
            <table className="table">
                {/*  table-bordered */}
                <thead>
                    <tr className=''>
                    <th scope="row"></th>
                    <th scope="row">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Vaccine Name</td>
                        <td>8/30/2021</td>
                    </tr>
                    <tr>
                        <td>Vaccine Name</td>
                        <td>8/30/2021</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Index;