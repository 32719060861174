import React from "react";
import './style.scss';
// import { Images } from '../../../utils/Images'
import Icon from 'react-icons-kit'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import { user } from 'react-icons-kit/ikons/'
import { ic_location_on, ic_school_twotone, ic_public_outline, ic_more_horiz_outline, ic_star_rate_outline,ic_create_outline, ic_delete} from 'react-icons-kit/md/'
// import { book, calendar } from 'react-icons-kit/typicons/'
import { briefcase } from 'react-icons-kit/oct/'
// import {dropbox} from 'react-icons-kit/ikons/'
// import Doctor from '../../components/doctor/index'
const Index = () => {
    return (
        <div className="doctor-about">

            {/* Co-Founder */}
            <div className="d-flex justify-content-between">
                <div className="d-flex text-align-center">
                    <Icon icon={briefcase}/>
                    <div  className="text-start" >
                        <p className="fw-bold p-0 m-0">Co-Founder & CTO at Flyfol . Free-Commerce Marketplace</p>
                        <p className="p-0 m-0">Past Esquire Group and Esquire Group</p>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                <Icon icon={ic_public_outline}/>
                
                <DropdownButton 
                                 title={<Icon style={{color:'#000'}} icon={ic_more_horiz_outline}/>}
                                 style={{ backgroundColor: '#fff' }} // Replace '#yourColor' with the desired background color
                                
                               >
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/patient/register-doctor" ><Icon icon={ic_star_rate_outline}/> See life event</Dropdown.Item>       
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/doctor/practice" ><Icon icon={ic_create_outline}/> Edit WorkPlace</Dropdown.Item>       
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/patient/register-doctor"><Icon icon={ic_delete}/> Delete Workplace</Dropdown.Item>       
                                   
                               </DropdownButton>

                
                </div>
            </div>

        {/* Studies */}
            <div className="d-flex mt-4 justify-content-between">
                <div className="d-flex text-align-start">
                    <Icon icon={ic_school_twotone}/>
                    <div  className="text-start">
                        <p className="fw-bold p-0 m-0">Studies MBA at Army Institute of Business Administration - AIBA, Sylhet</p>
                        <p className="p-0 m-0">Started in 2023</p>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                <Icon icon={ic_public_outline}/>
                
                <DropdownButton 
                                 title={<Icon style={{color:'#000'}} icon={ic_more_horiz_outline}/>}
                                 style={{ backgroundColor: '#fff' }} // Replace '#yourColor' with the desired background color
                                
                               >
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/patient/register-doctor" ><Icon icon={ic_star_rate_outline}/> See life event</Dropdown.Item>       
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/doctor/practice" ><Icon icon={ic_create_outline}/> Edit WorkPlace</Dropdown.Item>       
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/patient/register-doctor"><Icon icon={ic_delete}/> Delete Workplace</Dropdown.Item>       
                                   
                               </DropdownButton>

                
                </div>
            </div>

            {/* Live */}
            <div className="d-flex justify-content-between mt-4">
                <div className="d-flex text-align-center">
                    <Icon icon={briefcase}/>
                    <div className="text-start">
                        <p className="p-0 m-0">Past Esquire Group and Esquire Group</p>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                <Icon icon={ic_public_outline}/>
                
                <DropdownButton 
                                 title={<Icon style={{color:'#000'}} icon={ic_more_horiz_outline}/>}
                                 style={{ backgroundColor: '#fff' }} // Replace '#yourColor' with the desired background color
                                
                               >
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/patient/register-doctor" ><Icon icon={ic_star_rate_outline}/> See life event</Dropdown.Item>       
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/doctor/practice" ><Icon icon={ic_create_outline}/> Edit WorkPlace</Dropdown.Item>       
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/patient/register-doctor"><Icon icon={ic_delete}/> Delete Workplace</Dropdown.Item>       
                                   
                               </DropdownButton>

                
                </div>
                
            </div>

            {/* Location */}
            <div className="d-flex justify-content-between mt-4">
                <div className="d-flex text-align-center">
                    <Icon icon={ic_location_on}/>
                    <div  className="text-start">
                        <p className="fw-bold p-0 m-0">From Brahmanbaria, Chittagong, Bangladesh</p>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                <Icon icon={ic_public_outline}/>
                
                <DropdownButton 
                                 title={<Icon style={{color:'#000'}} icon={ic_more_horiz_outline}/>}
                                 style={{ backgroundColor: '#fff' }} // Replace '#yourColor' with the desired background color
                                
                               >
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/patient/register-doctor" ><Icon icon={ic_star_rate_outline}/> See life event</Dropdown.Item>       
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/doctor/practice" ><Icon icon={ic_create_outline}/> Edit WorkPlace</Dropdown.Item>       
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/patient/register-doctor"><Icon icon={ic_delete}/> Delete Workplace</Dropdown.Item>       
                                   
                               </DropdownButton>

                
                </div>
            </div>

            {/* Co-Founder */}
            <div className="d-flex justify-content-between mt-4">
                <div className="d-flex text-align-center">
                    <Icon icon={briefcase}/>
                    <div  className="text-start" >
                        <p className="fw-bold p-0 m-0">Practice Areas</p>
                        <p className="p-0 m-0">Bangladesh</p>
                    </div>
                </div>
                {/* <Icon icon={ic_public_outline}/> */}
            </div>
        </div>
    );
}
export default Index;