import React from 'react'
import './style.scss';
// import { Images } from '../../../utils/Images'
const Index = () => {

    return (
        <div className="allergies-reactions-table text-start">
            <p className='text-center p-2' style={{background:'#000', color:'#fff'}}>Right Eye</p>
            <table className="table">
                {/*  table-bordered */}
                {/* <head>Right Eye</head> */}
                
                <thead>
                    
                    <tr className=''>
                    <th scope="row">Sph</th>
                    <th scope="row">Cyl</th>
                    <th scope="row">Axis</th>
                    <th scope="row">VIA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><p>1</p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        
                    </tr>
                    <tr>
                        <td><p>2</p></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Index;