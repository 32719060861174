/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import './style.scss';
import Icon from 'react-icons-kit'
import { ic_report_gmailerrorred_outline } from 'react-icons-kit/md/'

import Appointment from '../../../components/patient/upcomingScreening/index'

const Index = () => {

    return (
        <div className="my-record text-start">
            <div className="d-flex justify-content-between Share-Health-Data-p">
                <p className="p-0 m-0"><Icon icon={ic_report_gmailerrorred_outline} /> Please read the Online Refund Instructions & Ticket Refund Policy before canceling your ticket.</p>
                <a href="#">View</a>
            </div>

            <div className="d-flex justify-content-center mt-4 mb-4 flex-wrap flex-sm-nowrap">
                <button className="record-btn">28D</button>
                <button className="record-btn">90D</button>
                <button className="record-btn">365D</button>
                <button className="record-btn">Dec</button>
                <button className="record-btn">Nov</button>
                <button className="record-btn">Oct</button>
                <button className="record-btn">2023</button>
                <button className="record-btn">2022</button>
                <button className="record-btn">Lifetime</button>
            </div>
            <div className="d-flex justify-content-center">
                <div className="col-sm-12 col-md-12 col-lg-5">
            <Appointment 
                appointment='appointment'
            />

                </div>
            </div>
        </div>
    );
}
export default Index;