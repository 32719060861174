import React from "react";
import './style.scss';
import Icon from 'react-icons-kit'
import {ic_check_circle_outline} from 'react-icons-kit/md/'
import ForwardPrescription from '../../../components/table/forwardPrescription/ForwardPrescription'
const Index = () => {

    return (
        <div className="forward-prescription text-start">
            <div className="card p-4">
                <div className="prescription">
                    <p className="mb-0 p-0 ms-4">Prescription</p>
                    <hr />
                </div>

                <div className="d-flex justify-content-between mb-4">
                    <div className="d-flex">
                        <button className="prescription-btn">28D</button>
                        <button className="prescription-btn">90D</button>
                        <button className="prescription-btn">365D</button>
                        <button className="prescription-btn">Dec</button>
                        <button className="prescription-btn">Nov</button>
                        <button className="prescription-btn">Oct</button>
                        <button className="prescription-btn">2023</button>
                        <button className="prescription-btn">2022</button>
                        <button className="prescription-btn">Lifetime</button>
                    </div>
                    <button className="add-prescription-btn">Add Prescription</button>
                </div>
                <ForwardPrescription/>
            </div>

            <div className="card mt-5 p-4 d-flex align-items-center justify-content-center">
                <Icon icon ={ic_check_circle_outline} size={35} style={{color:'#75147B', marginTop:'20px'}}/>
                <h5>That's all for now</h5>
                <button className="add-prescription-btn" style={{ marginBottom:'20px'}}>Back to Top</button>

            </div>

        </div>
    );
}
export default Index;