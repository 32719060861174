/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
// import Icon from 'react-icons-kit'
// import { Images } from '../../../utils/Images';
import { Modal } from 'react-bootstrap'
// import {ic_facebook} from 'react-icons-kit/md/'
// import {ic_collections} from 'react-icons-kit/md/'

// import { api } from '../../../../../utils/api'
// import 'react-toastify/dist/ReactToastify.css'
// import { x, } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
// import { toast } from 'react-toastify';
const Index = (props) => {

    // const [isLoading, setLoading] = useState(false) formState: { errors }, 

    const { register, handleSubmit, } = useForm();
    const handleReScheduleProps = () =>{
        props.handleReSchedule()
    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const header = {
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    // }


    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            props.handleRefetch()
            

        } catch (error) {
            props.onHide();
        }
    }

    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-schedule"
            >
                <Modal.Header>
                    <Modal.Title >Edit your Schedule card</Modal.Title>
                    {/* <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button> */}
                </Modal.Header>
                <Modal.Body >
                    <div >
                        
                        <div className='mt-2 border-none'>
                        
                            <div className='card-body p-4'>
                            <h6>Chamber Details:</h6>
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="row">

                                        {/* Enter Clinic\ Hospital Name */}
                                        <div className="col-12">
                                            <div className="form-group mb-2">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="Enter Clinic\ Hospital Name"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/* Clinic\ Hospital Area */}
                                        <div className="col-12">
                                            <div className="form-group mb-2">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="Clinic\ Hospital Area"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/* Select Clinic\ Hospital District*/}
                                        <div className="col-12">
                                            <div className="form-group mb-2">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="Select Clinic\ Hospital District"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>
                                        
                                        <h6>Practice Details:</h6>

                                        {/* Start Date */}
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group mb-2">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="Start Date"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/* Select Week Day/s */}
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group mb-2">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="Select Week Day/s"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/*(Optional)  End Date */}
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group mb-2">
                                                <p className='p-0 m-0' style={{fontSize:'11px'}}>(Optional)</p>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="End Date"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/*(Optional)  End Date */}
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group mb-2">
                                                <div className='d-flex justify-content-between' style={{fontSize:'11px'}}>
                                                <p className='p-0 m-0' >Sun,Mon,Tues,Wed,Thu,Fri,Sat </p>
                                                    <a href='#'>Edit</a>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                <h6 style={{fontSize:'13px'}}>Your Fees:</h6>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder=""
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                                <h6 style={{fontSize:'13px'}}>BDT/-</h6>

                                                </div>
                                                
                                            </div>
                                        </div>

                                        <h6>Hours:</h6>
                                        <div className='d-flex align-items-center'>
                                            {/* Time */}
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group mb-2">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="Time"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <p className='m-1'>To</p>        
                                        {/* Time */}
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group mb-2">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="Time"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>
                                        </div>
                                        <p style={{fontSize:'11px'}}>(Average time in munities you want to attend each patient)</p>
                                        <div className='d-flex align-items-center'>
                                            {/* Time */}
                                        <div className="col-sm-12 col-md-4">
                                            <div className="form-group mb-2">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="1-59"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <p className='m-1'>Minities</p>        
                                        
                                        </div>
                                        <p style={{fontSize:'11px'}}>Your patient capacity is 50 patients for this time slot.</p>
                                        <p style={{fontSize:'11px'}}>You can adjust this number by adjusting your schedule hours. <a href='#'>Learn More</a></p>
                                        <button type="submit" className='btn-save' onClick={handleReScheduleProps}>Save</button>
                                        <p style={{fontSize:'11px'}}>By clicking on save you agree to Privacy Policy & Terms</p>

                                    </div>



                                    {/* <div className='row'>
                                        <div className="modal-footer text-center" >
                                            <div className="col-12 text-center mt-2 mb-0">
                                                <button type="submit" className='account-btn'>Post</button>

                                            </div>

                                            {/* <button  className="btn-save shadow-none" disabled={isLoading}  >
                                                    {isLoading ? 'Save...' : 'Save'}
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn shadow-none"
                                                    disabled={isLoading}>
                                                    {isLoading ? 'Next...' : 'Next'}
                                                </button> 
                                        </div>
                                    </div> */}

                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
