import React from "react";
import './style.scss';
const Index = () => {
    return (
        <div className="doctor-post">
            <div className="m-4 p-4 d-flex justify-content-center align-items-center" style={{background:'gray', height:'500px', flexDirection: 'column'}}>
                <h1>POST BOX. WILL GIVE DETAIL</h1>
                <h1>DESIGN LATER ON</h1>
            </div>
            <hr/>
        </div>
    );
}
export default Index;