import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Select from 'react-select';
import Icon from 'react-icons-kit'
import { circle_left } from 'react-icons-kit/ikons/'
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AppointmentProfile from '../../../components/doctor/todaysAppointments/appointmentProfile/index'
const Index = () => {
    const { register, } = useForm();//handleSubmit, formState: { errors },
    const [isClearable,] = useState(true); //setIsClearable
    const [isSearchable,] = useState(true); //setIsSearchable
    const [isDisabled,] = useState(false); //setIsDisabled
    const [isLoading,] = useState(false); //setIsLoading
    const [isRtl,] = useState(false); //setIsRtl
    // const [tabIndex, setTabIndex] = useState(0);
    return (
        <div className="todays-appointment mt-3">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-9 d-flex flex-wrap justify-content-evenly">
                    <div className="col-sm-12 col-md-5 col-lg-5 mb-5"><AppointmentProfile /></div>
                    <div className="col-sm-12 col-md-5 col-lg-5 mb-5"><AppointmentProfile /></div>
                    <div className="col-sm-12 col-md-5 col-lg-5 mb-5"><AppointmentProfile /></div>
                    <div className="col-sm-12 col-md-5 col-lg-5 mb-5"><AppointmentProfile /></div>
                </div>
                <div className='patient-profile col-md-3 col-lg-3 d-none d-lg-block' style={{marginTop:'-1px'}}>
                    <div className=' card p-2 '>
                    {/* position-fixed top-2 end-0 overflow-auto */}
                    {/* style={{ width: '310px', maxHeight: "85vh" }} */}
                        <div className='d-flex'>
                            <div className='col-sm-12 col-md-6 col-lg-6'><h1 className='fw-bolder' style={{ color: '#00BD13' }}>60</h1></div>
                            <div className='col-sm-12 col-md-6 col-lg-6'><p><span>Patients Pending Today</span></p></div>
                        </div>


                        <Select
                            className="basic-single"
                            classNamePrefix="SPECIALIST"
                            // defaultValue={district[0]}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="color"
                            placeholder="Select Card"
                            styles={customStyles}
                        // options={district}
                        />

                        <div className='col-sm-12 col-md-12 col-lg-12 mt-1'>
                            <div className="form-group text-center mb-2">
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control shadow-none form-control-center"
                                    placeholder="Monday, 2PM - 5PM"
                                    {...register("email", {
                                        required: "Indications is required",

                                    })}
                                />
                            </div>
                        </div>

                        <div><button className='view-record' style={{ background: '#FFC7EA', width: '100%' }}>Load Patients</button></div>
                        <div><Icon icon={circle_left} /><h6>Todays Appointments</h6></div>




                    </div>
                    <div><button className='view-record mt-5' style={{ background: '#49A0AE' }}>ADD PATIENT</button></div>

                </div>
            </div>

        </div>
    )
}
const customStyles = {
    control: (styles) => ({
        ...styles,
        //   borderBottom: 'none',
        backgroundColor: 'white', // Adjust the background color as needed
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#000', // Change the color of the placeholder text
    }),
};
export default Index;