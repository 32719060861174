/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import Icon from 'react-icons-kit'
import { ic_menu, ic_notifications_active, ic_autorenew,
     ic_help_outline_twotone, ic_logout,ic_settings, ic_person,ic_person_outline_outline, 
     ic_keyboard_arrow_down_twotone,ic_shutter_speed_outline,
     ic_thumbs_up_down, ic_keyboard_backspace_outline } from 'react-icons-kit/md/'
// import { search } from 'react-icons-kit/feather'
// import { chevronDown } from 'react-icons-kit/feather/'

import { Outlet } from "react-router-dom"
import { NavLink, Link } from 'react-router-dom'
// import Timer from '../time/Index'
import Layout from '../layout/Index';
import DoctorLayout from '../layout/DoctorLayout';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { Images } from '../../utils/Images'
import { useEffect } from 'react';
// import ChangePassword from '../auth/models/ChangePassword/index'
// import { roleShow } from '../../utils/helpers'
// import {Navbar} from '../../navbar/Index'
// import Alarm from '../../pages/Notification/Index';
// import QuickISearch from '../QuickISearch/Index'
const Sidebar = () => {
    const [view, setView] = useState(false)
    // const [show, setShow] = useState(false)
    const [viewSetting, setViewSetting] = useState(true)
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [isActive, setIsActive] = useState(0)
    const handleIsActive = (data) => {
        setIsActive(data)
    }
    const handleSetting =() =>{
        setViewSetting(!viewSetting)
    }

    // const handleChangePassword =() =>{
    //     setShow(true)
    // }
    const doLogout = async () => {
        try {
            // await Requests.Auth.Logout(header);
            localStorage.clear()
            window.location.reload()
        } catch (err) {
            if (err) console.log(err);
        }
    }
    const handleNotificationView = e => {
        e.preventDefault()
        setView(!view)
    }
     useEffect(() => {
        
    }, [viewSetting])
    return (
        <div className="master">
            <div className="main">
                <nav id="" className="navbar bg-light align-items-center" style={{marginTop:'-10px'}}>
                    <div className='d-flex ms-3'>
                        <Link to="/">
                            <img style={{ height: '20px' }} src={Images.TextLogo} className="img-fluid" alt="Logo" />

                            {/* <div className='d-flex flex-column align-items-center'>
                                            <img style={{ width: '33px' }} src={Images.Logo} className="img-fluid" alt="Logo" />
                                        </div> */}

                        </Link>
                        {/* <QuickISearch/> */}
                    </div>
                    <div className='d-flex'>
                        <NavLink activeClassName={isActive === 0 ? "isActive" : ''} to="/patient/patient-community" onClick={() => handleIsActive(0)}>

                            <span><img style={isActive === 0 ? { width: '40px', height: '40px', borderRadius: '25px', padding: '5px' }
                                : { width: '40px', height: '40px', borderRadius: '25px', padding: '5px' }}
                                src={isActive === 0 ? Images.CommunityClicked : Images.CommunityUnClicked} /></span></NavLink>
                        <NavLink activeClassName="isActive" to="/patient/find-care" onClick={() => handleIsActive(1)}><span>
                            <img style={isActive === 1 ? { width: '40px', height: '40px', borderRadius: '25px', padding: '5px' }
                                : { width: '40px', height: '40px', borderRadius: '25px', padding: '5px' }} src={isActive === 1 ? Images.FineCareClicked : Images.FineCareUnClicked} /></span></NavLink>
                        <NavLink activeClassName="isActive" to="/doctor/community" onClick={() => handleIsActive(2)}><span>
                            <img style={isActive === 2 ? { width: '40px', height: '40px', borderRadius: '25px', padding: '5px' }
                                : { width: '40px', height: '40px', borderRadius: '25px', padding: '5px' }} src={isActive === 2 ? Images.PatientCommunityClicked : Images.PatientCommunityUnClicked} /></span></NavLink>

                    </div>
                    <ul className="nav nav-pills">

                        <li className="nav-item d-flex align-items-center">
                            <Icon className='icon-notification' style={{ color: '#707070', marginRight: '15px' }} type='button' icon={ic_notifications_active}  onClick={handleNotificationView} size={25} />
                            {/* onClick={MyProfile}   onClick={Notification} style={{background:'#fff', border:'none'}} */}
                            <DropdownButton 
                                 title={<img className="img-logo image-container rounded-circle" type='button' src={Images.Female} alt="" />}
                                 style={{ backgroundColor: '#fff' }} // Replace '#yourColor' with the desired background color
                                
                               >
                                <div>
                                {viewSetting? (<div>
                                    <div className='card m-3 text-center'>
                                    <Dropdown.Item className='dropdown-text p-3' as={Link} to="/doctor/my-profile" onClick={() => handleIsActive(2)}><Icon icon={ic_person}/> Profile</Dropdown.Item>
                                    <hr className='mt-0 pt-0'/>
                                    <Dropdown.Item className='switch-btn' as={Link} to="/doctor/community" onClick={() => handleIsActive(2)}> <Icon icon={ic_autorenew} size={17} />Switch to care day mode</Dropdown.Item>
                                    <Dropdown.Item className='family-btn mt-2 mb-3' as={Link} to="" onClick={() => handleIsActive(2)}>Add a family member</Dropdown.Item>
                                </div>
                                    <Dropdown.Item className='dropdown-text' as={Link} onClick={handleSetting}><Icon icon={ic_settings}/> Setting & Policy <Icon icon={ic_keyboard_arrow_down_twotone}/></Dropdown.Item>       
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/patient/register-doctor" onClick={() => handleIsActive(0)}><Icon icon={ic_thumbs_up_down}/> Help and Support</Dropdown.Item>       
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/doctor/practice" onClick={() => handleIsActive(0)}><Icon icon={ic_help_outline_twotone}/> Give a feedback</Dropdown.Item>       
                                    <Dropdown.Item className='dropdown-text' as={Link} to="/patient/register-doctor" onClick={() => handleIsActive(0)}><Icon icon={ic_person_outline_outline}/> Become a Doctor</Dropdown.Item>       
                                 <Dropdown.Item className='dropdown-text' onClick={doLogout}><Icon icon={ic_logout}/>Logout</Dropdown.Item>
                            
                                </div>):(<div>
                                        <Dropdown.Item className='dropdown-text' as={Link} onClick={handleSetting}><Icon icon={ic_keyboard_backspace_outline}/> Settings</Dropdown.Item>       
                                        <Dropdown.Item className='dropdown-text' as={Link} to='/doctor/forget-password'><Icon icon={ic_settings}/> Change Password</Dropdown.Item>       
                                        <Dropdown.Item className='dropdown-text' as={Link} to="/doctor/profile-settings-cont" ><Icon icon={ic_shutter_speed_outline}/>Profile Settings</Dropdown.Item>       
                                        <Dropdown.Item className='dropdown-text' as={Link} to="/doctor/preference" ><Icon icon={ic_settings}/>Preferences</Dropdown.Item>       

                                    </div>)}

                                    </div>
                               </DropdownButton>


                        </li>

                        <li className="d-lg-none">
                            <button type="button" className="btn btn-sm shadow-none" onClick={toggle}>
                                <Icon icon={ic_menu} size={20} />
                            </button>

                        </li>

                    </ul>
                </nav>
                {/**/}
                {isActive === 2 ?
                    <DoctorLayout isOpen={isOpen} />
                    : <Layout isOpen={isOpen} />
                }

                {/* {isOpen?<Layout />:null} */}
                <div className="content">

                    {/* <Footer/> */}
                    <div className='outlet'>
                        <Outlet />

                    </div>

                </div>

                {/* </main> */}
            </div>



            {
                view ?
                    <div className={view ? 'notification-section border-0' : 'd-none'}>
                        

                        {/* {notifications.profileViewed > 0 ? */}
                            <div className='profileView'>
                                <h6 className='text-start'>Notification</h6>
                                <div className='header'>
                                {/* <p className='title'> user viewed yout profile</p> */}
                                <div className='seenbtn'>All</div>
                                <div className='seenbtn'>Unread</div>
                                </div>
                                {/* {notifications.profileViewPerson.map((one, idx) => onClick={removeViewPerson} {notifications.profileViewed}*/}
                                    <NavLink key={1} className='info-container d-flex'
                                        type="button"
                                        // to={`/single-profile/${one._id}`}
                                    >
                                        <img src={Images.Female} style={{width:'35px', height:'35px'}} alt="noimg" className='image-container' />
                                        <p className='description'>Lorem Ipsum has been the industry's standard dummy text</p>
                                        {/* {one.description} */}
                                    </NavLink>
                                {/* // )} */}
                            </div>
                            {/* : null
                        } */}
                    </div>
                    : null
            }
             {/* {
                    show ?
                    <ChangePassword
                        show={show}
                        onHide={() => setShow(false)}
                    />:null
                } */}
        </div>

    );
};

export default Sidebar;

