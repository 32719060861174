import React, {useState} from "react";
import './style.scss';
// import { Images } from '../../../utils/Images'
import PatientSidebar from '../../../components/sidebar-patient/index'
import Schedules from '../../../components/Schedules/index'
import Schedule from '../../../components/model/Schedulecard/index'
import ReSchedule from '../../../components/model/Schedulecard/Payment/index'
import PaymentSuccessful from '../../../components/model/Schedulecard/PaymentSuccessful'
import PaymentUnSuccessful from '../../../components/model/Schedulecard/PaymentUnSuccessful'



const Index = () => {
    const [schedule, setSchedule] = useState(false)
    const [reSchedule, setReSchedule] = useState(false)
    const [paymentSuccessful, setPaymentSuccessful] = useState(false)
    const [paymentUnSuccessful, setPaymentUnSuccessful] = useState(false)
    // const [isMenu, setMenu] = useState(false)
    // const [loggingOut, setLoggingOut] = useState(false)
  
    const handleSchedule = () => {
        setSchedule( true )
    }
    const handleReSchedule = () => {
        setSchedule( false )
        setReSchedule(true)
    }
    const handlePaymentSuccessful = () => {
        setReSchedule(false)
        setPaymentSuccessful(true)
    }

    const handlePaymentUnSuccessful = () => {
        setPaymentSuccessful(false)
        setPaymentUnSuccessful(true)
    }
    return (
        <div className="doctor-schedule">
            {/* <div className="row"> */}

            <div className="col-sm-12 col-md-12 col-lg-9">
            <div className="d-flex justify-content-between align-items-center">
            <button className='schedule-btn' onClick={handleSchedule}>Add New</button>
            <p className="fw-bold m-0 p-0" style={{color:'#7B7A7B'}}>You Have <span  style={{color:'#1EC630'}}>2 Active</span> Schedules</p>
            </div>
              <h3 className="text-start"  style={{color:'#7B7A7B'}}>Your Schedule</h3>  
              <div className="col-sm-12 col-md-12 col-lg-10 pe-lg-5 ps-lg-5 m-auto">
                <Schedules/>
                <Schedules/>
                <Schedules/>
                <Schedules/>
                <Schedules/>
                <Schedules/>
                <Schedules/>
              </div>
            </div>
            <div className='col-md-3 col-lg-3 d-none d-lg-block '>
                <div className='card p-2 position-fixed top-2 end-0 overflow-auto' style={{ width: '310px', maxHeight: "85vh" }}>
                    <PatientSidebar  profile ={'doctor'}/>
                </div>
            </div>
            {/* </div> */}
            {
                    schedule ?
                    <Schedule
                        show={schedule}
                        handleReSchedule={handleReSchedule}
                        onHide={() => setSchedule(false)}
                    />:null
                }

                {
                    reSchedule?
                    <ReSchedule
                        show={reSchedule}
                        handlePaymentSuccessful={handlePaymentSuccessful}
                        onHide={() => setReSchedule(false)}
                    />:null
                }

                {
                    paymentSuccessful?
                    <PaymentSuccessful
                        show={paymentSuccessful}
                        handlePaymentUnSuccessful={handlePaymentUnSuccessful}
                        onHide={() => setPaymentSuccessful(false)}
                    />:null
                }
                {
                    paymentUnSuccessful?
                    <PaymentUnSuccessful
                        show={paymentUnSuccessful}
                        // handleReSchedule={handlePaymentUnSuccessful}
                        onHide={() => setPaymentUnSuccessful(false)}
                    />:null
                }

        </div>
    );
}
export default Index;