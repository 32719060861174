import React, { useState, useEffect } from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import Icon from 'react-icons-kit'
import { ic_facebook } from 'react-icons-kit/md/'
import { Modal } from 'react-bootstrap'
import { Images } from '../../../../utils/Images'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
// import { api } from '../../../../../utils/api'
// import 'react-toastify/dist/ReactToastify.css'
// import { x, } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
import { ic_arrow_drop_down, ic_help } from 'react-icons-kit/md/'
import Select from 'react-dropdown-select';
// import { toast } from 'react-toastify';
const Index = (props) => {

    const [isLoading, setLoading] = useState(false) 

    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [value, setValue] = useState()
    const currentYear = new Date().getFullYear();
    const [day, setDay] = useState(null);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);

    const days = Array.from({ length: 31 }, (_, i) => ({ label: `${i + 1}`, value: `${i + 1}` }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const months = [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
    ];

    const years = Array.from({ length: 100 }, (_, i) => ({
        label: `${currentYear - i}`,
        value: `${currentYear - i}`,
    }));

    const handleDayChange = (selected) => {
        setDay(selected[0]);
    };



    const handleMonthChange = (selected) => {
        setMonth(selected[0]);
    };

    const handleYearChange = (selected) => {
        setYear(selected[0]);
        
    };

    useEffect(() => {
        // Set default values when the component mounts

        setDay(days[0]);
        setMonth(months[0]);
        setYear(years[0]);
        setLoading(true)
    }, [days, months, years]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const header = {
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    // }

    const handleOTP = () =>{
        props.handleOtp()
    }

    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            props.handleRefetch()
            // const res = await Axios.post(`${api}user/prospect`, regData, header);

            // if (res.status === 200) {
            //     setId(res.data.response);
            //     toast('Your create account save');
            //     setStep(1);
            //     setLoading(false)
            //     props.handleRefetch()
            // }
            // else {
            //     setLoading(false);
            //     toast('This phone number or email already exists.');
            //     props.onHide();
            // }



        } catch (error) {
            // if (error) 
            // console.log(error.response.data.message)
            // setLoading(false);
            // toast(error.response.data.message);
            props.onHide();
        }
    }
    if (!isLoading) return (<div></div>)
    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-form"
            >
                {/* <Modal.Header>
                    <Modal.Title>{props.value ? props.value.name : 'New Traffic'}</Modal.Title>
                    <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button>
                </Modal.Header> */}
                <Modal.Body >

                    <div className='d-flex align-items-center'>
                        <div className='col-5 text-center firstColumn'>
                            <div className='mb-2'>
                                <img src={Images.Logo} className='modal-logo-img' alt='' />
                            </div>

                            <img src={Images.TextLogo} className='modal-logo-text-img mb-5' alt='' />
                            <p className='mt-4' style={{ fontSize: '20px', color: '#000' }}>HI!!</p>
                            <p className='mb-0' style={{ fontSize: '20px', color: '#000' }}>Welcome Back To</p>
                            <h1 style={{ fontSize: '20px', fontWeight: "bold", color: '#C2696E' }}>MEDISERV.ai</h1>
                        </div>
                        <div className='col-12 col-md-12 col-sm-7 col-lg-7 col-xl-7 secondColumn'>
                            <p className='p-0 text-end' style={{ fontSize: '12px' }}>English(UK) <Icon icon={ic_arrow_drop_down} /></p>

                            <div className='text-center mb-4' style={{ color: '#000' }}>
                                <h2 style={{ fontSize: '25px' }} >Create a new account</h2>
                                <p>It's quick and easy</p>
                            </div>

                            <div className='card mt-2 border-none'>
                                <div className='card-body p-4'>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <div className="row">

                                            {/* Name */}
                                            <div className="col-6">
                                                <div className="form-group mb-2">

                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control shadow-none"
                                                        placeholder="First name"
                                                        {...register("email", {
                                                            required: "First name is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>

                                            {/* Surname */}
                                            <div className="col-6">
                                                <div className="form-group mb-2">

                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control shadow-none"
                                                        placeholder="Surname"
                                                        {...register("email", {
                                                            required: "Surname is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>

                                            {/* Email */}
                                            <div className="col-12">
                                                <div className="form-group mb-2">

                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control shadow-none"
                                                        placeholder="Email"
                                                        {...register("email", {
                                                            required: "Email is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-control form-group">

                                                    <PhoneInput
                                                        name="phone"
                                                        style={{ border: 'none' }}
                                                        className="shadow-none"
                                                        value={value}
                                                        onChange={setValue}
                                                        placeholder="Enter phone number"
                                                    // {...register("phone", {
                                                    //     required: "First name is required",

                                                    // })}
                                                    />
                                                </div>
                                            </div>

                                            {/* Email */}
                                            <div className="col-12 mt-2">
                                                <div className="form-group mb-2">
                                                    {errors.phone && errors.phone.message ? (
                                                        <p className="text-danger">{errors.phone && errors.phone?.message}</p>
                                                    ) : <p>Birth Date<Icon icon={ic_help} /></p>
                                                    }
                                                    <div className="d-flex justify-content-between">

                                                        <div className="col-sm-12 col-md-3"> <Select options={days} onChange={handleDayChange} values={[day]} /></div>
                                                        <div className="col-sm-12 col-md-4"><Select options={months} onChange={handleMonthChange} values={[month]} /></div>
                                                        <div className="col-sm-12 col-md-3"><Select options={years} onChange={handleYearChange} values={[year]} /></div>


                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-lg-6">
                                                <div className="form-group mb-4">
                                                    {errors.familyWith && errors.familyWith.message ? (
                                                        <p className="text-danger">{errors.familyWith && errors.familyWith?.message}</p>
                                                    ) : <p>Gender<Icon icon={ic_help} /></p>
                                                    }
                                                    <div className='d-flex mt-2'>
                                                        <label className='form-control radio-gender d-flex align-items-center' style={{ marginRight: '20px' }}>
                                                            <p className='p-0 m-0'>Female</p>

                                                            <input
                                                                style={{ marginLeft: '12px' }}
                                                                type="radio"
                                                                value="Female"
                                                            // checked={selectedOption === 'Yes'}
                                                            // onChange={handleOptionChange}
                                                            />

                                                        </label>

                                                        <label className='form-control radio-gender d-flex align-items-center'>
                                                            <p className='m-0 p-0'>Male</p>
                                                            <input
                                                                style={{ marginLeft: '12px' }}
                                                                type="radio"
                                                                value="Male"
                                                            // checked={selectedOption === 'No'}
                                                            // onChange={handleOptionChange}
                                                            />

                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className='row'> */}
                                        <p className='mt-2' style={{ color: '#000', fontSize: '10px' }}>
                                            People who use service may have uploaded your contact information to Mediserv.
                                            <span style={{ color: '#a356a8', fontSize: '12px' }}>Learn more</span>
                                        </p>
                                        <p className='mt-2' style={{ color: '#000', fontSize: '10px' }}>
                                            By clicking Sign up, you agree to our
                                            <span style={{ color: '#a356a8', fontSize: '12px' }}> Terms, Privacy policy</span> and
                                            <span style={{ color: '#a356a8', fontSize: '12px' }}> cookies policy</span> you may receive SMS notification from us and can opt out any time
                                        </p>
                                        <h6 className='mt-2 text-center' style={{ color: '#D9D7D7', fontSize: '12px' }}>-OR-</h6>

                                        <div className='row mt-3'>
                                            <div className='col-6'><p className='sign-up-google'><img style={{ width: '20px' }} src={Images.Google} alt='' /> Signup with Google</p></div>
                                            <div className='col-6'><p className='sign-up-google'><Icon style={{ color: '#1e4297' }} icon={ic_facebook} size={20} /> Signup with Facebook</p></div>
                                        </div>


                                        {/* </div> */}

                                        <div className='row'>
                                            <div className="modal-footer text-center" >
                                                <div className="col-12 text-center">
                                                    <button className='btn-sign-up' type="submit" style={{ background: "#A356A8" }} onClick={handleOTP}>Sign Up</button>

                                                </div>
                                                <div className="col-12 text-center mb-0"><p style={{ fontWeight: "bold" }}>Already have an account?</p></div>


                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '40px' }}>
                                <div className='col-8'>
                                </div>
                                <div className='col-4' style={{ textAlign: 'end' }}>
                                    <button type="button" className="close-btn shadow-none" onClick={props.onHide} >Close</button>
                                </div>
                            </div>
                        </div>

                    </div>


                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
