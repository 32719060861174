import React from 'react';
import './style.scss'
import Patient from '../../components/patient/Index'
import PatientSidebar from '../../components/sidebar-patient/index'
const Index = () => {
    return(
        <div className='patient-profile-page row'>
            <div className='col-sm-12 col-md-9 col-lg-9 card'>
            <Patient profile={'self'}/>
            </div>
            <div className='col-md-3 col-lg-3 d-none d-lg-block '>
            <div className='card p-2 position-fixed top-2 end-0 overflow-auto'  style={{width:'310px',maxHeight: "85vh"}}>

                <PatientSidebar/>
            </div>
            </div>
        </div>
    );
}
export default Index;