import React from 'react'
import './style.scss'
const Index = () => {

    return (
        <></>
    );
};

export default Index;



