import React, {useState} from "react";
import './style.scss';
import { Images } from '../../utils/Images'
import SearchComponent from '../search/Index'
import Icon from 'react-icons-kit'
import { user } from 'react-icons-kit/ikons/user'
import { ic_more_horiz_outline, ic_autorenew, ic_favorite_border, ic_create,ic_public } from 'react-icons-kit/md/'
import { speech_bubble_1 } from 'react-icons-kit/ikons/'
import { ic_arrow_drop_down, ic_download_outline, ic_upload_outline } from 'react-icons-kit/md/'
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form' 
import DoctorPost from '../../components/model/postDoctor/index'
const Index = ({community}) => {
    const { register, handleSubmit, formState: { errors },  } = useForm()
    const [show, setShow] = useState(false)
    const handlePost = () => {
        setShow( true )
    }
    // const [searchValues, setSearchValues] = useState(null);
    const onClearSearch = event => {
        // const value = event.target.value
        // if (!value) props.clear()
    }

    // Submit Form
    const onSubmit = data =>{

    };
    return (
        <div className="community-component">
            <div className="">
                <div className="card  mb-3 ">
                    <div className='search-card'>
                        <SearchComponent
                            placeholder='Ask something to the Community'
                        />
                        {community ?
                            <div className="d-flex justify-content-center" type='button' onClick={handlePost}>
                                <Icon icon={ic_create} size={20}/>
                                Post
                            </div>
                            : null
                        }
                        <hr style={{ height: '2px' }} />
                    </div>

                </div>
                <div className="card mt-3 mb-3">
                    <div className="d-flex justify-content-between">
                        <div className="col-sm-12 col-md-12 col-lg-5">
                            <div className="d-flex">
                                <img className="m-3 " src={Images.Doctor2} alt="" />
                                <div className="d-flex flex-column align-items-start m-3">
                                    <h3>Dr. Iman Khan</h3>
                                    <Link to='/single-post' style={{ textDecoration: 'none', color: 'black' }} className="p-0 m-0">
                                        <p className="p-0 m-0">15min <Icon icon={ic_public} size={19}/></p>
                                    </Link>
                                    {/* <p type='button' as={Link} to ='/single-post' className="p-0 m-0">15min <Icon icon={ic_public} size={19}/></p> */}
                                    <h3 style={{ color: "#A0A0A0" }}>MBBS FCPS</h3>
                                </div>

                            </div>
                        </div>
                        <Icon style={{ color: '#E1E1E1' }} icon={ic_more_horiz_outline} size={50} />
                    </div>

                    <div className="mt-4">
                        <h5 className="text-start p-4 fw-bold">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type
                            specimen book. It has survived not only five centuries, but also the leap into
                            electronic typesetting, remaining essentially unchanged ...See More</h5>
                    </div>

                    <div style={{ height: '200px', background: '#D9D9D9' }}>

                    </div>
                    <div>
                        <hr style={{ height: '2px' }} />
                        <div className="d-flex justify-content-between  flex-column flex-md-row">
                            <h6 className="text-start m-3"><Icon icon={ic_favorite_border} /> 2.1k <Icon icon={ic_autorenew} /> 2.1k <Icon icon={speech_bubble_1} /> 2.1k</h6>

                            <div>
                                <Link className="d-flex align-items-center link-btn m-0"><Icon className="me-1 mb-0" icon={ic_upload_outline} size={25} />Upvote . 2k<div className="ms-1 mb-0 vertical-line "><Icon className="ms-2 mb-0" icon={ic_download_outline} size={25} /></div></Link>
                                <h6 className="mt-2">Most Recent<Icon className="me-4" icon={ic_arrow_drop_down} size={30} /></h6>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-around">
                            <img className="d-none d-lg-block" style ={{width:'60px', height:'60px'}} src={Images.Female} alt=""/>
                        <div className="col-11 search-component">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="input-container">
                                    <input
                                        type="text"
                                        name="query"
                                        {...register("query", { required: 'value is required' })}
                                        placeholder="Write a comment ..."//{`Search ${props.placeholder}`}
                                        onChange={onClearSearch}
                                        className={errors.query ? "form-control form-control-sm shadow-none error" : "form-control form-control-sm shadow-none"}
                                    />
                                    <button
                                        type="submit"
                                        className="btn btn-sm shadow-none"
                                        // disabled={props.loading}
                                        >
                                   
                                    </button>

                                </div>
                            </form>
                        </div>
                        </div>
                        


                        <div className="text-start d-flex flex-column align-items-start m-3">
                            <h5><Icon className="" icon={user} size={30} /> Md. Hanif</h5>
                            <p style={{ marginLeft: '40px' }}>when an unknown printer took a galley of type and scrambled it to make a
                                type specimen book. It has survived not only five
                                centuries, but also the leap into electronic typesetting, remaining essentially unchanged... </p>
                            <h6 className="text-start mt-0 " style={{ marginLeft: '40px' }}><Icon icon={ic_favorite_border} /> 2.1k <Icon icon={speech_bubble_1} /> 2.1k</h6>
                            <p className="text-start p-4 fw-bold">View More Comments</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                    show ?
                    <DoctorPost
                        show={show}
                        // handleBookingPayment={handleBookingPayment}
                        onHide={() => setShow(false)}
                    />:null
                }
        </div>
    );
}
export default Index;