import React from "react";
import './index.css';



const Index = () => {
    return (
        <div className="container p-4">
            <div className="content-container">
                <h1>Privacy Policy</h1>
                <h4>এই প্রাইভেসি পলিসি সর্বশেষ হালনাগাদ  করা হয় ১১ ই জুন ২০২৪ ইং তারিখে।</h4>
                <h3>Data Security for Patients:</h3>
                <ol>
                    <li>
                        আমরা রোগীর ডেটা তৃতীয় পক্ষের সাথে ভাগ করি না রোগীর অনুমোদিত একজন চিকিৎসা পেশাদার ছাড়া।
                    </li>
                    <li>
                        প্রতিটি রোগী শুধুমাত্র তাদের সেবা স্থিতি দেখতে পারেন (যেমনঃ তাদের অ্যাপয়েন্টমেন্ট তথ্যের বিশদ, নিজের পূর্ববর্তী মেডিকেল রেকর্ড, পূর্ববর্তী এপয়েন্টমেন্ট তথ্যের বিশদ, পূর্ববর্তী মানি রিসিট, প্লাটফর্মের সকল ডক্টরের লিস্ট, প্লাটফর্মে ডক্টরদের স্বাস্থ্য সম্পর্কিত  পাবলিক পোস্ট, নিজের ভ্যাকসিনেশন রেকর্ড ইত্যাদি।
                    </li>
                    <li>
                        রোগীরা একে অপরকের ডেটা দেখতে পারে না।
                    </li>
                    <li>
                        আমরা আপনার ফোন নম্বরের সাথে 'সাইন-ইন প্রসেস' এর জন্য 2FA (দুটি ফ্যাক্টর প্রমাণীকরণ) অনুসরণ করি, আসল আপনাকে সাইন ইন করার জন্য সনাক্ত করার জন্য।
                    </li>
                    <li>
                        রোগীরা তাদের প্রোফাইল যে কোন ডাক্তারের সাথে mediserv.ai প্ল্যাটফর্মে শেয়ার করতে পারবেন
                        বিনামূল্যে।
                    </li>
                </ol>

                <h3>Data Security for Doctors:</h3>
                <ol>
                    <li>
                        একটি ডাক্তারের ডেটা কোনও তৃতীয় পক্ষের সাথে ভাগ করা হয় না। একজন রোগীর ডেটা, যা ডাক্তার দ্বারা প্রবেশ, দেখা বা পরিচালিত হয়, শুধুমাত্র সম্মতির আওতায় রোগী দ্বারা নিযুক্ত তৃতীয় পক্ষে চিকিৎসা  সেবা প্রদাতার সাথে ভাগ করা হতে পারে।
                    </li>
                    <li>
                        প্রতিটি ডাক্তারের একক অ্যাক্সেস তাদের ডেটার সাথে সীমাবদ্ধ রয়েছে। তবে, যেহেতু এই প্লাটফর্মটি ডাক্তারদের নেটওয়ার্কিং জায়গা হিসাবেও কাজ করে, সেজন্য সারাবিশ্বের ডাক্তারদের জন্য এটি একটি পাবলিক প্রোফাইল দেখা যাবে। ডাক্তাররা তাদের পাবলিক ডেটা (যেমনঃ পাবলিক পোস্ট, রোগীর তালিকা ইত্যাদি) প্রাইভেসি সেটিংস পরিবর্তন করতে পারবেন
                    </li>
                </ol>
                <h3>Securely Hosted on Amazon Cloud Server</h3>
                <ol>
                    <li>সিকিউরিটি একমপ্লায়েন্স এবং সলিউশনে সর্বোচ্চ মানের। </li>
                    <li>সবচেয়ে জোরদার সুরক্ষা ও কমপ্লায়েন্স কন্ট্রোল </li>
                    <li>সবচেয়ে আধুনিক সিকিউরিটি মেথড ও ডেটা সিকিউরিটি মেথড অনুসরণ করে বানানো</li>
                </ol>
                <h3>Password is protected using RSA signature with SHA-256 Algorithm</h3>
                <ol>
                    <li>
                        এর সাথে সংজ্ঞায়িত কোনও সুরক্ষার দুর্বলতা নেই যা এটি অসুরক্ষিত করে
                    </li>
                    <li>
                        এটি খুবই নিরাপদ এবং বিশ্বস্ত প্রযুক্তি
                    </li>
                    <li>
                        এভিলাঞ্চ এফেক্ট
                    </li>
                    <li>
                        কলিশন হওয়া প্রায় অসম্ভব
                    </li>
                </ol>
                <h3>Protected Database by Amazon</h3>
                <ol>
                    <li>পাসওয়ার্ড, কেরবেরোস, এবং  ডেটাবেস প্রাথমিকভাবে ডেটাবেসে প্রমাণীকরণের বিভিন্ন পদ্ধতি ব্যবহার করে।</li>
                    <li>এই ভাবে, একটি নির্দিষ্ট ব্যবহারকারী শুধুমাত্র একটি প্রায় মেধার্থির মাধ্যমে একটি ডেটাবেসে লগইন করতে পারে।</li>
                </ol>
                <h3>Regular Backup by Amazon</h3>
                <ol>
                    <li>ডেটা ট্রেরের দ্রুততা</li>
                    <li>সমস্ত প্রকারের ডেটা ব্যাকআপ  </li>
                    <li>ম্যানেজ, অ্যাক্সেস, অব্যবহার গণনা, ডেটা এনক্রিপ্ট করুন, এবং তফসিলভুক্তি পরিচালনা।</li>
                </ol>
                <h3>Software is up to date with cutting edge technologies</h3>
                <ol>
                    <li>শিল্প অনুসারে, সফ্টওয়্যারটি অন্যান্য লাইব্রেরিগুলির উপর নির্ভর করে, তাই আমাদের সিস্টেমটি সব উপায়ে হালনাগাদ এবং যেকোনো ব্লিমিশ কমিয়ে ফেলা হয়।</li>
                    <li>সর্ববৃহৎ সুরক্ষা এবং অনুসারণ নিয়ন্ত্রণ উপলব্ধি করে নেওয়া </li>
                    <li>সর্বোচ্চ মানের গোপনীয়তা এবং ডেটা সুরক্ষা জন্য তৈরি হয়েছে</li>
                </ol>
                <h3>Use Base64-encoded JSON to call the server from end-user</h3>
                <ol>
                    <li>আমরা একেবারে Base64-এনকোড করা JSON টোকেন ব্যবহার করছি যাতে শেষ ব্যবহারকারীর থেকে যেকোনো অনুরোধ করা যায় এবং এটি নিশ্চিত করে যে অন্য কেউ আপনার ডেটা পাতেন না।</li>
                </ol>
                <h3>Following MicroService architecture to Scale the system and ensure 99.99% uptime</h3>
                <ol>
                    <li>আমরা আমাদের সিস্টেমটি মাইক্রোসার্ভিস প্রণালী অনুসরণ করে তৈরি করেছি</li>
                    <li>কতগুলি অনুরোধ প্রতি সেকেন্ডে আমরা পেয়েছি তা বলে না। আমাদের সিস্টেম যখন আরও অনুরোধ হ্যান্ডল করতে প্রয়োজন হয়, তখন স্বয়ংক্রিয়ভাবে স্কেল করা যায় যা সার্ভারের অবিচ্ছিন্নতা নিশ্চিত করে।</li>
                </ol>

            </div>
        </div>
    )
}
export default Index;