import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
// import { useForm } from 'react-hook-form'
import { Link } from "react-router-dom";
// import Icon from 'react-icons-kit'
// import {ic_add_circle_outline} from 'react-icons-kit/md/'

import ServicesAndSpecialization from '../../../components/setting/ProfileSettingsCont/Services'
import Education from '../../../components/setting/ProfileSettingsCont/Education'
import Experience from '../../../components/setting/ProfileSettingsCont/Experience'
import Awards from '../../../components/setting/ProfileSettingsCont/Awards'
import Membership from '../../../components/setting/ProfileSettingsCont/Membership'
import Registrations from '../../../components/setting/ProfileSettingsCont/Registrations'
const Index = () => {
    // const navigate = useNavigate();
    // const { register, } = useForm();//handleSubmit, formState: { errors }, 
    // const onSubmit = async (data) => {
    //     try {

    //         // setLoading(true)
    //         const regData = {
    //             ...data
    //         }
    //         console.log(regData);
    //         navigate(`/doctor/change-password`);
        
    //     } catch (error) {
 
    //     }
    // }
    return (

        <div className="profile-setting-cont p-2 pt-0">
            <ServicesAndSpecialization />
            <Education />
            <Experience />
            <Awards />
            <Membership />
            <Registrations />
            <div className='text-start mb-5'><Link className='save-btn'>Save Changes</Link></div>
            
            </div>
       
    );
}
export default Index;

