import Doctors from '../assets/doctors.jpg'
import Doctor from '../assets/doctor.png'
import Logo from '../assets/Icon Logo.png'
import Logo2 from '../assets/Logo.png'
import TextLogo from '../assets/Text Logo.png'
import Profile from '../assets/profile 1.png'
import Google from '../assets/google-logo.png'
import Banner from '../assets/banner.png'
import Lab from '../assets/lab.png'
import Tickets from '../assets/Tickets.png'
import GooglePlay from '../assets/google-play.png'
import AppStore from '../assets/app-store.png'
import Payment from '../assets/payment.png'
import Doctor2 from '../assets/doctor2.png'
import BlackProfile from '../assets/black-profile.png'
import ProfileIcon from '../assets/profile-icon.png'
import ChangePassword from '../assets/change-password.png'
import Female from '../assets/Female Image Place Holder.png'
import PrivacyTerms from '../assets/privacy-terms.png'

// payment
import Bkash from '../assets/bkash .png'
import NotBkash from '../assets/notbkash.png'

// menu image
import CommunityClicked from '../assets/menu/Community Clicked.png'
import CommunityUnClicked from '../assets/menu/Community UnClicked.png'
import FineCareClicked from '../assets/menu/Find Care clicked.png'
import FineCareUnClicked from '../assets/menu/Find Care Unclicked.png'
import PatientCommunityClicked from '../assets/menu/Patient Community Clicked.png'
import PatientCommunityUnClicked from '../assets/menu/Patient Community UnClicked.png'


//patient sidebar icon
import UpcomingScreenings from '../assets/patientIcon/Upcoming Screenings.png'
import DocumentCentre from '../assets/patientIcon/Document Centre.png'
import FindCareLeftSide from '../assets/patientIcon/Find Care Left Side.png'
import ForwardPrescriptions from '../assets/patientIcon/Forward Prescriptions.png'
import MyAppointments from '../assets/patientIcon/My Appointments.png'
import MyMedications from '../assets/patientIcon/My Medications.png'
import RescheduledAppointment from '../assets/patientIcon/Rescheduled Appointment.png'
import ShareHealthData from '../assets/patientIcon/Share Health Data.png'
import MyRecords from '../assets/patientIcon/My Records.png'

// Doctor
import Schedules from '../assets/doctor/Schedules.png'
import Videos from '../assets/doctor/Videos.png'
import Photos from '../assets/doctor/Photos.png'
import CancelAppointments from '../assets/doctor/Cancel Appointments.png'


// import notificationMusic from '../assets/music/notification.mp3'

export const Images = {
    Logo,
    Doctors,
    Logo2,
    TextLogo,
    Profile,
    Google,
    Banner,
    Lab,
    Tickets,
    GooglePlay,
    AppStore,
    Payment,
    Doctor,
    Doctor2,
    BlackProfile,
    ProfileIcon,
    ChangePassword,
    Female,
    PrivacyTerms,

    // menu image
    CommunityClicked,
    CommunityUnClicked,
    FineCareClicked,
    FineCareUnClicked,
    PatientCommunityClicked,
    PatientCommunityUnClicked,

    //patient sidebar icon
    UpcomingScreenings,
    DocumentCentre,
    FindCareLeftSide,
    ForwardPrescriptions,
    MyAppointments,
    MyMedications,
    RescheduledAppointment,
    ShareHealthData,
    MyRecords,

    // payment
    Bkash,
    NotBkash,

    // Doctor
    Schedules,
    Videos,
    Photos,
    CancelAppointments,
} 
