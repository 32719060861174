import React from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { search } from 'react-icons-kit/feather'


const Index = () => {
    return (
        <div className="quick-items-container">

            {/* Show in Desktop  */}
            <div className="d-none d-lg-block">
               

                <div className="card border-0">
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                            <Icon icon={search} size={20} />
                                <input
                                    type="text"
                                    placeholder="QUICK SEARCH"
                                    className="form-control shadow-none"
                                />
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;