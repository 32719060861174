import React, { useState } from "react";
import './style.scss';
import ReactSwitch from 'react-switch';
// import { Images } from '../../utils/Images'
// import Icon from 'react-icons-kit'
// import {user} from 'react-icons-kit/ikons/user'
import Doctor from '../../../components/doctor/profile/doctorProfile'
import PatientSidebar from '../../../components/sidebar-patient/index'

const Index = () => {
    const [checked, setChecked] = useState(true);

    const handleChange = val => {
        setChecked(val)
    }
    return (
        <div className="find-care text-start">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-9">
                    <h4>Find Care</h4>
                    <div className="d-flex justify-content-around align-items-center flex-lg-row flex-md-row flex-column ">

                        <div className="col-sm-12 col-md-2 col-lg-2">
                            <button className="find-btu text-center">Specialization</button>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2">
                            <button className="find-btu"> District</button>
                        </div>

                        <div>
                            <div className="d-flex justify-content-end">
                                <label>Male Doctor</label>
                                <input type="checkbox" />
                            </div>
                            <div>
                                <label>Female Doctor</label>
                                <input type="checkbox" />
                            </div>
                        </div>

                        <div>
                            <div>
                                <label>Earliest Available</label>
                                <ReactSwitch
                                    checked={checked}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <label>Nearest Location</label>
                                <ReactSwitch
                                    checked={checked}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Doctor />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <Doctor />
                        </div>

                    </div>
                </div>
                <div className='col-md-3 col-lg-3 d-none d-lg-block '>
                <div className='card p-2 position-fixed top-2 end-0 overflow-auto' style={{ width: '310px', maxHeight: "85vh" }}>
                    <PatientSidebar  profile ={'doctor'}/>
                </div>
            </div>
            </div>

            
        </div>
    );
}
export default Index;