import React from 'react'
import './style.scss';
// import { Images } from '../../../utils/Images'
const Index = () => {

    return (
        <div className="allergies-reactions-table">
            <table className="table table-bordered">
                {/*  table-bordered */}
                <thead>
                    <tr className=''>
                        <th scope="col">Name</th>
                        <th scope="col">Dose</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Frequency</th>
                        <th scope="col">Special Instructions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>NAPIBRADINE</td>
                        <td>
                            <p className='m-0 p-0'>7.5MG 30 F.C TABS</p>
                            <p className='m-0 p-0'>7.5MG 30 F.C TABS</p>
                        </td>
                        <td>10 Days</td>
                        <td>morning, night, noon, evening</td>
                        <td> </td>
                    </tr>
                    <tr>
                        <td>NAPIBRADINE</td>
                        <td>
                            <p className='m-0 p-0'>7.5MG 30 F.C TABS</p>
                            <p className='m-0 p-0'>7.5MG 30 F.C TABS</p>
                        </td>
                        <td>10 Days</td>
                        <td>morning, night, noon, evening</td>
                        <td> </td>
                    </tr>
                    <tr>
                        <td>NAPIBRADINE</td>
                        <td>
                            <p className='m-0 p-0'>7.5MG 30 F.C TABS</p>
                            <p className='m-0 p-0'>7.5MG 30 F.C TABS</p>
                        </td>
                        <td>10 Days</td>
                        <td>morning, night, noon, evening</td>
                        <td> </td>
                    </tr>
                    <tr>
                        <td>NAPIBRADINE</td>
                        <td>
                            <p className='m-0 p-0'>7.5MG 30 F.C TABS</p>
                            <p className='m-0 p-0'>7.5MG 30 F.C TABS</p>
                        </td>
                        <td>10 Days</td>
                        <td>morning, night, noon, evening</td>
                        <td> </td>
                    </tr>
                    <tr>
                        <td>NAPIBRADINE</td>
                        <td>
                            <p className='m-0 p-0'>7.5MG 30 F.C TABS</p>
                            <p className='m-0 p-0'>7.5MG 30 F.C TABS</p>
                        </td>
                        <td>10 Days</td>
                        <td>morning, night, noon, evening</td>
                        <td> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Index;