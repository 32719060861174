/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import './style.scss';
// import { Images } from '../../utils/Images'
import Accordion from 'react-bootstrap/Accordion';

// import QuickISearch from '../../components/QuickISearch/Index'

const Index = () => {

    return (
        <div className="helpCenter">
            <div className="container">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Create a Mediserv account</Accordion.Header>
                        <Accordion.Body className="text-start">
                            <a className="nav-link active" href="#">Active</a>
                            <a className="nav-link" href="#">Link</a>
                            <a className="nav-link" href="#">Link</a>
                            <a className="nav-link disabled" href="#">Disabled</a>

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Create a Mediserv account</Accordion.Header>
                        <Accordion.Body className='p-4 '>


                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Why am i being asked to add moblie phone number to account?</Accordion.Header>
                        <Accordion.Body className='p-4 '>


                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Make a Strong Password</Accordion.Header>
                        <Accordion.Body className='p-4 '>


                        </Accordion.Body>
                    </Accordion.Item>



                </Accordion>
            </div>


        </div>
    );
}
export default Index;