import React, {useState} from "react";
import './style.scss';
import { Link } from 'react-router-dom'
// import { Images } from '../../utils/Images'
// import SearchComponent from '../../components/search/Index'
// import Icon from 'react-icons-kit'
// import {user} from 'react-icons-kit/ikons/user'
// import {ic_more_horiz_outline, ic_autorenew, ic_favorite_border} from 'react-icons-kit/md/'
// import {speech_bubble_1} from 'react-icons-kit/ikons/'
import BookingAppointment from '../../components/model/BookingPayment/BookingModel/index'
import AppointmentPayment from '../../components/model/BookingPayment/Payment/index'
import PaymentSuccessful from '../../components/model/BookingPayment/PaymentSuccessful'

const PatientSidebar = ({profile}) => {
    const [showBooking, setShowBooking] = useState(false)
    const [showBookingPayment, setShowBookingPayment] = useState(false)
    const [showPaymentSuccessful, setShowPaymentSuccessful] = useState(false)
    const handleBooking = () => {
        setShowBooking( true )
    }

    const handleBookingPayment = () => {
        setShowBooking( false )
        setShowBookingPayment( true )
    }

    const handlePaymentSuccessful =() =>{
        setShowBookingPayment( false )
        setShowPaymentSuccessful(true)
    }
    return (
        <div className="patient-doctor-sidebar mb-3" style={{border:'none'}}>
            {profile ==='doctor' ? <Link to="/practice-prescription" target="_blank" className="btn" rel="noopener noreferrer">
                Practice
            </Link> : <button className='btn'  onClick={handleBooking}>Booking Appointment</button>}

            <p className="text-start m-3" style={{ color: '#D9D9D9' }}>Sponsored</p>
            <div className="d-flex m-3 justify-content-between align-items-center">
                <div style={{ width: '90%', height: '150px', background: '#D9D9D9' }}></div>
                

            </div>
            
            <hr style={{ height: '2px' }} />
            <div><h4 className="fw-bold">Who to Follow</h4></div>
            <div className="d-flex m-3 mb-0 justify-content-between align-items-center">
                <div style={{ width: '50px', height: '50px', background: '#D9D9D9', borderRadius: '50px' }}></div>
                <div>
                    <h4 className="fw-bold mt-3 mb-0" style={{ fontSize: '12px' }}>Dr. Anik Dash</h4>
                    <p>mbbs fcps </p>
                </div>
                <button className="follow">Follow</button>
            </div>
            <button className="doctor mb-3">See More</button>

            <hr className="mt-0" style={{ height: '2px' }} />

            <div className="mb-4">
                <button className="doctor mb-1">Engage With Doctors</button>
                <button className="doctor">Aks A Question</button>
            </div>




            {
                    showBooking ?
                    <BookingAppointment
                        show={showBooking}
                        handleBookingPayment={handleBookingPayment}
                        onHide={() => setShowBooking(false)}
                    />:null
                }

                {
                    showBookingPayment ?
                    <AppointmentPayment
                        show={showBookingPayment}
                        handlePaymentSuccessful={handlePaymentSuccessful}
                        onHide={() => setShowBookingPayment(false)}
                    />:null
                }
        
            {
                showPaymentSuccessful ?
                    <PaymentSuccessful
                        show={showPaymentSuccessful}
                        // handlePaymentSuccessful={handlePaymentSuccessful}
                        onHide={() => setShowPaymentSuccessful(false)}
                    /> : null
            }
        </div>
    );
}
export default PatientSidebar;