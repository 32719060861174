import React from "react";
import './style.scss';
// import { Images } from '../../../utils/Images'
import PatientSidebar from '../../../components/sidebar-patient/index'
import ShareHealthData from '../../../components/ShareHealthData/Index'
import Icon from 'react-icons-kit'
import { ic_check_circle_outline } from 'react-icons-kit/md/'



const Index = () => {

    return (
        <div className="patient-community">
            {/* <div className="row"> */}

            <div className="col-sm-12 col-md-12 col-lg-9">

                <ShareHealthData />

                <div className="card mt-5 p-4 d-flex align-items-center justify-content-center">
                    <Icon icon={ic_check_circle_outline} size={35} style={{ color: '#75147B', marginTop: '20px' }} />
                    <h5>That's all for now</h5>
                    <button className="add-prescription-btn" style={{ marginBottom: '20px' }}>Back to Top</button>

                </div>
            </div>
            <div className='col-md-3 col-lg-3 d-none d-lg-block '>
                    <div className='card p-2 position-fixed top-2 end-0 overflow-auto' style={{ width: '310px', maxHeight: "85vh" }}>
                        
                        <PatientSidebar />
                    </div>
                </div>
            {/* </div> */}


        </div>
    );
}
export default Index;