import React, {useState} from "react";
import './style.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MyProfile from '../../../components/doctor/myProfile/myProfile/index'
import About from '../../../components/doctor/myProfile/About/index'
import Post from '../../../components/doctor/myProfile/Post/index'
import Reviews  from '../../../components/doctor/myProfile/Reviews/index'
import Patients  from '../../../components/doctor/myProfile/Patients/index'
import Photo  from '../../../components/doctor/myProfile/Photo/index'
import PatientSidebar from '../../../components/sidebar-patient/index'
// import { Images } from '../../utils/Images'
// import SearchComponent from '../../components/search/Index'
// import Icon from 'react-icons-kit'
// import {user} from 'react-icons-kit/ikons/user'
// import {ic_more_horiz_outline, ic_autorenew, ic_favorite_border} from 'react-icons-kit/md/'
// import {speech_bubble_1} from 'react-icons-kit/ikons/'

// import Ads from '../../components/ads/index'

const Index = () => {
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <div className="doctor-profile mt-3">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-9">
                    <MyProfile profile='self'/>

                    <div>
                        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                            <TabList>
                                <Tab>ABOUT</Tab>
                                <Tab>POSTS</Tab>
                                <Tab>REVIEWS</Tab>
                                <Tab>ANSWERS</Tab>
                                <Tab>PHOTOS</Tab>
                                <Tab>VIDEOS</Tab>
                                <Tab>PATIENTS</Tab>
                            </TabList>

                            {/* about */}
                            <TabPanel className='mt-3 mb-3'>
                                <About/>
                            </TabPanel>

                            {/* post */}
                            <TabPanel>
                                <Post/>
                            </TabPanel>
                            
                            {/* Reviews */}
                            <TabPanel>
                                <Reviews/>
                            </TabPanel>

                            {/* answer */}
                            <TabPanel>
                                <Post/>
                            </TabPanel>

                            {/* Photo */}
                            <TabPanel>
                                <Photo/>
                            </TabPanel>

                            {/* VIDEOS */}
                            <TabPanel>
                                <Photo/>
                            </TabPanel>

                            {/* Patients */}
                            <TabPanel>
                                <Patients/>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
                <div className='col-md-3 col-lg-3 d-none d-lg-block '>
                <div className='card p-2 position-fixed top-2 end-0 overflow-auto' style={{ width: '310px', maxHeight: "85vh" }}>
                    
                    <PatientSidebar profile ={'doctor'}/>
                </div>
            </div>
            </div>
          
        </div>
    );
}
export default Index;