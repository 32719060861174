import React from 'react'
import './style.scss'
import { Images } from '../../../utils/Images'
import Icon from 'react-icons-kit'
import {ic_visibility_outline, ic_share, ic_delete, ic_local_printshop_outline} from 'react-icons-kit/md/'
import {edit} from 'react-icons-kit/feather/'
const Index = ({document}) => {

    return (
        <div className="forward-prescription-table">
            {/* <div className='card'> */}
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Patient Name</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>16 Nov 2023</td>
                            <td>Md. Rahim</td>
                            <td>
                                <div className='d-flex'>
                                    <img className='forward-img' src={Images.Doctor2} alt='' />
                                    <div>
                                        <p className='m-0'>Dr. Rumana Khan</p>
                                        <p className='forward-sub-p m-0'>Dental</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'> 
                                    <button className='forward-view'><Icon icon={ic_visibility_outline}/> View</button>
                                    {
                                        document?
                                    <button className='forward-share'><Icon icon={ic_local_printshop_outline}/> Print</button>:
                                    <button className='forward-share'><Icon icon={ic_share}/> Share</button>
                                    }
                                    <button className='forward-edit'><Icon icon={edit}/> Edit</button>
                                    <button className='forward-delete'><Icon icon={ic_delete}/> Delete</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            {/* </div> */}
        </div>
    );
};

export default Index;