import React from "react";
import './style.scss';
// import { Images } from '../../../utils/Images'
import DoctorSidebar from '../../../components/sidebar-patient/index'
import DoctorCommunity from '../../../components/community/index'
// import { Link } from "react-router-dom";
// import Icon from 'react-icons-kit'
// import {user} from 'react-icons-kit/ikons/user'
// import {ic_more_horiz_outline, ic_autorenew, ic_favorite_border} from 'react-icons-kit/md/'
// import {speech_bubble_1} from 'react-icons-kit/ikons/'



const Index = () => {

    return (
        <div className="doctor-community">
            {/* <div className="row"> */}
               
                <div className="col-sm-12 col-md-9 col-lg-9">
                    
                     <DoctorCommunity
                        community ='doctor'
                     />
                </div>
                <div className="card d-none d-lg-block position-fixed end-0 overflow-auto ms-lg-2" style={{width:'300px', maxHeight: "89vh"}}>
                
                <DoctorSidebar/>
                </div>
            {/* </div> */}
        </div>
    );
}
export default Index;