/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'//useEffect, useCallback,
import './style.scss'
import Icon from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { Images } from '../../../../utils/Images'
import {ic_verified_user} from 'react-icons-kit/md/'
// import Appointment from '../BookAppointment'
const Index = (props) => {

    const handlePaymentSuccessfulData =()=>{
        props.handlePaymentSuccessful()
    }


    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-appointment"
            >
                {/* <Modal.Header>
                    <Modal.Title>{props.value ? props.value.name : 'New Traffic'}</Modal.Title>
                    <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button>
                </Modal.Header> */}
                <Modal.Body >

                    <div className='d-flex align-items-center' >

                        <div className='col-sm-12  col-md-12'>

                            <div className='card-body'>
                                <div className="appointment">

                                    <div className="card-appointment mt-3 mb-3 mr-3 text-start">
                                        {/* <button>BOOK APPOINMENT</button> */}
                                        <h3 style={{ color: '#7D7C7D' }}>You are booking Dr. Rahim's Appointment</h3>
                                        <h2 className='mt-5 mb-4' style={{ color: '#7D7C7D', fontSize:'20px' }}>Select Payment Method</h2>
                                        <div className='d-flex justify-content-center'>
                                            <button><img src={Images.Bkash} alt=''/></button>
                                            <button><img src={Images.NotBkash} alt=''/></button>
                                        </div>
                                        <h4 className='mt-5 text-center' style={{color:'#00BD2B'}}><Icon icon={ic_verified_user} size={25}/>Secure Payment System </h4>
                                        <h6>You are Paying:</h6>
                                        <h6>Invoice Number:</h6>
                                        <form >


                                            <button className='btn-payment' type="submit" style={{ width: "100%" }} 
                                            onClick={handlePaymentSuccessfulData}>Continue</button>


                                        </form>
                                        
                                        <div className="col-12 text-center mb-0"><p style={{fontSize:'12px'}}>By clicking on Continue you agree to <a href='#'>Payment Terms</a> & <a href='#'>Refund Policy</a> </p></div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
