import React, {useState} from 'react'
import './style.scss';
import Icon from 'react-icons-kit'
import {Images} from '../../utils/Images';
import Assistant from '../auth/models/assistant/assistant'
import Receive from '../auth/models/assistant/receive'

import {
    ic_double_arrow,
    ic_email,
    ic_facebook,
    ic_location_on,
    ic_phone_outline,
    ic_sports_basketball_outline,
    ic_wh
} from 'react-icons-kit/md/'

const Index = () => {
    const [show, setShow] = useState({status: false})
    const [receive, setReceive] = useState({status: false})

    const handleAssistant = data => {
        setShow({status: true})
    }

    const handleReceive = data => {
        setShow({status: false})
        setReceive({status: true})
    }
    return (
        <div className='footer'>
            <div className='container'>
                <div className='row pt-5'>
                    <div className='col-sm-12 col-md-6 col-lg-3'>
                        <img src={Images.Logo2} alt=''/>
                        <div><img src={Images.Profile} alt=''/></div>

                        <p className='text-start mt-5' style={{color: '#fff'}}>MediServ provides an inexpensive solution to be in charge of your own care in an easy, secure, and feature-rich environment.</p>
                        <p className='text-start mt-4 p-1' style={{color: '#fff'}}><Icon icon={ic_facebook}/> <Icon
                            icon={ic_sports_basketball_outline}/></p>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3 text-start' style={{color: '#fff'}}>
                        <h4 style={{color: '#fff'}}>For Patients</h4>
                        <p className='mt-4 mb-1'><Icon icon={ic_double_arrow}/>Search for Doctor</p>
                        <p className='mb-1'><Icon icon={ic_double_arrow}/>Login</p>
                        <p className='mb-1'><Icon icon={ic_double_arrow}/>Register</p>
                        <p className='mb-1'><Icon icon={ic_double_arrow}/>Booking</p>
                        <p className='mb-1'><Icon icon={ic_double_arrow}/>Patient Dash Board</p>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3 text-start' style={{color: '#fff'}}>
                        <h4 style={{color: '#fff'}}>For Doctors</h4>
                        <p className='mt-4 mb-1'><Icon icon={ic_double_arrow}/>Appointments</p>
                        <p className='mb-1'><Icon icon={ic_double_arrow}/>Chat</p>
                        <p className='mb-1'><Icon icon={ic_double_arrow}/>Login</p>
                        <p className='mb-1'><Icon icon={ic_double_arrow}/>Register</p>
                        <p className='mb-1'><Icon icon={ic_double_arrow}/>Doctor Dashdoard</p>

                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-3 text-start' style={{color: '#fff'}}>
                        <h4 style={{color: '#fff'}}>Contact Us</h4>
                        <p className='mt-4 mb-1'><Icon icon={ic_location_on}/>Dhaka Office: H-691, R-12, MOHAMMADPUR,
                            ADABOR, DHAKA-1207, BANGLADESH</p>
                        <p className='mt-4 mb-1'><Icon icon={ic_location_on}/>B.Baria Office : H-1101, North Side of
                            Loknath Tank, West Paik Para, Brahmanbaria 3400</p>
                        <p className='mb-1'><Icon icon={ic_phone_outline}/>+8801828015208</p>
                        <p className='mb-1' type='button' onClick={() => handleAssistant()}><Icon icon={ic_email}/>EMAIL
                            US </p>

                    </div>
                </div>
            </div>
            {show.status ?
                <Assistant
                    show={show.status}
                    handleReceive={handleReceive}
                    onHide={() => setShow({status: false})}
                />
                : null}

            {receive.status ?
                <Receive
                    show={receive.status}
                    onHide={() => setReceive({status: false})}
                />
                : null}
            <div className='p-3'><p style={{color: '#fff'}}>Copyright &copy; 2024 Mediserv Ltd. All right reserved</p>
            </div>
        </div>
    );
}
export default Index;