/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import './style.scss'
import Icon from 'react-icons-kit'
import {ic_check_circle} from 'react-icons-kit/md/'



const Index = () => {
    return (
        <div className="profile-not-verified">
            
            <div className="card">
                <div className="card shadow-none" style={{border:'1px solid #dee2e6'}}>
                <Icon  icon={ic_check_circle} size={50} style={{color:'#75157B'}}/>
                <p className="p-0 m-0 fw-bolder">You applied for verification</p>
                <p className="p-0 m-0 fw-bolder">Please Wait while we verify your account</p>
                </div>
                
            </div>
        </div>
    )
}
export default Index;