import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import { useForm } from 'react-hook-form'
import { useNavigate } from "react-router-dom";
const Index = () => {
    const navigate = useNavigate();
    const { register, } = useForm();//handleSubmit, formState: { errors }, 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            navigate(`/doctor/change-password`);
        
        } catch (error) {
 
        }
    }
    return (

        <div className="custom-otp">
            <p className='m-0 p-0'>
                    <span style={{ color: '#48B250' }}>Warning: </span>
                    Unauthorized Entry to this website is subject to Criminal Offence.
                </p>
            <div className="d-flex justify-content-evenly align-items-center text-center">
                
                

                <div className="col-sm-12 col-md-5 col-lg-5">
                
                <div className="card p-5 mt-5 mb-5 text-center">
                   <div className='otp-text'>OTP</div>
                    
                <form onSubmit={onSubmit}>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                {/* <p className='p-0 m-0'>Entre</p> */}
                                <div className="form-group mb-2 mt-2">

                                    <input
                                        type="text"
                                        name="otp"
                                        style={{ textAlign: 'center' }}
                                        className="form-control otp-input shadow-none"
                                        placeholder="Entre"
                                        {...register("otp", {
                                            required: "otp is required",

                                        })}
                                    />
                                </div>
                            </div>                            
                        </form>
                        
                </div>
                <p className='p-0 m-0 fw-bold'>mediserv.ai - বাংলাদেশের স্বাস্থ্য সেবায় নিয়োজিত।</p>
                <p>MediServ Private Limited. All rights reserved.</p>
            </div>
                
            </div>
        </div>
       
    );
}
export default Index;

