/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import ActiveAllergies from '../../../components/table/practice/active-allergies'
import VaccinationRecord from '../../../components/table/practice/vaccination-record'
import RightEye from '../../../components/table/practice/RightLens'
import LeftEye from '../../../components/table/practice/LeftLens'
import LensProp from '../../../components/model/Lens/index'
import './style.scss';
import Select from 'react-select';
import Icon from 'react-icons-kit'
import { circle_left } from 'react-icons-kit/ikons/'

const Index = () => {
    const { register, } = useForm();//handleSubmit, formState: { errors },
    const [show, setShow] = useState(false)
    const [isClearable,] = useState(true); //setIsClearable
    const [isSearchable,] = useState(true); //setIsSearchable
    const [isDisabled,] = useState(false); //setIsDisabled
    const [isLoading,] = useState(false); //setIsLoading
    const [isRtl,] = useState(false); //setIsRtl

    const handleLens = () => {
        setShow(true)
    }
    return (
        <div className='practice-prescription-show'>
            <div className='d-flex'>
                <div className='col-sm-12 col-md-10 col-lg-10'>
                    <div className='card ms-5 me-5 p-2'>
                        <div className='row align-items-center'>
                            <div className='col-sm-12 col-md-6 col-lg-3'>
                                <h2>Akkas Ali</h2>
                                <h5>70 years old</h5>
                                <p>Male</p>
                            </div>

                            <div className='col-sm-12 col-md-6 col-lg-3'>
                                <ActiveAllergies />
                            </div>

                            <div className='col-sm-12 col-md-6 col-lg-3'>
                                <h6 className='text-start'>Vaccination Record <span><a href='#'> See All</a></span> </h6>
                                <VaccinationRecord />
                            </div>

                            <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div><button className='view-record' style={{ background: '#49A0AE' }}>View Full Record</button></div>
                                <div><button className='view-record' style={{ background: '#FFC7EA' }}>Skip Patient</button></div>
                            </div>

                        </div>

                        <hr />

                        <p className='m-0 p-0'>Al Maya will Assist you in writing this Prescription. Just give her some indications.</p>
                        <p className='mt-0 mb-4 p-0'>Maya is an Assistive Al. Give her some time to learn your pattern<a href='#'>See How</a></p>

                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div className="form-group mb-2">
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control shadow-none form-control-center"
                                        placeholder="Select Chief Complaint"
                                        {...register("email", {
                                            required: "Select Chief Complaint is required",

                                        })}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-2'>
                                <p className='m-0 p-0'>Write</p>
                                <p className='m-0 p-0'>Select or Edit</p>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div className="form-group mb-2">
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control shadow-none form-control-center"
                                        placeholder="Indications"
                                        {...register("email", {
                                            required: "Indications is required",

                                        })}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-3'>
                                <div className="form-group mb-2">
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control shadow-none form-control-center"
                                        placeholder="Drug Name"
                                        {...register("email", {
                                            required: "Drug Name is required",

                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-9  mt-3'>
                                <div className='col-sm-12 col-md-12 col-lg-12 d-flex  justify-content-between'>
                                    <h3>Drugs Added(2)</h3>
                                    <p className='m-0 p-0'><a href='#'>Jump to Tests Added</a></p>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-12 p-2 card' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <div className='row p-2'>
                                        <div className='col-sm-12 col-md-4 col-lg-4'><h3>Drug Name</h3></div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'><h3>Dose</h3></div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'><h3>Duration</h3></div>
                                    </div>

                                    <hr />

                                    <div className='col-sm-12 col-md-12 col-lg-12 p-2 card'>
                                        <div className='col-sm-12 col-md-12 col-lg-12 d-flex  justify-content-between p-2'>
                                            <h3>1</h3>
                                            <button className='delete'>Delete</button>
                                        </div>

                                        <div className='row p-2'>
                                            <div className='col-sm-12 col-md-4 col-lg-4 m-2 card'><h3>Napa</h3></div>
                                            <div className='col-sm-12 col-md-4 col-lg-4 m-2 card'>
                                                <h3>7.5MG 30 F.C TABS</h3>
                                                <h3>7.5MG 30 F.C TABS</h3>
                                            </div>
                                            <div className='col-sm-12 col-md-4 col-lg-3 m-1'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='card me-3 p-2'><p className='m-0 p-1'>365</p></div>
                                                    <h3>Days</h3>
                                                </div>
                                                <div>
                                                    <div className="d-flex m-0 fw-bold">
                                                        <input type="checkbox" id="btncheck1" autocomplete="off" />
                                                        <label className="btn fw-bold mb-0" for="btncheck1">Continues</label>
                                                    </div>
                                                </div>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="SPECIALIST"
                                                    // defaultValue={district[0]}
                                                    isDisabled={isDisabled}
                                                    isLoading={isLoading}
                                                    isClearable={isClearable}
                                                    isRtl={isRtl}
                                                    isSearchable={isSearchable}
                                                    name="color"
                                                    placeholder='Select Frequency'
                                                    styles={customStyles}
                                                // options={district}
                                                />
                                            </div>
                                        </div>
                                        <div class="form-floating">
                                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                                            <label for="floatingTextarea2">Write Special Instructions(Optional)</label>
                                        </div>
                                    </div>

                                    <div className='col-sm-12 col-md-12 col-lg-12 p-2 card mt-3'>
                                        <div className='col-sm-12 col-md-12 col-lg-12 d-flex  justify-content-between p-2'>
                                            <h3>1</h3>
                                            <button className='delete'>Delete</button>
                                        </div>

                                        <div className='row p-2'>
                                            <div className='col-sm-12 col-md-4 col-lg-4 m-2 card'><h3>Napa</h3></div>
                                            <div className='col-sm-12 col-md-4 col-lg-4 m-2 card'>
                                                <h3>7.5MG 30 F.C TABS</h3>
                                                <h3>7.5MG 30 F.C TABS</h3>
                                            </div>
                                            <div className='col-sm-12 col-md-4 col-lg-3 m-1'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='card me-3 p-2'><p className='m-0 p-1'>365</p></div>
                                                    <h3>Days</h3>
                                                </div>
                                                <div>
                                                    <div className="d-flex m-0 fw-bold">
                                                        <input type="checkbox" id="btncheck1" autocomplete="off" />
                                                        <label className="btn fw-bold mb-0" for="btncheck1">Continues</label>
                                                    </div>
                                                </div>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="SPECIALIST"
                                                    // defaultValue={district[0]}
                                                    isDisabled={isDisabled}
                                                    isLoading={isLoading}
                                                    isClearable={isClearable}
                                                    isRtl={isRtl}
                                                    isSearchable={isSearchable}
                                                    name="color"
                                                    placeholder='Select Frequency'
                                                    styles={customStyles}
                                                // options={district}
                                                />
                                            </div>
                                        </div>
                                        <div class="form-floating">
                                            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                                            <label for="floatingTextarea2">Write Special Instructions(Optional)</label>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between mt-3'>
                                    <h3>Tests Added(2)</h3>
                                    <p className='m-0 p-0'><a href='#'>Jump to Drugs Added</a></p>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-12 card'>
                                    <div className='row p-2'>
                                        <div className='col-sm-12 col-md-4 col-lg-4'><h3>Test Name</h3></div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'><h3>Referred To</h3></div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'><h3>Indications</h3></div>
                                    </div>

                                    <hr />

                                    <div className='col-sm-12 col-md-12 col-lg-12 '>


                                        <div className='row p-2 align-items-center'>
                                            <div className='col-sm-12 col-md-4 col-lg-4 m-2 card'><h3>Select Test</h3></div>
                                            <div className='col-sm-12 col-md-4 col-lg-4 m-2 card'>
                                                <h3>Bangabandhu Medical College, Paik Para, Brahmanbaria</h3>
                                            </div>
                                            <div className='col-sm-12 col-md-4 col-lg-3 d-flex justify-content-center m-1'>
                                                <button className='view-add' style={{ background: '#49A0AE' }}>Add</button>


                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-sm-12 col-md-12 col-lg-12 '>


                                        <div className='row p-2 align-items-center'>
                                            <div className='col-sm-12 col-md-4 col-lg-4 m-2 text-start'>
                                                <h3>1.</h3>
                                                <h3 className='card p-1'>Select Test</h3>
                                                <button className='delete'>Delete</button>
                                            </div>
                                            <div className='col-sm-12 col-md-4 col-lg-4 m-2 card'>
                                                <h3>Bangabandhu Medical College, Paik Para, Brahmanbaria</h3>
                                            </div>
                                            <div className='col-sm-12 col-md-4 col-lg-3 m-1'>
                                                <div className='col-sm-12 col-md-4 col-lg-4'>
                                                    <div class="form-floating">
                                                        <textarea class="form-control" placeholder="Write if you have any Indications(Optional)" id="floatingTextarea3" style={{ height: "100px", width: '220px', resize: 'none' }}></textarea>
                                                        <label for="floatingTextarea3">Write if you have any</label>
                                                        {/* <label for="floatingTextarea2">Write if you have any Indications(Optional)</label> */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-sm-12 col-md-4 col-lg-3 '>
                                <div className=' ms-3 card pt-2' style={{ height: '300px' }}><h3>Suggested Drug and Dose</h3></div>
                                <div><button className='view-record mt-5' style={{ background: '#49A0AE' }}>Print</button></div>
                                <p>OR</p>
                                <div><button className='view-record' style={{ background: '#FFC7EA' }}>Save and End Session</button></div>
                                <p className='m-0 p-0'>It will automatically Take
                                    you to the next patient.We
                                    will send the prescription
                                    via SMS right away.
                                </p>
                                <p className='m-0 p-0'><a href='#'>terms of use</a></p>
                            </div>
                        </div>



                        <div className='col-sm-12 col-md-12 col-lg-12 mt-3'>
                            <div class="form-floating">
                                <textarea class="form-control" placeholder="Write if you have any Indications(Optional)" id="floatingTextarea3" style={{ height: "100px", resize: 'none' }}></textarea>

                                <label for="floatingTextarea2">Write extra advices(Optional)</label>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='text-start mt-2'>
                        <h2>Glasses Prescription</h2>
                        <div className='d-flex m-3 mt-0'>
                            <div className='text-center  m-3 mt-0'>
                                <div className='card p-4 '> 
                                <p>eye image</p>
                                <button className='view-record' style={{ background: '#49A0AE' }} onClick={handleLens}>Lens Prescription</button></div>

                                </div>
                                
                            <div className='col-6'>
                            <RightEye />
                            </div>
                            </div>
                    </div>
                    <div className='text-start mt-2'>
                        <h2>Contact Lens Prescription</h2>
                        <div className='d-flex m-3 mt-0'>
                            <div className='text-center  m-3 mt-0'>
                                <div className='card p-4 '> 
                                <p>eye image</p>
                                <button className='view-record' style={{ background: '#49A0AE' }} onClick={handleLens}>Lens Prescription</button></div>

                                </div>
                                
                            <div className='col-6'>
                            <LeftEye />
                            </div>
                            </div>
                    </div>
                   
                        </div>
                    </div>

                    
                </div>

                <div className='col-sm-12 col-md-2 col-lg-2'>
                    <div className='card p-2'>
                        <div className='d-flex'>
                            <div className='col-sm-12 col-md-6 col-lg-6'><h1>60</h1></div>
                            <div className='col-sm-12 col-md-6 col-lg-6'><p><span>Patients Pending Today</span></p></div>
                        </div>


                        <Select
                            className="basic-single"
                            classNamePrefix="SPECIALIST"
                            // defaultValue={district[0]}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                            isClearable={isClearable}
                            isRtl={isRtl}
                            isSearchable={isSearchable}
                            name="color"
                            placeholder="Select Card"
                            styles={customStyles}
                        // options={district}
                        />

                        <div className='col-sm-12 col-md-12 col-lg-12 mt-1'>
                            <div className="form-group text-center mb-2">
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control shadow-none form-control-center"
                                    placeholder="Monday, 2PM - 5PM"
                                    {...register("email", {
                                        required: "Indications is required",

                                    })}
                                />
                            </div>
                        </div>

                        <div><button className='view-record' style={{ background: '#FFC7EA', width: '100%' }}>Load Patients</button></div>
                        <div><Icon icon={circle_left} /><h6>Todays Appointments</h6></div>

                    </div>

                    <div><button className='view-record mt-5' style={{ background: '#49A0AE' }}>ADD PATIENT</button></div>
                    <div className='card p-2 text-start'>
                        <h5>Current Drugs</h5>
                        <h3>1.Napa</h3>
                        <p className='ms-4'> 7.5MG TABS</p>
                        <h3>2.Napa</h3>
                        <p className='ms-4'> 7.5MG TABS</p>
                        <p className='m-0 text-center p-0'><a href='#'>See more</a></p>

                    </div>

                    <div className='card mt-5 p-2 text-center'>
                        <p>eye image</p>
                        <div><button className='view-record' style={{ background: '#49A0AE' }} onClick={handleLens}>Lens Prescription</button></div>

                    </div>

                    <div className='col-12'>
                        <div className='mt-5'>
                            <div className='d-flex'>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control shadow-none form-control-center"
                                            placeholder="blood pressure"
                                            {...register("email", {
                                                required: "Indications is required",

                                            })}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control shadow-none form-control-center"
                                            placeholder="pulse Rate"
                                            {...register("email", {
                                                required: "Indications is required",

                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control shadow-none form-control-center"
                                            placeholder="55"
                                            {...register("email", {
                                                required: "Indications is required",

                                            })}
                                        />
                                    </div>
                                </div>

                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control shadow-none form-control-center"
                                            placeholder="Height"
                                            {...register("email", {
                                                required: "Indications is required",

                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control shadow-none form-control-center"
                                            placeholder="Temp"
                                            {...register("email", {
                                                required: "Indications is required",

                                            })}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="email"
                                            className="form-control shadow-none form-control-center"
                                            placeholder="SpO2"
                                            {...register("email", {
                                                required: "Indications is required",

                                            })}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className='col-sm-6 col-md-6 col-lg-6'>
                                <div className="form-group text-center mb-2">
                                    <input
                                        type="text"
                                        name="email"
                                        className="form-control shadow-none form-control-center"
                                        placeholder="RPG"
                                        {...register("email", {
                                            required: "Indications is required",

                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>BMI:0.0kg/me</div>

                    <div className='card p-2 mt-5'>
                        <div><h6>You can Refer this patient to other Doctors</h6></div>

                        <div className='col-sm-12 col-md-12 col-lg-12 mt-1'>
                            <div className="form-group text-center mb-2">
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control shadow-none form-control-center"
                                    placeholder="Specialization"
                                    {...register("email", {
                                        required: "Indications is required",

                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 mt-1'>
                            <div className="form-group text-center mb-2">
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control shadow-none form-control-center"
                                    placeholder="Doctor Name"
                                    {...register("email", {
                                        required: "Indications is required",

                                    })}
                                />
                            </div>
                        </div>
                        <div><button className='view-record' style={{ background: '#FFC7EA', width: '100%' }}>Refer Patients</button></div>

                    </div>
                </div>
            </div>
           
            {show ?
                <LensProp
                    show={show}
                    // handleReSchedule={handleLens}
                    onHide={() => setShow(false)}
                />
                : null}
        </div>
    )
}

const customStyles = {
    control: (styles) => ({
        ...styles,
        //   borderBottom: 'none',
        backgroundColor: 'white', // Adjust the background color as needed
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#000', // Change the color of the placeholder text
    }),
};

export default Index;