import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import './style.scss';
import Select from 'react-select';
import Icon from 'react-icons-kit'
import { circle_left } from 'react-icons-kit/ikons/'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AppointmentProfile from '../../../components/doctor/todaysAppointments/appointmentProfile/index'
import PatientSidebar from '../../../components/sidebar-patient/index'
const Index = () => {
    const { register, } = useForm();//handleSubmit, formState: { errors },
    const [isClearable,] = useState(true); //setIsClearable
    const [isSearchable,] = useState(true); //setIsSearchable
    const [isDisabled,] = useState(false); //setIsDisabled
    const [isLoading,] = useState(false); //setIsLoading
    const [isRtl,] = useState(false); //setIsRtl
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <div className="patient-profile">
            {/* <div className="row"> */}
            <div className='col-sm-12 card col-md-2 col-lg-2 me-lg-3'><PatientSidebar /></div>
            <div className="col-sm-12 col-md-8 col-lg-8">
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                            <TabList>
                                <Tab>General</Tab>
                                <Tab>Skipped</Tab>
                                
                            </TabList>

                            {/* about */}
                            <TabPanel className='mt-3 mb-3'>
                                {/* <About/> */}
                            </TabPanel>

                            {/* post */}
                            <TabPanel>
                                {/* <Post/> */}
                            </TabPanel>
                            


                            
                        </Tabs>
                <div className="d-flex justify-content-around">
                    <div className="col-sm-12 col-md-5 col-lg-5"><AppointmentProfile doctor='doctor' /></div>
                    <div className="col-sm-12 col-md-5 col-lg-5"><AppointmentProfile doctor='doctor' /></div>
                </div>
            </div>
            <div className=''>
                <div className='card p-2'>
                    <div className='d-flex'>
                        <div className='col-sm-12 col-md-6 col-lg-6'><h1 className='fw-bolder' style={{ color: '#00BD13' }}>60</h1></div>
                        <div className='col-sm-12 col-md-6 col-lg-6'><p><span>Patients Pending Today</span></p></div>
                    </div>


                    <Select
                        className="basic-single"
                        classNamePrefix="SPECIALIST"
                        // defaultValue={district[0]}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        isClearable={isClearable}
                        isRtl={isRtl}
                        isSearchable={isSearchable}
                        name="color"
                        placeholder="Select Card"
                        styles={customStyles}
                    // options={district}
                    />

                    <div className='col-sm-12 col-md-12 col-lg-12 mt-1'>
                        <div className="form-group text-center mb-2">
                            <input
                                type="text"
                                name="email"
                                className="form-control shadow-none form-control-center"
                                placeholder="Monday, 2PM - 5PM"
                                {...register("email", {
                                    required: "Indications is required",

                                })}
                            />
                        </div>
                    </div>

                    <div><button className='view-record' style={{ background: '#FFC7EA', width: '100%' }}>Load Patients</button></div>
                    <div><Icon icon={circle_left} /><h6>Todays Appointments</h6></div>

                </div>

                <div><button className='view-record mt-5' style={{ background: '#49A0AE' }}>ADD PATIENT</button></div>
            </div>
            {/* </div> */}

        </div>
    )
}
const customStyles = {
    control: (styles) => ({
        ...styles,
        //   borderBottom: 'none',
        backgroundColor: 'white', // Adjust the background color as needed
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#000', // Change the color of the placeholder text
    }),
};
export default Index;