import React from "react";
import './style.scss'
// import Icon from 'react-icons-kit'
// import { useForm } from 'react-hook-form'
// import {ic_highlight_off_twotone} from 'react-icons-kit/md/'

const Index = () =>{
    return(
        <div className="medication-card text-start">
            <h5 className="text-capitalize fw-bold p-0 m-0" style={{color:'#184A79'}}>gemfibrozil 600 MG tablet</h5>
            <p className="text-capitalize fw-medium m-0 p-1">take 1 tablet by mouth twice daily</p>
            <hr className="p-0 m-0" style={{width:'40%', color:'#0f0f11', background:'#0f0f11', height:'2px', border:'none'}}/>
            <p className="fw-medium p-0 m-0">Prescription Details</p>
            <p className="p-0 m-0">Prescribed November 28,2023</p>
            <p className="p-0 m-0">Approved by <spn>Sanjay Kunapuli</spn></p>
            <p className="p-0 m-0">Prescription number 7853481</p>
        </div>
    );
}

export default Index;