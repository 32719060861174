import React from 'react'
import './style.scss';
// import { Images } from '../../../utils/Images'
const Index = () => {

    return (
        <div className="allergies-reactions-table">
            <table className="table table-bordered">
                {/*  table-bordered */}
                <thead>
                    <tr className=''>
                        <th className='p-4' scope="col">Name</th>
                        <th className='p-4' scope="col">Bone Marrow Tests</th>
                        <th className='p-4' scope="col">Bone Marrow Tests</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Reference</td>
                        <td>
                            <p className='m-0 p-0'>Bangabandhu Medical College</p>
                            <p className='m-0 p-0'>Paik Para,Dhaka</p>
                        </td>
                        <td> </td>
                    </tr>
                    <tr>
                        <td>Special Indications</td>
                        <td>
                            <p className='m-0 p-0'>None</p>
                        </td>
                        <td><p className='m-0 p-0'>None</p> <p></p></td>
                    </tr>                   
                </tbody>
            </table>
        </div>
    );
};

export default Index;