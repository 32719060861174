import React from "react";
import './style.scss';
// import { Images } from '../../utils/Images'
// import SearchComponent from '../../components/search/Index'
// import Icon from 'react-icons-kit'
// import {user} from 'react-icons-kit/ikons/user'
// import {ic_more_horiz_outline, ic_autorenew, ic_favorite_border} from 'react-icons-kit/md/'
// import {speech_bubble_1} from 'react-icons-kit/ikons/'


const Ads = () => {
    return (
        <div className="card mt-3 mb-3" style={{border:'none'}}>
            <p className="text-start m-3" style={{ color: '#D9D9D9' }}>Sponsored</p>
            <div className="d-flex m-3 justify-content-between align-items-center">
                <div style={{ width: '90px', height: '150px', background: '#D9D9D9' }}></div>
                <div>
                    <h4 className="fw-bold">ADS</h4>
                    <p className="mt-3">ADS</p>
                </div>

            </div>
            <hr style={{ height: '2px' }} />

            <div className="d-flex m-3 justify-content-between align-items-center">
                <div style={{ width: '90px', height: '150px', background: '#D9D9D9' }}></div>
                <div>
                    <h4 className="fw-bold">ADS</h4>
                    <p className="mt-3">ADS</p>
                </div>

            </div>
            <hr style={{ height: '2px' }} />
            <div><h4 className="fw-bold">Who to Follow</h4></div>
            <div className="d-flex m-3 mb-0 justify-content-between align-items-center">
                <div style={{ width: '50px', height: '50px', background: '#D9D9D9', borderRadius: '50px' }}></div>
                <div>
                    <h4 className="fw-bold mt-3 mb-0" style={{ fontSize: '12px' }}>Dr. Anik Dash</h4>
                    <p>mbbs fcps </p>
                </div>
                <button className="follow">Follow</button>
            </div>
            <hr className="mt-0" style={{ height: '2px' }} />

            <div className="mb-4">
                <button className="doctor mb-1">Engage With Doctors</button>
                <button className="doctor">Ask A Question</button>
            </div>
        </div>
    );
}
export default Ads;