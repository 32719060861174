import React from 'react';
import QRCode from 'react-qr-code';

const MyQrCodeComponent = ({ data }) => {
  return (
    <div className='mt-2 mb-2'>
      <QRCode style={{width:'200px', height:'140px'}} value={data} />
    </div>
  );
};

export default MyQrCodeComponent;