import React from "react";
import './style.scss';
import { Images } from '../../utils/Images'
// import SearchComponent from '../../components/search/Index'
import Icon from 'react-icons-kit'
import { user } from 'react-icons-kit/ikons/user'
import { ic_more_horiz_outline, ic_autorenew, ic_favorite_border } from 'react-icons-kit/md/'
import { speech_bubble_1 } from 'react-icons-kit/ikons/'
import { useForm } from 'react-hook-form'
import Ads from '../../components/ads/index'
import Blog from '../../components/blog/index'

const Index = () => {
    const { register, handleSubmit, formState: { errors }, } = useForm()
    // const placeholderText = (
    //     <span>
    //         Search <img src={Images.Female} alt="Female" />
    //     </span>
    // );
    const onSubmit = (data) => {
        console.log(data);
    }
    return (
        <div className="community">
            {/* <div className="row"> */}
            
            <div className="col-sm-12 col-md-8 col-lg-8  me-lg-2 ">
                <div className="card mt-3 mb-3 ">
                <Blog/>

                </div>
                <div className="card mt-3 mb-3">
                    <div className="d-flex justify-content-between">
                        <div className="col-sm-12 col-md-5 col-lg-5">
                            <div className="d-flex">
                                <img className="m-3" src={Images.Doctor2} alt="" />
                                <div className="d-flex flex-column align-items-start m-3">
                                    <h3>Dr. Iman Khan</h3>
                                    <h3 style={{ color: "#A0A0A0" }}>MBBS FCPS</h3>
                                </div>

                            </div>
                        </div>
                        <Icon style={{ color: '#E1E1E1' }} icon={ic_more_horiz_outline} size={50} />
                    </div>

                    <div className="mt-4">
                        <h5 className="text-start p-4 fw-bold">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type
                            specimen book. It has survived not only five centuries, but also the leap into
                            electronic typesetting, remaining essentially unchanged ...See More</h5>
                    </div>

                    <div style={{ height: '200px', background: '#D9D9D9' }}>

                    </div>
                    <div>
                        <hr style={{ height: '2px' }} />
                        <h6 className="text-start m-3"><Icon icon={ic_favorite_border} /> 2.1k <Icon icon={ic_autorenew} /> 2.1k <Icon icon={speech_bubble_1} /> 2.1k</h6>

                        <div className="text-start d-flex flex-column align-items-start m-3">
                            <h3><Icon className="mr-4" icon={user} size={25} /> Md. Hanif</h3>
                            <p style={{ marginLeft: '40px' }}>when an unknown printer took a galley of type and scrambled it to make a
                                type specimen book. It has survived not only five
                                centuries, but also the leap into electronic typesetting, remaining essentially unchanged... </p>
                            <h6 className="text-start mt-0 " style={{ marginLeft: '40px' }}><Icon icon={ic_favorite_border} /> 2.1k <Icon icon={speech_bubble_1} /> 2.1k</h6>
                            <p className="text-start p-4 fw-bold">View More Comments</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="me-lg-2 mt-3 d-none d-lg-block">
                <div className="card"><Ads /></div>
                
            </div>
            {/* </div> position-fixed top-2 end-0 overflow-auto" */}
        </div>
    );
}
export default Index;