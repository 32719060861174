import React, {useState} from "react";
import './style.scss'
import MedicationCard from '../../../components/medication/medication-card'
import PatientSidebar from '../../../components/sidebar-patient/index'
import ReportMedicationStep1 from '../../../components/model/medication/reportMedicaionstep1'
import ReportMedicationStep2 from '../../../components/model/medication/reportMedicaion'
const Index = () => {

    const [reportMedicationStep1, setReportMedicationStep1] = useState(false)
    const [reportMedicationStep2, setReportMedicationStep2] = useState(false)

    const handleReportMedicationStep1 = () => {
        setReportMedicationStep1( true )
    }
    const handleReportMedicationStep2 = () => {
        setReportMedicationStep1( false )
        setReportMedicationStep2(true)
    }
    return (
        <div className="my-medication row">
            <div className='col-sm-12 col-md-12 col-lg-9'>
                <div className="text-start">
                    <h3 style={{ color: '#2D516A' }}>Medications</h3>
                    <h5 style={{ color: '#224662' }}>Current Medications</h5>
                    <p>Please review your medications, and verify that the list is up to date.</p>
                </div>

                <div className="mb-3"><MedicationCard /></div>
                <div className="mb-3"><MedicationCard /></div>
                <div className="mb-3"><MedicationCard /></div>
                <div className="mb-3"><MedicationCard /></div>

                <div className="report-btn mb-4 d-flex justify-content-center align-items-center" type='button' onClick={handleReportMedicationStep1}>
                    <h5>+ Report a medication</h5>
                </div>
            </div>
            <div className='col-md-3 col-lg-3 d-none d-lg-block '>
                    <div className='card p-2 position-fixed top-2 end-0 overflow-auto' style={{ width: '310px', maxHeight: "85vh" }}>
                        
                        <PatientSidebar />
                    </div>
                </div>

                {
                    reportMedicationStep1 ?
                    <ReportMedicationStep1
                        show={reportMedicationStep1}
                        handleReportMedicationStep2={handleReportMedicationStep2}
                        onHide={() => setReportMedicationStep1(false)}
                    />:null
                }

                {
                    reportMedicationStep2?
                    <ReportMedicationStep2
                        show={reportMedicationStep2}
                        onHide={() => setReportMedicationStep2(false)}
                    />:null
                }
        </div>
    );
}

export default Index;