import React from "react";
import './style.scss';
import { Images } from "../../../utils/Images";
const Index = ({ appointment }) => {
    return (
        <div className="upcoming-screening-profile">
            <div className="profile-card mb-5">
                <div className="d-flex mt-1">

                    <div className="ms-2 text-start">
                        <div className="d-flex align-items-center">
                           <h5 className="mb-3 mt-3 fw-bold fs-6"> {appointment? 'Rescheduled Appointment':'Test:Ultrasound'}</h5>
                            
                        </div>




                    </div>
                </div>
                <div className="d-flex justify-content-evenly flex-wrap flex-sm-nowrap p-3 p-md-0">
                    <div className="">
                        <p className="profile-header-p">Clinic/Hospital</p>
                        <div>
                            <p className="m-0">Bangabandhu Medical College Hospital</p>
                            <p className="m-0">
                                <span className="fw-bold">Address:</span>
                                Kumar Para, 178/b,ittttw33 Dhaka
                            </p>
                            <p><span className="fw-bold">Ref-</span>Dr. Ajith Kumar</p>

                        </div>

                    </div>
                    <div className="">
                        <p className="profile-header-p">Doctor</p>
                        {appointment &&<img style={{width:'30px'}} src={Images.Doctor} alt=""/>}
                        <p className="m-0">Dr. Ali Asad</p>
                        {appointment &&<p style={{color:'grey'}}>MBBS FCBS</p>}

                    </div>
                    <div className="">
                        <p className="profile-header-p">Time</p>
                        <p className="m-0">22 oct, 2023</p>
                        <p className="m-0">5.55PM</p>
                        <p><span className="fw-bold">Pay</span> BDT 600/-</p>
                    </div>
                </div>



                <div className="text-center justify-content-end mt-4 mb-2">
                    {appointment ?
                        <button className="text-center cancel-appointment">Download Recipt</button>
                        : <button className="text-center cancel-appointment">Cancel Appointment</button>
                    }

                </div>
                <div className="text-center justify-content-end mb-4">
                    {appointment ?
                        <button className="upload-Test-result">Cancel Appointment</button>
                        : <button className="upload-Test-result">Upload Test Result</button>
                    }
                </div>
            </div>
        </div>
    );
}
export default Index;