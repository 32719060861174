import React from 'react'
import './style.scss';
// import { Images } from '../../../utils/Images'
const Index = () => {

    return (
        <div className="allergies-reactions-table">
            <table className="table">
                {/*  table-bordered */}
                <thead>
                    <tr className=''>
                        <th scope="col">Allergy</th>
                        <th scope="col">Type</th>
                        <th scope="col">Severity</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th className='text-center' scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Active Allergies(2)</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>Bees</td>
                        <td>Environmental</td>
                        <td>Service</td>
                        <td>Mar 01, 2020</td>
                        <td>-</td>
                        <td>
                            <div className='d-flex justify-content-center'>
                                <button className='inactivate-btn'>Inactivate</button>
                                <button className='edit-btn'>Edit</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Index;