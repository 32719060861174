/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Verifications from '../../../components/table/Verifications/index'
import './style.scss'
// import Icon from 'react-icons-kit'
// import {world} from 'react-icons-kit/typicons/'
// import {user} from 'react-icons-kit/ikons/user'
// import {ic_more_horiz_outline, ic_autorenew, ic_favorite_border, ic_person} from 'react-icons-kit/md/'
// import {speech_bubble_1} from 'react-icons-kit/ikons/'
// import { Images } from '../../utils/Images'
const Index = () => {

    return (
        <div className="patient-profile-card">
            <h3 className='text-start fw-medium'>Verifications</h3>
            <p className='mt-5 mb-5 text-center'>You Applied to become a doctor. <a href=''>See status</a></p>
            <hr/>
            <div className='mt-5 mb-5'>
            <Verifications/>
            </div>
            
        </div>
    );
};

export default Index;