/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import './style.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import { Images } from '../../utils/Images'
import Icon from 'react-icons-kit'
import { ic_save_alt } from 'react-icons-kit/md/'
// import ForwardPrescription from '../../components/table/ForwardPrescription'
import AllergiesReactions from '../../../components/table/Record/AllergiesReactions'
import VaccinationRecord from '../../../components/table/Record/VaccinationRecord'
import Record from '../../../components/table/Record/Records'

const Index = () => {
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <div className="my-record text-start">
            <Tabs  selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList className="text-center">
                    <Tab>My Records</Tab>
                    <Tab>Allergies & Reaction</Tab>
                    <Tab>Vaccination Record </Tab>
                </TabList>

                {/* My Records */}
                <TabPanel className=''>
                    <p className="mb-0 p-0 fw-bold">My Records</p>


                    <div className="d-flex justify-content-center mb-4">
                        <button className="record-btn">28D</button>
                        <button className="record-btn">90D</button>
                        <button className="record-btn">365D</button>
                        <button className="record-btn">Dec</button>
                        <button className="record-btn">Nov</button>
                        <button className="record-btn">Oct</button>
                        <button className="record-btn">2023</button>
                        <button className="record-btn">2022</button>
                        <button className="record-btn">Lifetime</button>
                    </div>
                    <Record />
                </TabPanel>

                {/* Allergies & Reaction */}
                <TabPanel>
                    <div className="d-flex justify-content-between">
                        <p className="fw-bold">Allergies & Reaction <a href="#"> See All</a></p>
                        <p className="fw-bold"><Icon icon={ic_save_alt} /> Download Full Medical Record</p>
                    </div>
                    <div className="mb-3">
                        <AllergiesReactions />
                    </div>

                    <a className='mt-2 mb-2' href="#">add more</a>
                </TabPanel>

                {/* Vaccination Record  */}
                <TabPanel>
                    <p className="mt-4 fw-bold">Vaccination Record <a href="#">See All</a></p>
                    <div className="mb-3"><VaccinationRecord /></div>

                    <a className='mt-4 mb-2' href="#">add more</a>
                    <hr />


                </TabPanel>
            </Tabs>

        </div>
    );
}
export default Index;