/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import './style.scss';
import { Images } from '../../../../utils/Images'
import Icon from 'react-icons-kit'
// import { user } from 'react-icons-kit/ikons/'
import { Link } from "react-router-dom";
import { ic_location_on, ic_public_outline } from 'react-icons-kit/md/'
const Index = ({ profile }) => {
    return (
        <div className="doctor-patients">
            {profile ? <>
                <p className="p-md-5">User does not share this information
                    Se MediServ.<a href="#"> Privacy Policy</a></p>
            </> : <>
                <p className="fw-bold text-start">You can hide this list</p>
                {/* Account suggestions on profiles */}
                <div className="card">
                    <div className="d-flex justify-content-between p-5">
                        <div>
                            <p className="fw-bold text-start m-0">Show account suggestions on profiles</p>
                            <p className=" text-start m-0">Choose whether people can see similar account suggestions
                                on your profile, and whether your account can be suggested
                                on other profiles.
                            </p>
                        </div>
                        <Icon icon={ic_public_outline} />
                    </div>
                </div>

                {/* Profile */}
                <div className="col-sm-12 col-md-6 col-lg-3">
                    <div className="card profile  mt-2 mr-2 p-4 text-center">
                        <div>
                            <img style={{ width: '50px' }} src={Images.Doctor2} alt="" />
                        </div>
                        <Link to='/doctor/patient-profile' style={{ textDecoration: 'none', color: 'black' }} className="p-0 m-0">
                            <p className="fw-bold p-0 m-0">Aranya Khan</p>
                        </Link>

                        <p className="m-0">Patient ID: Pt0016</p>
                        <p className="m-0"><Icon icon={ic_location_on} />Dhaka, Bangladesh</p>
                    </div>
                </div>
            </>}




        </div>
    );
}
export default Index;