import React from "react";
import './style.scss';
// import { Images } from '../../../utils/Images'
import Icon from 'react-icons-kit'
import { ic_more_horiz_twotone, ic_add_circle, ic_place } from 'react-icons-kit/md/'



const Index = () => {

    return (
        <div className="schedule mt-lg-3">
            <div className="card p-4">
                <div className="text-end"><Icon icon={ic_more_horiz_twotone} size={30}/></div>
                <h5 className="fw-bold text-start m-0 p-0">Status: <span style={{ color: '#1EC630' }}>30 Patients Booked</span></h5>
                <div className="text-start d-flex flex-row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    
                    <p className="m-0 p-0 mt-3"><span style={{ color: '#7B7A7B' }}><Icon icon={ic_add_circle} size={20}/> Hossain Ershad Medical College and Hospital</span></p>
                    <p className="m-0 p-0 mt-2" style={{ color: '#1EC630' }}><Icon icon={ic_place} size={20}/><span style={{ color: '#7B7A7B' }}>Paik Para, Brahmanbaria</span></p>
                    <p className="m-0 p-0 mt-2">
                        <span className="fw-bold">Days:</span>
                        <span style={{ color: '#7B7A7B' }}> Sun, Mon, Tues, Wed, Thu, Fri, Sat</span>
                    </p>

                    <p className="m-0 p-0 mt-2">
                        <span className="fw-bold">Time:</span>
                        <span style={{ color: '#FF66c4' }}> 2PM-5PM</span>
                    </p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <p className="m-0 p-0 mt-2">
                        <span className="fw-bold">Start Date:</span>
                        <span style={{ color: '#7B7A7B' }}> 16 Dec 2022</span>
                    </p>
                    <p className="m-0 p-0 mt-2">
                        <span className="fw-bold">End Date:</span>
                        <span style={{ color: '#62B5FF' }}> Set End Date</span>
                    </p>
                    <p className="m-0 p-0 mt-2">
                        <span className="fw-bold">Fees:</span>
                        <span style={{ color: '#7B7A7B' }}> BDT 500</span>
                    </p>
                    <p className="m-0 p-0 mt-2">
                        <span className="fw-bold">Patients Capacity:</span>
                        <span style={{ color: '#7B7A7B' }}> 55 Patients</span>
                    </p>
                    <p className="m-0 p-0 mt-2">
                        <span className="fw-bold">Lead time:</span>
                        <span style={{ color: '#7B7A7B' }}> 2 Minutes</span>
                    </p>
                </div>
                </div>
                
            </div>
        </div>
    );
}
export default Index;