/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import './style.scss'
import Icon from 'react-icons-kit'
import { useForm } from 'react-hook-form'
import { ic_highlight_off_twotone } from 'react-icons-kit/md/'



const Index = ({ handleVerified }) => {
    const { handleSubmit } = useForm();

    const handleVerifiedData = () => {
        handleVerified()
    }
    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);

        } catch (error) {

        }
    }
    return (
        <div className="profile-not-verified">

            <div className="card">
                <Icon icon={ic_highlight_off_twotone} size={35} style={{ color: '#F07181' }} />
                <p className="p-0 m-0">Sorry</p>
                <p>Your doctor profile couldn't be verified</p>

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="d-flex">
                        <p style={{ paddingRight: '10px' }}>Message</p>
                        {/* <div className="col-8"> */}
                        <textarea style={{ width: '500px' }} className="form-control" id="exampleFormControlTextarea1" rows="5">
                        </textarea>
                        {/* </div> */}

                    </div>


                    {/* </div> */}

                    <div className='row'>
                        <div className="modal-footer text-center" >

                            <div className="col-12 text-start mb-0">
                                <p className="p-0 mt-4"  >If you feel something is wrong</p>

                                <div className="col-12 text-start">
                                    <button className='btn-verification'
                                        type="submit"
                                        style={{ background: "#A356A8" }}
                                        onClick={handleVerifiedData}
                                    >Apply again</button>

                                </div>
                            </div>


                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}
export default Index;