import React from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import { world } from 'react-icons-kit/typicons/'
// import {user} from 'react-icons-kit/ikons/user'
import { ic_more_horiz_outline, ic_autorenew, ic_favorite_border, ic_person, ic_check_circle_outline, } from 'react-icons-kit/md/'
import { speech_bubble_1 } from 'react-icons-kit/ikons/'
import { Images } from '../../utils/Images'
// import SearchComponent from '../../components/search/Index'
// import { user } from 'react-icons-kit/ikons/user'
import { ic_arrow_drop_down, ic_download_outline, ic_upload_outline } from 'react-icons-kit/md/'
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form'

const Index = ({profile}) => {
    const { register, handleSubmit, formState: { errors }, } = useForm()
    // const [searchValues, setSearchValues] = useState(null);
    const onClearSearch = event => {
        // const value = event.target.value
        // if (!value) props.clear()
    }

    // Submit Form
    const onSubmit = data => {

    };
    return (
        <div className="patient-profile-card">

            <div className='card-patient'>
                <div className='row align-items-center'>
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                        <img style={{}} src={Images.BlackProfile} alt='' />
                        {/* <div style={{zIndex:'122'}}><Icon icon={ic_camera_alt_outline} size={50}/><p>Change Picture</p>
                        </div> */}
                    </div>

                    <div className='col-sm-12 col-md-6 text-align-start' style={{ textAlign: 'start' }}>
                        <h4 className=''>Patient Name</h4>
                        {profile === 'self' ?
                        <div>
                        <p className='fw-bolder'>Asked 22 Questions</p>
                        <div className='d-flex justify-content-between'>
                            <Link to='/patient/edit-portfolio'  className='btn m-0'>Edit Portfolio</Link>
                            <Link to='/prescription' target="_blank" className='btn m-0'>View Prescription</Link>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                            {/* <p className='fw-bolder'>Diagnosed with</p> */}
                            <p className='fw-bolder p-0 mt-2'>Diagnosed with</p>
                            <p className='fw-bolder p-0'>No- Sevier health condition</p>
                        </div>
                    </div>
                        :
                            <div><p>Diagnosed with Insomnia</p>
                                <button className='btn'>Request Access</button></div>
                            
                        }



                    </div>
                </div>

                <div className='d-flex mt-3 justify-content-between align-items-center'>
                    <p className='p-0 m-0 fw-bold'>Vaccinations done </p>
                    <p className='p-0 m-0 fw-600' style={{ color: '#A356A8' }}>Edit</p>
                </div>

                <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex justify-content-around align-items-center flex-column flex-lg-row'>
                        <p className='border  border-2 rounded p-1 m-0 me-lg-5 fw-700 '> <span className='count'>1</span> Covid-19</p>
                        <p className='border  border-2 rounded p-1 m-0 me-lg-5 fw-700 '> <span className='count'>2</span> Covid-19</p>
                        <p className='border  border-2 rounded p-1 m-0 me-lg-5 fw-700 '> <span className='count'>3</span> Covid-19</p>
                        <p className='border  border-2 rounded p-1 m-0 me-lg-5 fw-700 '> <span className='count'>4</span> Covid-19</p>

                    </div>
                    <p className='p-0 m-0 fw-900' style={{ color: '#A356A8' }}>More</p>
                </div>
                {profile &&(<p className='p-0 m-0 mt-2 fw-bold text-start'>None recorded yet</p>)}
            </div>
            <div className="card-patient mb-3 mr-3">
                {profile === 'self'?
                <div>
                <p className='p-0 mb-2 fw-bold text-start'>Your Questions</p>
                <div className="d-flex align-items-center justify-content-around">
                        <img className="d-none d-lg-block" style={{ width: '60px', height: '60px' }} src={Images.Female} alt="" />
                        <div className="col-11 search-component">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="input-container">
                                    <input
                                        type="text"
                                        name="query"
                                        {...register("query", { required: 'value is required' })}
                                        placeholder="Ask something to the community"//{`Search ${props.placeholder}`}
                                        onChange={onClearSearch}
                                        className={errors.query ? "form-control form-control-sm shadow-none error" : "form-control form-control-sm shadow-none"}
                                    />
                                    <button
                                        type="submit"
                                        className="btn btn-sm shadow-none"
                                    // disabled={props.loading}
                                    >

                                    </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                :
                <div className="d-flex justify-content-between">
                    <div className="col-sm-12 col-md-5 ">
                        <div className="d-flex align-items-center">
                            {/* <img className="m-3" src={Images.ProfileIcon} alt=""/> */}
                            <Icon icon={ic_person} size={60} />
                            <div className="d-flex flex-column align-items-start m-3">
                                <h3>Patient Name</h3>
                                <p className='mb-0 pb-0'>25 min <Icon icon={world} /></p>
                                <p className='m-0 p-0 fw-bold' style={{ color: "#A0A0A0" }}>MBBS FCPS</p>
                            </div>

                        </div>
                    </div>
                    <Icon style={{ color: '#E1E1E1' }} icon={ic_more_horiz_outline} size={50} />
                </div>
                
            }
                <div className="mt-4">
                    <h5 className="text-start p-4 fw-bold">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged ...See More</h5>
                </div>

                <div className='m-2' style={{ height: '200px', background: '#D9D9D9' }}>

                </div>


                <div>
                    {/* <hr style={{ height: '2px' }} /> */}
                    {profile !=="self"&&(<div className="d-flex justify-content-between  flex-column flex-md-row ">
                        <h6 className="text-start m-3"><Icon icon={ic_favorite_border} /> 2.1k <Icon icon={ic_autorenew} /> 2.1k <Icon icon={speech_bubble_1} /> 2.1k</h6>

                        <div>
                            <Link className="d-flex align-items-center link-btn m-0"><Icon className="me-1 mb-0" icon={ic_upload_outline} size={25} />Upvote . 2k<div className="ms-1 mb-0 vertical-line "><Icon className="ms-2 mb-0" icon={ic_download_outline} size={25} /></div></Link>
                            <h6 className="mt-2">Most Recent<Icon className="me-4" icon={ic_arrow_drop_down} size={30} /></h6>
                        </div>
                    </div>)}
                    {profile !=="self" &&(<div className="d-flex align-items-center justify-content-around">
                        <img className="d-none d-lg-block" style={{ width: '60px', height: '60px' }} src={Images.Female} alt="" />
                        <div className="col-11 search-component">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="input-container">
                                    <input
                                        type="text"
                                        name="query"
                                        {...register("query", { required: 'value is required' })}
                                        placeholder="Write a comment ..."//{`Search ${props.placeholder}`}
                                        onChange={onClearSearch}
                                        className={errors.query ? "form-control form-control-sm shadow-none error" : "form-control form-control-sm shadow-none"}
                                    />
                                    <button
                                        type="submit"
                                        className="btn btn-sm shadow-none"
                                    // disabled={props.loading}
                                    >

                                    </button>

                                </div>
                            </form>
                        </div>
                    </div>)}
                    



                    {/* <div className="text-start d-flex flex-column align-items-start m-3">
                            <h5><Icon className="" icon={user} size={30} /> Md. Hanif</h5>
                            <p style={{ marginLeft: '40px' }}>when an unknown printer took a galley of type and scrambled it to make a
                                type specimen book. It has survived not only five
                                centuries, but also the leap into electronic typesetting, remaining essentially unchanged... </p>
                            <h6 className="text-start mt-0 " style={{ marginLeft: '40px' }}><Icon icon={ic_favorite_border} /> 2.1k <Icon icon={speech_bubble_1} /> 2.1k</h6>
                            <p className="text-start p-4 fw-bold">View More Comments</p>
                        </div> */}

                    <div className="card mt-5 p-4 d-flex align-items-center justify-content-center">
                        <Icon icon={ic_check_circle_outline} size={35} style={{ color: '#75147B', marginTop: '20px' }} />
                        <h5>That's all for now</h5>
                        <button className="add-prescription-btn" style={{ marginBottom: '20px' }}>Back to Top</button>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default Index;



