import React from "react";
import './index.css'
import comingBg from "../../assets/commingBg.jpg"

const Index = () => {
    return (
        <div className="container">
            <div className="content-container">
                <div style={{ height:'70vh'}}>
                    <div className="middle">
                        <h1>COMING SOON</h1>
                        <hr/>
                        <p>Stay tuned </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Index;