/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import { useForm } from 'react-hook-form'
import Drugs from '../../../components/table/prescription/index'
import Tests from '../../../components/table/prescription/tests'
// import VaccinationRecord from '../../../components/table/practice/vaccination-record'
import './style.scss';
// import Select from 'react-select';
// import Icon from 'react-icons-kit'
// import { circle_left } from 'react-icons-kit/ikons/'
import { Images } from '../../../utils/Images';
import MyQrCodeComponent from '../../qr'
const Index = () => {
    const qrCodeData = 'https://example.com';
    // const { register, } = useForm();//handleSubmit, formState: { errors },
    // const [isClearable,] = useState(true); //setIsClearable
    // const [isSearchable,] = useState(true); //setIsSearchable
    // const [isDisabled,] = useState(false); //setIsDisabled
    // const [isLoading,] = useState(false); //setIsLoading
    // const [isRtl,] = useState(false); //setIsRtl
    return (
        <div className='prescription-pdf card'>
            <div className='chamber d-flex align-items-center'>
                <div className='col-6'>
                    <div className='d-flex'>
                        <p className='m-0 p-0 fw-bold'>Chamber:</p>
                        <div>
                            <p className='m-0 p-0'>Bangabandhu, Ershad, Jia, Golam Aza</p>
                            <p className='m-0 p-0'>Friendship Hospital for disabled peop</p>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <p className='m-0 p-0 fw-bold'>Address:</p>
                        <div>
                            <p className='m-0 p-0'>Bangabandhu, Ershad, Jia, Golam Aza</p>
                            <p className='m-0 p-0'>Friendship Hospital for disabled peop</p>
                        </div>
                    </div>
                </div>
                <div className='col-6 d-flex align-items-center'>
                    <img src={Images.Logo} style={{width:'100px'}} alt='' />
                    <div>
                        <img src={Images.TextLogo} alt='' />
                        <p>COMMITTED TO HEALTHCARE</p>
                    </div>

                </div>
            </div>
            <div className='p-5'>
                <div className='d-flex justify-content-between align-items-center mt-2'>
                    <p><span className='fw-bolder'>Patient Name: </span>Akhyar Ul Haque</p>
                    <p><span className='fw-bolder'>Age: </span>23 yrs</p>
                    <p><span className='fw-bolder'>Date: </span>12 Oct 2023</p>
                </div>
                <p className='text-start'><span className='fw-bolder'>Reference: </span>Jalij Moddhop0336</p>
                <hr />
                <p className='text-start p-0 m-0'><span className='fw-bolder'>Previously Diagnosed With: </span>None recorded on mediserv.ai/Pelvic Pain - 24 Dec,2023</p>
                <p className='text-start p-0 m-0'><span className='fw-bolder'>Chief Complaint: </span>Pelvic Pain</p>
                <p className='text-start p-0 m-0'><span className='fw-bolder'>Investigation: </span>Pelvic Pain</p>

                <div className='d-flex justify-content-between align-items-center mt-4 mb-2'>
                    <p><span className='fw-bolder'>BP: </span></p>
                    <p><span className='fw-bolder'>Pulse: </span></p>
                    <p><span className='fw-bolder'>Temp.: </span></p>
                    <p><span className='fw-bolder'>SpO2: </span></p>
                    <p><span className='fw-bolder'>RPG: </span></p>
                </div>
                <p className='text-start p-0 m-0'><span className='fw-bolder'>Drugs: </span></p>
                <Drugs />
                <div className='d-flex mt-3 align-items-center justify-content-between'>
                    <p><span className='fw-bolder'>Tests: </span></p>
                    <div className='col-sm-12 col-md-9 col-lg-9'>
                        <Tests />

                    </div>

                    <div className='col-sm-12 col-md-2 col-lg-2'>
                        <p className='p-0 m-0'>Digitally Signed By:</p>
                        <h4 className='p-0 m-0'>Dr. Ned Nitin</h4>
                        <p className='p-0 m-0'>MBBS, FCPS</p>
                        <p className='p-0 m-0'>Child Health Specialist</p>
                        <MyQrCodeComponent data={qrCodeData} />
                        <p className='p-0 m-0'>Scan To See Card</p>
                    </div>
                </div>
                <div class="form-floating mt-4">
                    <textarea class="form-control" placeholder="Write if you have any Indications(Optional)" id="floatingTextarea3" style={{ height: '100px' }}></textarea>
                    <label for="floatingTextarea3">Advice:</label>
                </div>
            </div>


            <div className='chamber-footer d-flex align-items-center justify-content-center mt-5'>

                <div className='d-flex'>
                    <p className='m-0 p-0 fw-bold'>Registered Office:</p>
                    <div>
                        <p className='m-0 p-0'>MediServ Private Ltd. House 691, Road 12, post office- Mohammadpur 120,</p>
                        <p className='m-0 p-0'>Adabor, Dhaka north city corporation, Dhaka</p>
                    </div>
                </div>


            </div>
        </div>
    )
}



export default Index;