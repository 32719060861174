import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import Icon from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { Images } from '../../../../utils/Images'
// import { api } from '../../../../../utils/api'
// import 'react-toastify/dist/ReactToastify.css'
// import { x, } from 'react-icons-kit/feather'
import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle'
import { useForm } from 'react-hook-form'
// import { toast } from 'react-toastify';
const Index = (props) => {

    // const [isLoading, setLoading] = useState(false) formState: { errors }, 

    const { handleSubmit, } = useForm();// register,




    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const header = {
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    // }


    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            props.handleRefetch()
            // const res = await Axios.post(`${api}user/prospect`, regData, header);

            // if (res.status === 200) {
            //     setId(res.data.response);
            //     toast('Your create account save');
            //     setStep(1);
            //     setLoading(false)
            //     props.handleRefetch()
            // }
            // else {
            //     setLoading(false);
            //     toast('This phone number or email already exists.');
            //     props.onHide();
            // }



        } catch (error) {
            // if (error) 
            // console.log(error.response.data.message)
            // setLoading(false);
            // toast(error.response.data.message);
            props.onHide();
        }
    }

    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-form"
            >
                {/* <Modal.Header>
                    <Modal.Title>{props.value ? props.value.name : 'New Traffic'}</Modal.Title>
                    <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button>
                </Modal.Header> */}
                <Modal.Body >

                    <div className='d-flex  align-items-center' style={{background:'#FEEEFF', borderRadius:'50px' }}>
                        <div className='d-none d-lg-block col-lg-5 text-center' style={{background:'#FEEEFF', borderRadius:'50px 0px 0px 50px' }}>
                        <div className='mb-2 mt-5 '>
                                <img src={Images.Logo} className='modal-logo-img' alt=''/>
                            </div>
                            
                            <img src={Images.TextLogo} className='modal-logo-text-img mb-5' alt=''/>
                            <p className='mt-4'style={{fontSize:'20px', color:'#000'}}>HI!!</p>
                            <p className='mb-0' style={{fontSize:'20px', color:'#000'}}>Welcome Back To</p>
                            <h1 className='mb-5' style={{fontSize:'20px', fontWeight:"bold", color:'#C2696E'}}>MEDISERV.ai</h1>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-7'  style={{background:'#F0F0F0', borderRadius:'0px 50px 50px 0px', padding:'40px 50px 10px 50px'}}>
                            
                           
                            <div className='card mt-2 border-none'>
                                <div className='card-body p-4'>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <div className="text-center">
                                        <Icon style={{color:'#A356A8'}} icon={ic_check_circle} size={60} />
                                        </div>

                                        {/* <div className='row'> */}
                                        <p className='mt-2' style={{ fontWeight: '700', color: '#000', fontSize: '21px' }}>
                                        "Your massage has been received <span style={{ fontWeight: '700', color: '#a356a8', fontSize: '21px' }}>
                                        Al 'Maya'</span> will contact you shortly via Email"
                                            
                                        </p>
                                    </form>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '40px' }}>
                                <div className='col-8'>
                                </div>
                                <div className='col-4' style={{ textAlign: 'end' }}>
                                    <button type="button" className="close-btn shadow-none" onClick={props.onHide} >Close</button>
                                </div>
                            </div>
                        </div>

                    </div>


                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
