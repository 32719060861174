import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import Icon from 'react-icons-kit'
import { Images } from '../../../utils/Images';
import { Modal } from 'react-bootstrap'
import {ic_facebook} from 'react-icons-kit/md/'
import { useNavigate } from "react-router-dom";
// import { api } from '../../../../../utils/api'
// import 'react-toastify/dist/ReactToastify.css'
// import { x, } from 'react-icons-kit/feather'
import {ic_arrow_drop_down} from 'react-icons-kit/md/'

import { useForm } from 'react-hook-form'
// import { toast } from 'react-toastify';
const Index = (props) => {
    const navigate = useNavigate();
    // const [isLoading, setLoading] = useState(false) formState: { errors }, 

    const { register, handleSubmit, } = useForm();
    // const token = localStorage.getItem('token')



    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const header = {
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    // }
    // useEffect(() => {
    //     if(token){
            
    //     }
    // }, [token, navigate])
    const accountProps = () =>{
        props.handleSignUp()

    }

    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            // const regData = {
            //     ...data
            // }
            localStorage.setItem('token', "bhjjhjkeroiwgtuigow");
            navigate(`/patient/patient-community`);
            props.handleRefetch();
            window.location.reload();
            // const res = await Axios.post(`${api}user/prospect`, regData, header);

            // if (res.status === 200) {
            //     setId(res.data.response);
            //     toast('Your create account save');
            //     setStep(1);
            //     setLoading(false)
            //     props.handleRefetch()
            // }
            // else {
            //     setLoading(false);
            //     toast('This phone number or email already exists.');
            //     props.onHide();
            // }



        } catch (error) {
            // if (error) 
            // console.log(error.response.data.message)
            // setLoading(false);
            // toast(error.response.data.message);
            props.onHide();
        }
    }

    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-form"
            >
                {/* <Modal.Header>
                    <Modal.Title>{props.value ? props.value.name : 'New Traffic'}</Modal.Title>
                    <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button>
                </Modal.Header> */}
                <Modal.Body >

                    <div className='d-flex align-items-center' >
                        <div className='col-5 text-center firstColumn'>
                            <div className='mb-2'>
                                <img src={Images.Logo} className='modal-logo-img' alt=''/>
                            </div>
                            
                            <img src={Images.TextLogo} className='modal-logo-text-img mb-5' alt=''/>
                            <p className='mt-4'style={{fontSize:'20px', color:'#000'}}>HI!!</p>
                            <p className='mb-0' style={{fontSize:'20px', color:'#000'}}>Welcome Back To</p>
                            <h1 style={{fontSize:'20px', fontWeight:"bold", color:'#C2696E'}}>MEDISERV.ai</h1>
                        </div>
                        <div className='col-12 col-md-12 col-sm-7 col-lg-7 col-xl-7 secondColumn'>
                            <p className='p-0 text-end' style={{fontSize:'12px'}}>English(UK) <Icon icon={ic_arrow_drop_down}/></p>
                            <h2>Login</h2>
                            <div className='card mt-2 border-none'>
                                <div className='card-body p-4'>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <div className="row">

                                            {/* Name */}
                                            <div className="col-12">
                                                <div className="form-group mb-4">
                                                   
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        style={{height:'60px'}}
                                                        className="form-control shadow-none"
                                                        placeholder="Email or Phone Number"
                                                        defaultValue={props.value ? props.value.name : null}
                                                        {...register("email", {
                                                            required: "Email or Phone Number is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-group">
                                                  
                                                    <input
                                                        type="text"
                                                        name="password"
                                                        style={{height:'60px'}}
                                                        className="form-control shadow-none"
                                                        placeholder="Password"
                                                        defaultValue={props.value ? props.value.name : null}
                                                        {...register("password", {
                                                            required: "Password is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        

                                        <div className='row'>
                                            <div className="modal-footer text-center" >
                                                <div className="col-12 text-center">
                                                    <button className='btn-login' style={{ background: "#A356A8" }}>Log in</button>
                                                   
                                                </div>
                                                <div className="col-12 text-center mb-4"><p style={{color: "#A356A8"}}>Forgot Password?</p></div>


                                                <div className="col-12 text-center mt-2 mb-0">
                                                    <button className='account-btn' onClick={accountProps}>Create new account</button>
                                                   
                                                </div>
                                                
                                                {/* <button type="submit" className="btn-save shadow-none" disabled={isLoading}  >
                                                    {isLoading ? 'Save...' : 'Save'}
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn shadow-none"
                                                    disabled={isLoading}>
                                                    {isLoading ? 'Next...' : 'Next'}
                                                </button> */}
                                            </div>
                                            </div>

                                    </form>
                                </div>
                            </div>
                            <h6 className='mt-2' style={{fontWeight:'900',color:'#000', fontSize:'10px'}}>Register As Doctor, LOREMIPSUMLOREMIPSUMLOREMIPSUM</h6>
                            <h6 className='mt-2 text-center' style={{fontWeight:'900',color:'#D9D7D7', fontSize:'12px'}}>-OR-</h6>

                            <div className='row mt-3'>
                                <div className='col-6'><p><img style={{width:'14px'}} src={Images.Google} alt=''/> Signup with Google</p></div>
                                <div className='col-6'><p style={{marginRight:'0px'}}><Icon style={{color:'#1e4297'}} icon={ic_facebook} size={25}/> Signup with Facebook</p></div>
                            </div>

                            <div className='row' style={{marginTop:'40px'}}>
                                <div className='col-8'>
                                    <p style={{ fontWeight: '900', color: '#D0CFCF', fontSize: '12px' }}> Don't have an account?
                                        <span style={{ fontWeight: '900', color: '#000', fontSize: '14px' }} > Sign Up</span></p>
                                </div>
                                <div className='col-4' style={{textAlign:'end'}}>
                                <button type="button" className="close-btn shadow-none"  onClick={props.onHide} >Close</button>
                                </div>
                            </div>
                        </div>

                    </div>


                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
