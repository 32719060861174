/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import './style.scss'
import { Images } from "../../utils/Images";
import 'react-phone-number-input/style.css'
// import {ic_facebook} from 'react-icons-kit/md/'
import 'react-datepicker/dist/react-datepicker.css';
import 'react-month-picker-input/dist/react-month-picker-input.css';
import DoctorRegister from '../../components/register-dr/Index'
import CouldNotBeVerified from '../couldnot-be-verified/Index'
import CouldBeVerified from '../verification/Index'

const Index = () => {
    const [show, setShow] = useState(true)
    const [showNotVerified, setShowNotVerified] = useState(false)
    const [showVerified, setShowVerified] = useState(false)

    const handleNotVerified =()=>{
        setShow(false)
        setShowNotVerified(true)
    }

    const handleVerified =()=>{
        setShowNotVerified(false)
        setShowVerified(true)
    }


    return (
        <div className="community">
            <div className="col-sm-12 col-md-2 col-lg-2 me-lg-2 d-none d-lg-block">
                <div className="card mt-3 mb-3 mr-3">
                    <div className='mb-2'>
                        <img src={Images.Logo} className='modal-logo-img' alt='' />
                    </div>
                    <div className='mb-2'>
                        <img src={Images.TextLogo} className='modal-logo-text-img mb-5' alt='' />
                    </div>
                    <p className="mb-5" style={{ fontSize: '22px' }}>Get Connected with your Favorite Doctor</p>

                    <button className="get-btn mb-5">JOIN THE COMMUNITY</button>
                </div>
            </div>
            <div className="col-sm-12 col-md-9 col-lg-9 ">
                {
                    show ?
                        <DoctorRegister
                            handleNotVerified={handleNotVerified}
                        /> :null
                        
                }
                {
                    showNotVerified ?
                        <CouldNotBeVerified
                            handleVerified={handleVerified}
                        />
                        : null
                }
                {
                    showVerified ?
                        <CouldBeVerified />
                        : null
                }
            </div>

        </div>
    )
}
export default Index;