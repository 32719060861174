import React from 'react'//useEffect, useCallback,
import './style.scss'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

const Index = (props) => {

    // const [isLoading, setLoading] = useState(false) 

    const { register, handleSubmit, formState: { errors }, } = useForm();
    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            props.handleRefetch()
            // const res = await Axios.post(`${api}user/prospect`, regData, header);

            // if (res.status === 200) {
            //     setId(res.data.response);
            //     toast('Your create account save');
            //     setStep(1);
            //     setLoading(false)
            //     props.handleRefetch()
            // }
            // else {
            //     setLoading(false);
            //     toast('This phone number or email already exists.');
            //     props.onHide();
            // }



        } catch (error) {
            // if (error) 
            // console.log(error.response.data.message)
            // setLoading(false);
            // toast(error.response.data.message);
            props.onHide();
        }
    }

    return (

        <div className='' style={{marginTop:'100px'}}>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-otp-form"
            >
                <Modal.Body >

                    <div className='d-flex align-items-center'>
                        <div className=''>
                            <div style={{background: '#A356A8', color:'#fff', paddingTop:"10px", paddingLeft:"10px", paddingBottom:"1px" }}>
                                <p className='p-0 fw-bolder'>Verify Your Phone Number</p></div>                           
                            <div className='card border-none'>
                                <div className='card-body p-0'>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <div className="row p-4 pb-0" >
                                            <p className='pb-4'>In a few seconds, you should receive a text message that contains your 
                                                mobile activation code.If you haven't received a code after few minutes, please try 
                                                again</p>
                                            {/* Name */}
                                            <div className="col-12">
                                                <div className="form-group">
                                                    {errors.packageName && errors.packageName.message ? (
                                                        <p className="text-danger">{errors.packageName && errors.packageName?.message}</p>
                                                    ) : <h6>Enter your code here</h6>
                                                    }
                                                    <input
                                                        type="text"
                                                        name="email"                                                   
                                                        className="form-control-otp mt-1 shadow-none"
                                                        // placeholder="Email or Phone Number"
                                                        // defaultValue={props.value ? props.value.name : null}
                                                        {...register("email", {
                                                            required: "OTP is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <hr className='mt-3 m-0' style={{ background: "#A356A8", border: '1px solid'}}/>

                                        <div className='row pt-0 p-2'>
                                            <div className="modal-footer text-center" >
                                                <button type="submit" className="btn shadow-none" >
                                                    Confirm
                                                </button>
                                                <button
                                                    onClick={props.onHide}
                                                    className="cancel-btn shadow-none">
                                                    Cancel
                                                </button>
                                            </div>
                                            </div>

                                    </form>
                                </div>
                            </div>
                        
                        </div>

                    </div>


                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
