/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './style.scss'
// import Icon from 'react-icons-kit'
// import {ic_visibility_outline,ic_local_printshop_outline} from 'react-icons-kit/md/'
// import { Images } from '../../../utils/Images'
const Index = ({profile}) => {

    return (
        <div className="forward-prescription-table">
            <div className=''>
                <table className="table table-bordered text-start">
                    <thead>
                        <tr>
                            <th scope="col">
                                {profile?<h2>Posts</h2>:<h2>Questions</h2>}
                                </th>
                            <th scope="col"><h2 className="text-center">Status</h2></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><p className='p-2 m-0'>You Asked " amar cheler boyosh......<a href=''>See post</a>"</p></td>   
                            <td><p className='p-0 m-0 text-center' style={{ color: '#48B250' }}>Published</p></td>
                        </tr>
                        <tr>
                            <td><p className='p-2 m-0'>You Asked " amar cheler boyosh......<a href=''>See post</a>"</p></td>                            
                            <td><p className='p-0 m-0 text-center' style={{ color: '#FF6F6F' }}>Pending</p></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Index;