/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
// import Icon from 'react-icons-kit'
// import { Images } from '../../../utils/Images';
import { Modal } from 'react-bootstrap'
// import {ic_facebook} from 'react-icons-kit/md/'
// import {ic_collections} from 'react-icons-kit/md/'

// import { api } from '../../../../../utils/api'
// import 'react-toastify/dist/ReactToastify.css'
// import { x, } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'

import RightEye from '../../table/practice/RightLens'
import LeftEye from '../../table/practice/LeftLens'

// import { toast } from 'react-toastify';
const Index = (props) => {

    // const [isLoading, setLoading] = useState(false) formState: { errors }, 

    const { register, handleSubmit, } = useForm();
    // const handleReScheduleProps = () =>{
    //     props.handleReSchedule()
    // }



    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const header = {
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    // }


    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            // props.handleRefetch()
            

        } catch (error) {
            props.onHide();
        }
    }

    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-Lens"
            >
                <Modal.Header>
                    <Modal.Title >Update Lens Prescription</Modal.Title>
                    {/* <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button> */}
                </Modal.Header>
                <Modal.Body >
                    <div >
                        
                        <div className='mt-2 border-none'>
                        
                            <div className='card-body p-4 pb-2'>
                            
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="row">
                                    <div className='d-flex align-items-center mb-3'>
                                        <RightEye/>
                                        <p className='fw-bold'>Distant Near</p>
                                        <LeftEye/>
                                    </div>
                                        

                                        {/* IP.D */}
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group mb-2">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="IP.D"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/* Lens */}
                                        <div className="col-sm-12 col-md-6">
                                            <div className="form-group mb-2">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="Lens"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>

                                        {/* Write Remarks */}
                                        <div className="col-12">
                                            <div className="form-group mb-2">

                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control shadow-none"
                                                    placeholder="Write Remarks"
                                                    {...register("email", {
                                                        required: "Email or Phone Number is required",

                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <button type="submit" className='btn-save' onClick={props.onHide}>Save Changes</button>
                                        <p style={{fontSize:'11px'}}>Just Clicking Save Changes won't submit the prescription. To submit you need to end the session.</p>

                                    </div>



                                    {/* <div className='row'>
                                        <div className="modal-footer text-center" >
                                            <div className="col-12 text-center mt-2 mb-0">
                                                <button type="submit" className='account-btn'>Post</button>

                                            </div>

                                            {/* <button  className="btn-save shadow-none" disabled={isLoading}  >
                                                    {isLoading ? 'Save...' : 'Save'}
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn shadow-none"
                                                    disabled={isLoading}>
                                                    {isLoading ? 'Next...' : 'Next'}
                                                </button> 
                                        </div>
                                    </div> */}

                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
