/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import './style.scss'
import Icon from 'react-icons-kit'
import { ic_report_gmailerrorred_outline } from 'react-icons-kit/md/'



const Index = () => {
    return (
        <div className="Share-Health-Data">

            <div className="card">
                <h2 className="text-align-start">Share Health Data</h2>
                <p>Please generate a security code below and share with your Doctor </p>
                <p>Remember, your doctor will have View only Access to your health records for 24 hours</p>
                <div className="d-flex justify-content-between Share-Health-Data-p">
                    <p className="p-0 m-0"><Icon icon={ic_report_gmailerrorred_outline} /> Please read the Online Refund Instructions & Ticket Refund Policy before canceling your ticket.</p>
                    <a href="#">View</a>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="security">
                        <h3>Generated security code</h3>
                        <div className="cardShow">
                            <from>
                                <input className="form-control form-control-lg" type="text" aria-label=".form-control-lg example" />
                                <div>
                                    <button className="btn">Copy To Clip Board</button>
                                    <button className="btn">Generate New code </button>
                                </div>
                            </from>
                        </div>


                        <p>Security code expires in <span style={{color:'red'}}>20</span> minutes</p>
                    </div>
                </div>


            </div>
        </div>
    )
}
export default Index;