import React from 'react'//useEffect, useCallback,
import './style.scss'
import { Modal } from 'react-bootstrap'
// import Appointment from '../BookAppointment'
const Index = (props) => {
    const handlePayment =() =>{
        props.handleBookingPayment()
    }



    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-appointment"
            >
                {/* <Modal.Header>
                    <Modal.Title>{props.value ? props.value.name : 'New Traffic'}</Modal.Title>
                    <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button>
                </Modal.Header> */}
                <Modal.Body >

                    <div className='d-flex align-items-center' >

                        <div className='col-sm-12  col-md-12'>

                            <div className='card-body'>
                                <div className="appointment">

                                    <div className="card-appointment mt-3 mb-3 mr-3 text-start">
                                        {/* <button>BOOK APPOINMENT</button> */}
                                        <h3 style={{ color: '#7D7C7D' }}>You are booking Dr. Rahim's Appointment</h3>
                                        <h6>Hospital:</h6>
                                        <h6>Address:</h6>
                                        <div className='d-flex justify-content-between'>
                                            <h6>Time Slot:</h6>
                                            <h6>Booking Fee:</h6>
                                        </div>
                                        <form >
                                            {/* onSubmit={handleSubmit(onSubmit)} */}
                                            <div className="row">

                                                {/* Choose Date */}
                                                <div className="col-12">
                                                    <div className="form-group mb-2">

                                                        <input
                                                            type="date"
                                                            name="date"
                                                            className="form-control shadow-none"
                                                            placeholder="Choose Date"

                                                        />
                                                    </div>
                                                </div>

                                                {/* Cause (Optional) */}
                                                <div className="col-12">
                                                    <div className="form-group mb-2">

                                                        <input
                                                            type="text"
                                                            name="cause"
                                                            className="form-control shadow-none"
                                                            placeholder="Cause (Optional)"

                                                        />
                                                    </div>
                                                </div>

                                                {/* Reference(optional) */}
                                                <div className="col-12">
                                                    <div className="form-group mb-2">

                                                        <input
                                                            type="text"
                                                            name="reference"
                                                            className="form-control shadow-none"
                                                            placeholder="Reference(optional)"

                                                        />
                                                    </div>
                                                </div>



                                            </div>

                                            <div className="col-12 text-center mb-0" style={{ color: '#7D7C7D', fontSize: "14px" }}><p className='p-0' >Pay BDT 500 at the time you visit this doctor</p></div>
                                            <button className='btn-payment' type="submit" 
                                            style={{ width: "100%" }} onClick={handlePayment}>Continue to Payment</button>


                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
