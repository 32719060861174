import React, { useState, useEffect } from 'react'
import './style.scss'
import { Images } from '../../utils/Images'
import Icon from 'react-icons-kit'
import { NavLink,  } from 'react-router-dom'
import {ic_arrow_drop_down_outline, ic_arrow_drop_up_twotone} from 'react-icons-kit/md/'
// import { standby } from 'react-icons-kit/iconic' useNavigate

import BookingAppointment from '../model/BookingPayment/BookingModel/index'
import AppointmentPayment from '../model/BookingPayment/Payment/index'
import PaymentSuccessful from '../model/BookingPayment/PaymentSuccessful'

const Layout = (isOpen) => {
    // const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const [showBooking, setShowBooking] = useState(false)
    const [showBookingPayment, setShowBookingPayment] = useState(false)
    const [showPaymentSuccessful, setShowPaymentSuccessful] = useState(false)
    const [more, setMore] = useState(false)
    // const [addNew, setAddNew] = useState({ status: false })
    // const [isMenu, setMenu] = useState(false)
    // const [loggingOut, setLoggingOut] = useState(false)
  
    useEffect(() => {
        setShow(isOpen)
    }, [isOpen])
    const handleMore = data =>{
        setMore(data)
    }
    const handleBooking = () => {
        setShowBooking( true )
    }

    const handleBookingPayment = () => {
        setShowBooking( false )
        setShowBookingPayment( true )
    }

    const handlePaymentSuccessful =() =>{
        setShowBookingPayment( false )
        setShowPaymentSuccessful(true)
    }
  
    // Toggle menu
    // const toggleMenu = event => {
    //     let current = event.target.getAttribute("data-value")

    //     if (isMenu === current) {
    //         setMenu(false)
    //     } else {
    //         setMenu(current)
    //     }
    // }

    // Logout
    // const doLogout = async () => {
    //     try {
    //         setLoggingOut(true)
    //             localStorage.clear()
    //             setTimeout(() => {
    //                 navigate('/');
    //             }, 2000)
    //         // }
    //     } catch (error) {
    //         if (error) {
    //             localStorage.clear()
    //             setTimeout(() => {
    //                 navigate('/');
    //             }, 2000)
    //         }
    //     }
    // }
    const [isActive, ] = useState(0) //
    // const handleIsActive = (data) => {
    //     setIsActive(data)
    // }
    return (
        <div className="layout d-none d-lg-block position-fixed  start-0 overflow-auto" style={{maxHeight: "500px"}}>
            
            {/* Sidebar */}
            <div className={show ? "backdrop d-lg-none open-backdrop" : "backdrop d-lg-none"} onClick={() => setShow(false)}></div>

            <div className="sidebar-container">
                <div className={show ? "sidebar  shadow open-sidebar" : "shadow sidebar "}>
                    {/* className={isOpen ? "sidebar shadow open-sidebar" : "sidebar shadow"} */}
                    {/* {show ?
                  <div style = {{ marginTop:"70px"}}></div> : null} */}
                    <ul>
                        {/* Dashboard  */}
                        <li>
                            <NavLink
                                exact="true"
                                to="my-profile"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            > <img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.Doctor2} />My Profile</NavLink>
                            {/* <Icon icon={statsBars} size={20} /> */}
                        </li>
                        {/* Setting Links */}
                        
                        <li>
                            <NavLink
                                exact="true"
                                to="find-care"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.FindCareLeftSide} />Find Care</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="forward-prescription"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.ForwardPrescriptions} />Forward Prescription</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="my-record"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.MyRecords} />My Record</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="upcoming-screening"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.UpcomingScreenings} />Upcoming Screening</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="document-center"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.DocumentCentre} />Document Center</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                // to=""
                                onClick={handleBooking}
                                // activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.MyAppointments} />Appointment</NavLink>                            
                        </li>
                        {more ? <>
                            <li>
                            <NavLink
                                exact="true"
                                to="your-medication"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.MyMedications} />Your Medication</NavLink>                            
                        </li>
                        {/* <img style={isActive === 2 ? { width: '40px', height: '40px', borderRadius: '25px', padding: '5px' }
                                : { width: '40px', height: '40px', borderRadius: '25px', padding: '5px' }} src={isActive === 2 ? Images.PatientCommunityClicked : Images.PatientCommunityUnClicked} /></span></NavLink> */}
                        <li>
                            <NavLink
                                exact="true"
                                to="patient-community"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={isActive === 0 ? { width: '15px', height: '15px',  marginRight:'5px' }
                            : { width: '30px', height: '30px',}}  alt='' src={isActive === 0 ? Images.CommunityClicked : Images.CommunityUnClicked} />Patient Community</NavLink>                            
                        </li>
                        <li>
                            <NavLink
                                exact="true"
                                to="rescheduled-appointment"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.RescheduledAppointment} />Rescheduled Appointment</NavLink>                            
                        </li>
                        <li>
                            <NavLink
                                exact="true"
                                to="share-health-data"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.ShareHealthData} />Share Health data</NavLink>                            
                        </li>
                        <li>
                        </li>
                        </> : null}
                        

                      

                        {/* Logout */}
                        {/* <li>
                            <button
                                type="button"
                                className="btn shadow-none"
                                onClick={doLogout}
                                disabled={loggingOut}
                            >
                                <Icon icon={standby} size={15} />
                                {loggingOut ? <span>Logging out...</span> : <span>Logout</span>}
                            </button>
                        </li> */}

                    </ul>
                    {more?
                    <p className='fw-bold' type= 'button' onClick={() => handleMore(false)}><Icon icon={ic_arrow_drop_down_outline} size={40}/>See Less</p>:
                    <p className='fw-bold' type= 'button' onClick={() => handleMore(true)}><Icon icon={ic_arrow_drop_up_twotone} size={40}/>See More</p>
                    }
                </div>

                <div className={show ? "shortcuts  shadow open-sidebar mt-3" : "shadow shortcuts mt-3"}>
                    <h6>Your Shortcuts</h6>
                <ul>
                        {/* Dashboard  */}
                        <li>
                            <NavLink
                                exact="true"
                                to="my-profile"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            >UserName</NavLink>
                            {/* <Icon icon={statsBars} size={20} /> */}
                        </li>
                        {/* Setting Links */}
                        
                        <li>
                            <NavLink
                                exact="true"
                                to="find-care"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.FindCareLeftSide} />Find Care</NavLink> 
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="forward-prescription"
                                activeClassName="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.ForwardPrescriptions} />Forward Prescription</NavLink>
                        
                            
                        </li>

                        <li>
                            <NavLink
                                exact="true"
                                to="my-record"
                                activeclassname="isActive"
                                type="button"
                                className="btn shadow-none"
                            ><img style={{ width: '15px', height: '15px',  marginRight:'5px' }}  alt='' src={Images.MyRecords} />My Record</NavLink>
                        
                            
                        </li>
                    </ul>
                </div>
            </div>

                {
                    showBooking ?
                    <BookingAppointment
                        show={showBooking}
                        handleBookingPayment={handleBookingPayment}
                        onHide={() => setShowBooking(false)}
                    />:null
                }

                {
                    showBookingPayment ?
                    <AppointmentPayment
                        show={showBookingPayment}
                        handlePaymentSuccessful={handlePaymentSuccessful}
                        onHide={() => setShowBookingPayment(false)}
                    />:null
                }
        
            {
                showPaymentSuccessful ?
                    <PaymentSuccessful
                        show={showPaymentSuccessful}
                        // handlePaymentSuccessful={handlePaymentSuccessful}
                        onHide={() => setShowPaymentSuccessful(false)}
                    /> : null
            }
        </div>
    );
}

export default Layout;