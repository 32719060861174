import React from 'react'
import './style.scss'
import Icon from 'react-icons-kit'
import {ic_visibility_outline,ic_local_printshop_outline} from 'react-icons-kit/md/'
import { Images } from '../../../utils/Images'
const Index = () => {

    return (
        <div className="forward-prescription-table">
            <div className=''>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Invoice No</th>
                            <th scope="col">Doctor</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Paid On</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>#INV-AC0010</td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <img src={Images.Female} style={{width:'35px', height:'35px'}} alt=''/>
                                    <div className='text-start ms-3'>
                                        <p className='m-0 p-0'>Zakir Hossain</p>
                                        <p className='m-0 p-0'>#PT0016</p>
                                    </div>
                                    
                                </div>
                            </td>
                            <td>$450</td>
                            <td>14 Nov 2023</td>
                            <td>
                            <div className='d-flex justify-content-evenly'> 
                                    <button className='forward-view'><Icon icon={ic_visibility_outline}/> View</button>
                                    <button className='forward-share'><Icon icon={ic_local_printshop_outline}/> Print</button>                                  
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>#INV-AC0009</td>
                            <td><div className='d-flex align-items-center'>
                                    <img src={Images.Female} style={{width:'35px', height:'35px'}} alt=''/>
                                    <div className='text-start ms-3'>
                                        <p className='m-0 p-0'>Zakir Hossain</p>
                                        <p className='m-0 p-0'>#PT0016</p>
                                    </div>
                                    
                                </div></td>
                            <td>$450</td>
                            <td>14 Nov 2023</td>
                            <td>
                            <div className='d-flex justify-content-evenly'> 
                                    <button className='forward-view'><Icon icon={ic_visibility_outline}/> View</button>
                                    <button className='forward-share'><Icon icon={ic_local_printshop_outline}/> Print</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Index;