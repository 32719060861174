import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
import Icon from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import { x, } from 'react-icons-kit/feather'
import Select from 'react-select'
// import { ic_create } from 'react-icons-kit/md/'
// import { useForm } from 'react-hook-form'

const Index = (props) => {
    const months = [
        { label: 'lisinopril 1m/mL solution', value: '01' },
        { label: 'PRINZIDE (lisinopriL-hydrochlorothiazide) 10-12.5 mg tablet PRINIVIL (lisinopriL) 10 mg tablet', value: '02' },
        { label: 'PRINIVIL (lisinopril) 2.5 mg tablet', value: '03' },
        { label: 'PRINZIDE (lisinopril-hydrochlorothiazide) 20-12.5 mg tablet', value: '04' },
        { label: 'PRINZIDE (lisinopriL-hydrochlorothiazide) 20-25 mg tablet PRINIVIL (lisinopril) 20 mg tablet', value: '05' },
        { label: 'PRINIVIL (lisinopril) 20 mg tablet', value: '06' },
        { label: 'PRINIVIL (lisinopril) 30 mg tablet', value: '07' },
        { label: 'PRINIVIL (lisinopril) 40 mg tablet', value: '08' },
        { label: 'PRINIVIL (lisinopril) 5 mg tablet', value: '09' }
    ];
    // const [isLoading, setLoading] = useState(false) formState: { errors }, 

    // const { register, handleSubmit,  } = useForm();//formState: { errors },
    // const [value, setValue] = useState()

    // const handleReceive = data => {
    //     props.handleReceive()
    //     // props.onHide()
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const header = {
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    // }
    const handleReportMedication = () => {
        props.handleReportMedicationStep2()
    }

    // // Submit Form 
    // const onSubmit = async (data) => {
    //     try {

    //         // setLoading(true)
    //         const regData = {
    //             ...data
    //         }
    //         console.log(regData);
    //         props.handleRefetch()



    //         // const res = await Axios.post(`${api}user/prospect`, regData, header);

    //         // if (res.status === 200) {
    //         //     setId(res.data.response);
    //         //     toast('Your create account save');
    //         //     setStep(1);
    //         //     setLoading(false)
    //         //     props.handleRefetch()
    //         // }
    //         // else {
    //         //     setLoading(false);
    //         //     toast('This phone number or email already exists.');
    //         //     props.onHide();
    //         // }



    //     } catch (error) {
    //         // if (error) 
    //         // console.log(error.response.data.message)
    //         // setLoading(false);
    //         // toast(error.response.data.message);
    //         props.onHide();
    //     }
    // }

    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-form-report"
            >
                <Modal.Header>
                    <Modal.Title>{props.value ? props.value.name : 'Report a medication that you are taking'}</Modal.Title>
                    <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button>
                </Modal.Header>
                <Modal.Body >

                   <div className='p-5'>
                   <div className="form-group m-0">
                                <Select
                                    classNamePrefix="custom-select"
                                    styles={customStyles}
                                    placeholder={'Select'}
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    options={months}
                                    onChange={(event) => handleReportMedication(event.value)}
                                // defaultValue={defaultValueHandler(query.orderBy, orderByOptions)}
                                // onChange={(event) => setSearchItems(event.value)}
                                />
                            </div>

                            </div>
                </Modal.Body>
            </Modal>
        </div>

    );
}
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: 40,
        fontSize: 13,
        color: '#000',
        minWidth: 170,
        boxShadow: 'none', '&:hover': { borderColor: '1px solid #ced4da' },
        border: state.isFocused ? '1px solid #dfdfdf' : '1px solid #ced4da',
        borderRadius: 0
    })
}

export default Index;
