import React from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
// import Icon from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import {Images} from '../../utils/Images'
// import { api } from '../../../../../utils/api'
// import 'react-toastify/dist/ReactToastify.css'
// import { x, } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
// import { toast } from 'react-toastify';
const Index = (props) => {

    // const [isLoading, setLoading] = useState(false) 

    const { register, handleSubmit, formState: { errors }, } = useForm();




    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const header = {
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    // }


    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            props.handleRefetch()
            // const res = await Axios.post(`${api}user/prospect`, regData, header);

            // if (res.status === 200) {
            //     setId(res.data.response);
            //     toast('Your create account save');
            //     setStep(1);
            //     setLoading(false)
            //     props.handleRefetch()
            // }
            // else {
            //     setLoading(false);
            //     toast('This phone number or email already exists.');
            //     props.onHide();
            // }



        } catch (error) {
            // if (error) 
            // console.log(error.response.data.message)
            // setLoading(false);
            // toast(error.response.data.message);
            props.onHide();
        }
    }

    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-form"
            >
                {/* <Modal.Header>
                    <Modal.Title>{props.value ? props.value.name : 'New Traffic'}</Modal.Title>
                    <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button>
                </Modal.Header> */}
                <Modal.Body >

                    <div className='d-flex     align-items-center' style={{background:'#FEEEFF', borderRadius:'50px' }}>
                        <div className='col-5 text-center' style={{background:'#FEEEFF', borderRadius:'50px 0px 0px 50px' }}>
                        <div className='mb-2'>
                                <img src={Images.Logo} className='modal-logo-img' alt=''/>
                            </div>
                            
                            <img src={Images.TextLogo} className='modal-logo-text-img mb-5' alt=''/>
                            <p className='mt-4'style={{fontSize:'20px', color:'#000'}}>HI!!</p>
                            <p className='mb-0' style={{fontSize:'20px', color:'#000'}}>Welcome Back To</p>
                            <h1 style={{fontSize:'20px', fontWeight:"bold", color:'#C2696E'}}>MEDISERV.ai</h1>
                        </div>
                        <div className='col-7'  style={{background:'#F0F0F0', borderRadius:'0px 50px 50px 0px', padding:'40px 50px 10px 50px'}}>
                            <div style={{background: '#A356A8', color:'#fff', padding:"10px" }}><p className='p-0'>Verify Your Phone Number</p></div>                           
                            <div className='card border-none'>
                                <div className='card-body p-0'>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <div className="row p-4 pb-0" >
                                            <p className='pb-4'>In a few seconds, you should receive a text message that contains your 
                                                mobile activation code.If you haven't received a code after few minutes, please try 
                                                again</p>
                                            {/* Name */}
                                            <div className="col-12">
                                                <div className="form-group">
                                                    {errors.packageName && errors.packageName.message ? (
                                                        <p className="text-danger">{errors.packageName && errors.packageName?.message}</p>
                                                    ) : <h6>Enter your code here</h6>
                                                    }
                                                    <input
                                                        type="text"
                                                        name="email"                                                   
                                                        className="form-control-otp mt-1 shadow-none"
                                                        // placeholder="Email or Phone Number"
                                                        // defaultValue={props.value ? props.value.name : null}
                                                        {...register("email", {
                                                            required: "OTP is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <hr className='mt-3 m-0' style={{ background: "#A356A8", border: '1px solid'}}/>

                                        <div className='row pt-0 p-2'>
                                            <div className="modal-footer text-center" >
                                                <button type="submit" className="btn shadow-none" >
                                                    Confirm
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="cancel-btn shadow-none">
                                                    Cancel
                                                </button>
                                            </div>
                                            </div>

                                    </form>
                                </div>
                            </div>
                            
                            <div className='row' style={{marginTop:'40px'}}>
                                <div className='col-8'>
                                </div>
                                <div className='col-4' style={{textAlign:'end'}}>
                                <button type="button" className="close-btn shadow-none"  onClick={props.onHide} >Close</button>
                                </div>
                            </div>
                        </div>

                    </div>


                </Modal.Body>

            </Modal>
        </div>

    );
}


export default Index;
