import React, { useState } from 'react'//useEffect, useCallback,
import './style.scss'
// import Axios from 'axios'
// import Icon from 'react-icons-kit'
import { Modal } from 'react-bootstrap'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Images } from '../../../../utils/Images'
// import { api } from '../../../../../utils/api'
// import 'react-toastify/dist/ReactToastify.css'
// import { x, } from 'react-icons-kit/feather'
import { useForm } from 'react-hook-form'
// import { toast } from 'react-toastify';

const Index = (props) => {
    
    // const [isLoading, setLoading] = useState(false) formState: { errors }, 

    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [value, setValue] = useState()

    const handleReceive = data => {  
        props.handleReceive()
        // props.onHide()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const header = {
    //     headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    // }


    // Submit Form 
    const onSubmit = async (data) => {
        try {

            // setLoading(true)
            const regData = {
                ...data
            }
            console.log(regData);
            props.handleRefetch()
            
          
            
            // const res = await Axios.post(`${api}user/prospect`, regData, header);

            // if (res.status === 200) {
            //     setId(res.data.response);
            //     toast('Your create account save');
            //     setStep(1);
            //     setLoading(false)
            //     props.handleRefetch()
            // }
            // else {
            //     setLoading(false);
            //     toast('This phone number or email already exists.');
            //     props.onHide();
            // }



        } catch (error) {
            // if (error) 
            // console.log(error.response.data.message)
            // setLoading(false);
            // toast(error.response.data.message);
            props.onHide();
        }
    }

    return (

        <div>
            <Modal show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                className="custom-modal-form"
            >
                {/* <Modal.Header>
                    <Modal.Title>{props.value ? props.value.name : 'New Traffic'}</Modal.Title>
                    <button type="button" className="btn shadow-none rounded-circle" onClick={props.onHide}>
                        <Icon icon={x} size={22} />
                    </button>
                </Modal.Header> */}
                <Modal.Body >

                    <div className='d-flex align-items-center' style={{ background: '#FEEEFF', borderRadius: '50px' }}>
                        <div className='d-none d-lg-block col-lg-5 text-center' style={{ background: '#FEEEFF', borderRadius: '50px 0px 0px 50px' }}>
                            <div className='mb-2'>
                                <img src={Images.Logo} className='modal-logo-img' alt='' />
                            </div>

                            <img src={Images.TextLogo} className='modal-logo-text-img mb-5' alt='' />
                            <p className='mt-4' style={{ fontSize: '20px', color: '#000' }}>HI!!</p>
                            <p className='mb-0' style={{ fontSize: '20px', color: '#000' }}>Welcome Back To</p>
                            <h1 style={{ fontSize: '20px', fontWeight: "bold", color: '#C2696E' }}>MEDISERV.ai</h1>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-7 ' style={ window.innerWidth > 769?{ background: '#F0F0F0', borderRadius: '0px 50px 50px 0px', padding: '40px 50px 10px 50px' }:{ background: '#F0F0F0', borderRadius: '50px', padding: '25px 20px 10px 20px'}}>
                            {/* <div className='text-center mb-4' style={{color:'#000'}}>
                                <h2 style={{fontSize:'25px'}} >Create a new account</h2>
                                <p>It's quick and easy</p>
                            </div> */}

                            <div className='card mt-2 border-none'>
                                <div className='card-body p-4'>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <div className="row">

                                            {/*First Name */}
                                            <div className="col-12">
                                                <div className="form-group mb-2">
                                                    {errors.packageName && errors.packageName.message ? (
                                                        <p className="text-danger">{errors.packageName && errors.packageName?.message}</p>
                                                    ) : <h6>First name</h6>
                                                    }
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control shadow-none"
                                                        placeholder="First name"
                                                        {...register("email", {
                                                            required: "First name is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>

                                            {/*Last Name */}
                                            <div className="col-12">
                                                <div className="form-group mb-2">
                                                    {errors.packageName && errors.packageName.message ? (
                                                        <p className="text-danger">{errors.packageName && errors.packageName?.message}</p>
                                                    ) : <h6>Last name</h6>
                                                    }
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control shadow-none"
                                                        placeholder="Last name"
                                                        {...register("email", {
                                                            required: "First name is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>

                                            {/* Topic */}
                                            <div className="col-12">
                                                <div className="form-group mb-2">
                                                    {errors.packageName && errors.packageName.message ? (
                                                        <p className="text-danger">{errors.packageName && errors.packageName?.message}</p>
                                                    ) : <h6>Topic</h6>
                                                    }
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control shadow-none"
                                                        placeholder="Topic"
                                                        {...register("email", {
                                                            required: "Topic is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>

                                            {/* Email */}
                                            <div className="col-12">
                                                <div className="form-group mb-2">
                                                    {errors.packageName && errors.packageName.message ? (
                                                        <p className="text-danger">{errors.packageName && errors.packageName?.message}</p>
                                                    ) : <h6>Email</h6>
                                                    }
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        className="form-control shadow-none"
                                                        placeholder="email@gmail.com"
                                                        {...register("email", {
                                                            required: "First name is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>


                                            {/*Contact Number*/}
                                            <div className="col-12">
                                                <div className="form-group  mb-2">
                                                    {errors.phone && errors.phone.message ? (
                                                        <p className="text-danger">{errors.phone && errors.phone?.message}</p>
                                                    ) : <h6>Contact Number</h6>
                                                    }
                                                    <div className='form-control'>
                                                    <PhoneInput
                                                        name="phone"
                                                        className=" shadow-none"
                                                        value={value}
                                                        onChange={setValue}
                                                        placeholder="Enter phone number"
                                                    />
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            {/* Message */}
                                            <div className="col-12">
                                                <div className="form-group mb-2">
                                                    {errors.packageName && errors.packageName.message ? (
                                                        <p className="text-danger">{errors.packageName && errors.packageName?.message}</p>
                                                    ) : <h6>Message</h6>
                                                    }
                                                    <input 
                                                        type="text"
                                                        name="email"
                                                        className="form-control shadow-none"
                                                        placeholder="Message"
                                                        rows
                                                        {...register("email", {
                                                            required: "Message is required",

                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="modal-footer text-start" >
                                                <div className="col-12 d-flex justify-content-between align-items-center">
                                                    <button className='btn-submit' type="submit" style={{ background: "#A356A8" }}  onClick={() => handleReceive()}>Submit</button>
                                                    <img style={{width:'80px'}} src={Images.PrivacyTerms} alt=''/>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '40px' }}>
                                <div className='col-8'>
                                </div>
                                <div className='col-4' style={{ textAlign: 'end' }}>
                                    <button type="button" className="close-btn shadow-none" onClick={props.onHide} >Close</button>
                                </div>
                            </div>
                        </div>

                    </div>


                </Modal.Body>
            </Modal>
        </div>

    );
}


export default Index;
